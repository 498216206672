<template>
  <!-- Content Row -->
  <div class="row no-gutters h-100">
    <!-- Content Column -->
    <div class="col-12 file-manager-container border shadow-sm">
      <file-manager :settings="settings"></file-manager>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  computed: {
    ...mapGetters(["getLang"]),
    settings() {
      return {lang: this.getLang, baseUrl: "/media-pool/"};
    },
  },
};
</script>
