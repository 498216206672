<template>
  <div
    class="nav flex-column nav-pills"
    id="v-pills-tab"
    role="tablist"
    aria-orientation="vertical"
  >
    <a
      v-for="(cat, index) in cats"
      :key="cat.label"
      :class="{
    'nav-link border-bottom border-dark text-right text-dark ': true,
    'active text-ci-secondary-contrast':
    currentCategoryIndex === index,
    }"
      :id="'cat-nav-' + cat.label"
      data-toggle="pill"
      :href="'#cat-content-' + cat.label"
      role="tab"
      :aria-controls="'cat-content-' + cat.label"
      :aria-selected="currentCategoryIndex === index"
      @click="setCurrentCategoryIndex(index)">
      <div class="h5 mb-0 text-nowrap mr-3">
        <span class="mr-2">{{ $t("configcats", cat.label) }}</span>
        <i class="fas fa-chevron-right"></i>
      </div>
    </a>
  </div>
</template>
<script>
import {mapGetters} from "vuex";

export default {
  props: {
    orderId: Number,
    currentCategoryIndex: {
      required: false,
      type: Number,
      default: 0,
    },
    currentUnitIndex: {
      required: false,
      type: Number,
      default: 0,
    },
  },
  computed: {
    ...mapGetters({
      cats: "getCats",
    }),
  },
  methods: {
    setCurrentCategoryIndex(index) {
      this.$emit('update:currentCategoryIndex', index)
    }
  },
};
</script>
