<template>
  <b-form-radio-group ref="rg">
    <b-form-radio
      v-for="(option, index) in options"
      :key="index"
      v-model="selected"
      :disabled="busy"
      :value="option.value"
      inline
      v-bind="configAttribs"
      @change="submitParam"
    >
      <div class="">{{ option.text }}</div>
      <b-img v-if="option.media"
             :alt="option.text"
             :src="option.media"
             :title="option.text"
             :width="imageWidth"
             fluid
             thumbnail
      ></b-img>
    </b-form-radio>
  </b-form-radio-group>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  props: {
    activeUnit: {
      required: true,
      type: Number,
      default: -1,
    },
    activeParam: {
      required: true,
      type: Number,
      default: -1,
    },
    basePointer: {
      type: Object,
      required: true,
      default: null,
    },
    configNeedle: {
      type: Array,
      required: true,
      default() {
        return [];
      },
    },
    isui: {
      required: false,
      type: Boolean,
      default: false,
    },
    UiNeedle: {
      type: Array,
      required: true,
      default: () => {
        return [];
      },
    },
    param: {
      required: true,
      type: Object,
      default: null,
    },
    options: {
      required: true,
      validator: (prop) => Array.isArray(prop) || prop === null,
      default: () => {
        return [];
      },
    },
    driver: {
      required: true,
      type: Object,
      default: () => {
        return {show: false, groupIndex: -1};
      },
    },
    configAttribs: {
      required: true,
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      medias: [],
      selected: "",
      busy: false,
      invalidFeedback: null,
    };
  },
  methods: {
    ...mapActions(["saveParam"]),
    async submitParam() {
      this.busy = true;
      let newValue = this.selected;
      if (!this.isui && this.configValue !== newValue) {
        const paramConfigNeedle = this.configNeedle.concat([
          [this.activeUnit, this.activeParam],
        ]);
        const paramUiMapPointer = this.$calcUiPointer(
          this.basePointer.unitLabel,
          this.UiNeedle
        );
        const upData = {
          basePointer: this.basePointer.unitLabel,
          mastervariable: this.param.mastervariable,
          configNeedle: paramConfigNeedle,
          uiMapPointer: paramUiMapPointer,
          value: newValue,
          text: this.calcText(newValue),
        };
        const response = await this.saveParam(upData);

        if (response) {
          this.invalidFeedback = response;
        }
      }
      this.busy = false;
    },
    calcText(newValue) {
      let hasText =
        this.param.has_datasource &&
        Array.isArray(this.options) &&
        this.options.length > 0 &&
        newValue !== null;
      if (!hasText) {
        return newValue;
      }
      let optionIndex = _.findIndex(
        this.options,
        (element) => element.value === newValue
      );
      return optionIndex > -1 ? this.options[optionIndex].text : newValue;
    },
  },
  mounted() {
    this.selected = this.configValue;
  },
  computed: {
    ...mapGetters(["getParamValue"]),
    configValue() {
      if (this.isui || this.activeParam === -1) {
        return null;
      }
      return this.getParamValue({
        basePointer: this.basePointer,
        configNeedle: this.configNeedle,
        activeUnit: this.activeUnit,
        activeParam: this.activeParam,
      });
    },
    imageWidth() {
      let remInPx = parseInt(
        getComputedStyle(document.documentElement).fontSize
      );
      let groupWidth = this.$refs.rg.$el.clientWidth;
      let segments =
        Array.isArray(this.options) && this.options.length > 1
          ? this.options.length
          : 1;
      let imageWidth = groupWidth / segments - remInPx * 3;
      return imageWidth;
    },
  },
};
</script>
