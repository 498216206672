export const OrderImportHelper = {
    findUnitByAnchorId(unit, anchorId) {
        if (unit.id === anchorId) return unit;

        for (const childId in unit.children) {
            const child = unit.children[childId];
            const result = this.findUnitByAnchorId(child, anchorId);
            if (result) return result;
        }

        return null;
    },
}
