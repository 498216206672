<template>
  <div class="w-100 order-body">
    <div class="p-2">
      <div class="w-100 text-right">
        <b-button
          variant="outline-dark"
          :disabled="getIsValidating"
          @click="validate()"
        >
          <i
            :class="{
              'far fa-fw fa-sync': true,
              'fa-spin': getIsValidating,
            }"
          ></i>
          {{ $t("order", "validate") }}
        </b-button>
      </div>
    </div>
    <transition-group
      name="validation-list-transition"
      enter-active-class="animate__animated animate__fadeIn animate__faster"
      leave-active-class="animate__animated animate__fadeOut animate__faster"
      tag="div"
    >
      <div
        v-for="(vCombi, mIndex) in getValidationMessages"
        :key="'validation-message-' + mIndex"
        class="p-2"
      >
        <b-card>
          <div class="card-title">
            <span v-html="extractIndex(vCombi)" class="text-muted small font-weight-light"></span>
            <span class="align-text-bottom float-right">
              <b-button
                @click="$emit('click-on-source-link', vCombi)"
                variant="link"
                class="p-0"
                :id="'goto-parameter-' + mIndex"
              >
                <i class="fal fa-external-link-square text-dark"></i>
              </b-button>
            </span>
          </div>
          <b-card-text class="pt-2 pb-0 border-top border-light font-weight-light">
            <span class="text-danger">
              {{ extractMessage(vCombi) }}
            </span>
          </b-card-text>
        </b-card>
      </div>
    </transition-group>
  </div>
</template>
<script>
import {mapGetters, mapActions} from "vuex";

export default {
  props: {},
  methods: {
    ...mapActions(["validateOrder"]),
    async validate() {
      const response = await this.validateOrder();
    },
    extractIndex(combi) {
      let newMessage = "";
      var uiMapPointer = combi.param.uiMapPointer;
      let uiMapArray = uiMapPointer.split("/");
      let uiNeedle = [];
      let basePointerObject = this.getBasePointerObject(
        combi.param.basePointer
      );
      for (let wIndex = 1; wIndex < uiMapArray.length - 1; wIndex++) {
        if (wIndex == 1) {
          let unitText = this.$t("configunits", uiMapArray[wIndex]);
          if (combi.param.configNeedle[0][0] >= 0) {
            newMessage += " " + unitText + " [" + (combi.param.configNeedle[0][0] + 1) + "]";
          } else {
            newMessage += unitText;
          }
        }
        const word = uiMapArray[wIndex];
        if (!isNaN(word) && wIndex !== uiMapArray.length - 1) {
          let wordIndex = parseInt(word);
          uiNeedle.push(wordIndex);
          let unitLabel = this.getUnitLabel(basePointerObject, uiNeedle);
          if (combi.param.configNeedle[0][0] >= 0) {
            newMessage +=
              " / " + unitLabel + " [" + (combi.param.configNeedle[0][0] + 1) + "]";
          } else {
            newMessage +=
              " / " + unitLabel;
          }
        }
      }
      newMessage +=
        "<br><span class='h5 text-dark font-weight-normal'>" +
        this.$t("parameters", combi.param.mastervariable) + "</span>";

      return newMessage;
    },
    getBasePointerObject(unitLabel) {
      let basePointer = {
        unitLabel: unitLabel,
        catIndex: null,
        unitIndex: null,
      };
      for (let cIndex = 0; cIndex < this.getMap.length; cIndex++) {
        const cat = this.getMap[cIndex];

        let uIndex = cat.findIndex((cat) => cat.unit_label === unitLabel);
        if (uIndex > -1) {
          let basePointer = {
            unitLabel: unitLabel,
            catIndex: cIndex,
            unitIndex: uIndex,
          };
          return basePointer;
        }
      }
      return basePointer;
    },
    extractMessage(combi) {
      return combi.message[0].replace(combi.param.mastervariable, "");
    },
  },
  computed: {
    ...mapGetters([
      "getValidationMessages",
      "getIsValidating",
      "getMap",
      "getParams",
      "getUnitLabel",
    ]),
    params() {
      return this.getParams;
    },
  },
};
</script>
