<template>
  <div class="w-100 order-body">
    <media-pool></media-pool>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods: {},
  computed: {},
};
</script>
