<template>
  <div class="w-100 d-flex flex-column border-0" v-if="driver.show">
    <div class="card-header" v-if="grid == 4">
      <h5 class="m-0 p-1">
        <span>
          <b-button
            v-if="activeUnit > 0"
            variant="link"
            class="mr-1 p-0 text-dark"
            @click="editPrev"
            v-b-tooltip.hover
            :title="$t('order', 'prev_unit')"
          >
            <i class="fas fa-chevron-left"></i>
          </b-button>
          <b-button v-else variant="link" class="mr-1 p-0 text-dark" disabled>
            <i class="fas fa-chevron-left"></i>
          </b-button>
          <b-button
            v-if="activeUnit != unitCount - 1"
            variant="link"
            class="p-0 text-dark"
            @click="editNext"
            v-b-tooltip.hover
            :title="$t('order', 'next_unit')"
          >
            <i class="fas fa-chevron-right"></i>
          </b-button>
          <b-button v-else variant="link" class="p-0 text-dark" disabled>
            <i class="fas fa-chevron-right"></i>
          </b-button>
        </span>
        <span v-if="unit.canvas.length > 0"
        >{{ $t("parameters", unit.canvas[0].mastervariable) }} :
          {{ firstParamValue }}</span
        >
        <span class="float-right">
          <b-button
            v-if="showUnitTable"
            variant="link"
            class="p-0 text-dark"
            @click="expandEditor"
            v-b-tooltip.hover
            :title="$t('order', 'expand_unit')"
          >
            <i class="fas fa-expand-wide"></i>
          </b-button>
          <b-button
            v-else
            variant="link"
            class="p-0 text-dark"
            @click="shrinkEditor"
            v-b-tooltip.hover
            :title="$t('order', 'compress_unit')"
          >
            <i class="fas fa-compress-wide"></i>
          </b-button>
          <b-button
            variant="link"
            class="ml-1 p-0 text-dark"
            @click="closeEditor"
            v-b-tooltip.hover
            :title="$t('app', 'close')"
          >
            <i class="far fa-times"></i>
          </b-button>
        </span>
      </h5>
    </div>
    <div :class="{'card-body': grid == 4}">
      <div class="d-flex flex-wrap">
        <parameter
          v-for="(param, pIndex) in unit.canvas"
          :param="param"
          :driver="calcRecursiveTrigger(param, unitConfig)"
          :active-unit="activeUnit"
          :active-param="$calcIndex(param, unitConfig)"
          :grid="grid"
          :key="pIndex + '_' + param.id"
          :id-stub="idStub + '-' + $calcIndex(param, unitConfig)"
          :isui="$calcIsUi(param)"
          :base-pointer="basePointer"
          :ui-needle="UiNeedle.concat(pIndex)"
          :config-needle="configNeedle"
          :unit-config="unitConfig"
        />
      </div>
    </div>
  </div>
</template>
<script>
import {singleUnit} from "../../../../../mixins/app/singleUnit";

export default {
  mixins: [singleUnit],
  props: {
    level: Number,
    contentLevel: {
      required: false,
      type: Boolean,
      default: true,
    },
  },
  methods: {
    calcRecursiveTrigger(param, unitConfig) {
      let showLevel = param.level_view.show;
      switch (showLevel) {
        case 0:
          if (this.contentLevel) {
            return {show: false, groupIndex: -1};
          }
          break;
        case 1:
          if (!this.contentLevel) {
            return {show: false, groupIndex: -1};
          }
          break;

        default:
          break;
      }
      return this.$calcTrigger(
        param,
        unitConfig,
        this.configNeedle,
        this.activeUnit
      );
    },
  },
};
</script>
