<template>
  <div>
    <b-tabs content-class="col-md-10 col-lg-8" lazy nav-class="text-break w-100" nav-wrapper-class="col-md-2 col-lg-2"
            pills
            vertical>
      <template v-for="unit in units">
        <b-tab v-if="unit.hasContent()" :title="__('database.configunits.'+ unit.label)">
          <unit :order-data="orderData" :root="unit" :sheets="sheets" :unit="unit"></unit>
        </b-tab>
      </template>
    </b-tabs>
  </div>
</template>

<script>

export default {
  components: {
    unit: () => import('./preview-step/Unit.vue'),
  },
  props: {
    sheets: Object,
    sheetHeaders: [],
    units: Object,
    orderData: Object
  },
}
</script>
