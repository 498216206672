<template>
  <div class="h5 m-0 d-flex justify-content-center px-1 pt-4 position-absolute z-900">
    <b-button variant="link" @click="toggleSidebar" class="text-dark p-1 d-flex shadow-none text-decoration-none">
      <transition :duration="240" name="order-sidebar-toggle" mode="out-in">
        <i
          v-if="showSidebar"
          class="fas fa-outdent"
          key="hide"
          v-b-tooltip.hover
          :title="$t('app', 'hide_sidebar')"
        ></i>
        <i
          v-else
          class="fas fa-indent"
          key="show"
          v-b-tooltip.hover
          :title="$t('app', 'show_sidebar')"
        ></i>
      </transition>
    </b-button>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  data() {
    return {
      unitIndex: 0,
    };
  },
  props: {
    showSidebar: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    toggleSidebar() {
      this.$emit("update:showSidebar", !this.showSidebar);
    },

  },
};
</script>
