<template>
  <div class="w-100 order-body">
    <transition-group
      enter-active-class="animate__animated animate__fadeIn animate__faster"
      leave-active-class="animate__animated animate__fadeOut animate__faster"
      name="generation-list-transition"
      tag="div"
    >
      <div
        v-for="(generation, gIndex) in getGenerations"
        :key="generation.id"
        class="mb-3 mx-2"
      >
        <b-card>
          <h4 class="card-title">
            {{ generation.created_at }}
            <span class="align-text-bottom float-right">
              <b-button
                :id="'del-pop-' + generation.id"
                class="p-0"
                variant="link"
              >
                <i class="far fa-trash-alt text-dark"></i>
              </b-button>
              <b-popover
                :target="'del-pop-' + generation.id"
                placement="auto"
                triggers="focus"
              >
                <b-button
                  variant="outline-danger"
                  @click="deleteGen(generation.id, gIndex)"
                >
                  <i class="far fa-trash-alt"></i>
                </b-button>
              </b-popover>
            </span>
          </h4>
          <h6 class="card-subtitle text-muted mb-2">
            {{ generation.created_by }}
          </h6>
          <div class="p-2 border-top border-light w-100">
            <div
              v-if="generation.idefeedback && generation.idefeedback.length > 0"
              class="w-100"
            >
              <div
                v-for="(entry, eIndex) in generation.idefeedback"
                :key="eIndex"
                class="w-100"
              >
                <h4 class="d-flex justify-content-between">
                  <span>{{ $t("genunits", entry.target) }}</span>
                  <span
                  ><small class="align-top">{{
                      translateStatus(entry.status)
                    }}</small></span
                  >
                </h4>
                <div
                  v-if="
                    entry.status == 'FinishedSuccessfully' ||
                    entry.status == 'FinishedSuccesfully'
                  "
                  class="row"
                >
                  <div
                    v-for="(fileNames, fIndex) in entry.results"
                    :key="fIndex"
                    class="col d-flex flex-wrap"
                  >
                    <b-button
                      v-for="(fileName, fNdex) in fileNames"
                      :key="fNdex"
                      :disabled="
                        downloadingFile ===
                        generation.id +
                          '-' +
                          eIndex +
                          '-' +
                          fIndex +
                          '-' +
                          fNdex
                      "
                      class="mx-1 mw-100"
                      variant="link"
                      @click="
                        downloadFile(
                          generation.id,
                          eIndex,
                          fIndex,
                          fNdex,
                          fileName
                        )
                      "
                    >
                      <span class="fa-stack fa-2x">
                        <i
                          :class="{
                            'fal fa-stack-2x fa-file-pdf': fIndex === 'pdf',
                            'fal fa-stack-2x fa-file-archive': fIndex === 'zip',
                            'fal fa-stack-2x fa-file-word': fIndex === 'docx',
                            'fal fa-stack-2x fa-file-excel': fIndex === 'xlsx',
                            'fal fa-stack-2x fa-cube': fIndex === 'stp',
                            'fal fa-stack-2x fa-file': isUnknownType(fIndex),
                          }"
                        ></i>
                        <i
                          v-if="
                            downloadingFile ===
                            generation.id +
                              '-' +
                              eIndex +
                              '-' +
                              fIndex +
                              '-' +
                              fNdex
                          "
                          class="fad fa-stack-1x fa-spinner-third fa-spin"
                        ></i>
                      </span>
                      <p class="text-wrap">
                        <small>{{ fileName }}</small>
                      </p>
                    </b-button>
                  </div>
                </div>
                <div
                  v-else-if="entry.status == 'FinishedInError'"
                  class="row no-gutters"
                >
                  <div
                    v-for="(fileNames, fIndex) in entry.logs"
                    :key="fIndex"
                    class="col d-flex flex-wrap"
                  >
                    <b-button
                      v-for="(fileName, fNdex) in fileNames"
                      :key="fNdex"
                      class="mx-1"
                      variant="link"
                      @click="
                        downloadFile(
                          generation.id,
                          eIndex,
                          fIndex,
                          fNdex,
                          fileName
                        )
                      "
                    >
                      <div>
                        <i
                          :class="{
                            'fal fa-3x': true,
                            'fa-file-pdf': fIndex == 'pdf',
                            'fa-file-archive': fIndex == 'zip',
                            'fa-file-alt': fIndex == 'txt',
                            'fa-file-search': fIndex == 'log',
                          }"
                        ></i>
                        <p class="text-nowrap">
                          <small>{{ fileName }}</small>
                        </p>
                      </div>
                    </b-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {{ $t('app', 'snapshot__of_config__for_generation') }}
          <b-button
            :id="'generation-restore-pop-' + generation.id"
            variant="outline-warning"
          >
            <i class="far fa-sync"></i>
          </b-button>
          <b-popover
            :target="'generation-restore-pop-' + generation.id"
            :title="$t('order', 'restore_order_snapshot')"
            placement="auto"
            triggers="focus"
          >
            <p class="text-danger">
              <strong>{{ $t("app", "irreversible") }}</strong>
            </p>
            <b-button
              :disabled="busyRestoring"
              variant="outline-danger"
              @click="restoreGeneration(generation)"
            >
              <i
                :class="{
                  'far fa-fw fa-sync': true,
                  'fa-spin': busyRestoring,
                }"
              ></i>
              {{ $t("app", "restore") }}
            </b-button>
          </b-popover>
        </b-card>
      </div>
    </transition-group>
  </div>
</template>
<script>
import {mapGetters, mapActions} from "vuex";

export default {
  data() {
    return {
      knownTypes: ["pdf", "zip", "docx", "xlsx", "stp"],
      downloadingFile: null,
      busyRestoring: false,
    };
  },
  methods: {
    ...mapActions(["deleteGeneration", "downloadGeneratedFile", "loadOrder"]),
    async downloadFile(genID, eIndex, fileExtension, fileIndex, fileName) {
      this.downloadingFile =
        genID + "-" + eIndex + "-" + fileExtension + "-" + fileIndex;

      const response = await this.downloadGeneratedFile({
        genID: genID,
        eIndex: eIndex,
        fileExtension: fileExtension,
        fileIndex: fileIndex,
      });
      if (response) {
        var url = window.URL.createObjectURL(new Blob([response]));
        var link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        link.setAttribute("target", "_blank");
        document.body.appendChild(link);
        link.click();
        link.remove();
      }
      this.downloadingFile = null;
    },
    deleteGen(genId, genIndex) {
      this.$root.$emit("bv::hide::popover");
      this.deleteGeneration({
        id: genId,
        index: genIndex,
      });
    },
    translateStatus(status) {
      let retStr = "";
      switch (status) {
        case "Queued":
          retStr = this.$t("app", "queued");
          break;
        case "Running":
          retStr = this.$t("app", "running");
          break;
        case "FinishedInError":
          retStr = this.$t("app", "failed");
          break;
        case "FinishedSuccessfully":
        case "FinishedSuccesfully":
          retStr = this.$t("app", "successful");
          break;

        default:
          break;
      }
      return retStr;
    },
    isUnknownType(fIndex) {
      return !this.knownTypes.includes(fIndex);
    },
    restoreGeneration(generation) {
      this.busyRestoring = true;
      axios
        .post(
          "/api/orders/" +
          generation.order_id +
          "/generations/" +
          generation.id +
          "/restore"
        )
        .then(({data}) => {
          this.loadOrder(generation.order_id);
          this.$root.$emit("bv::hide::popover");
          this.busyRestoring = false;
        });
    },
  },
  computed: {
    ...mapGetters(["getGenerations"]),
  },
};
</script>
