<template>
  <div>
    <b-tabs pills vertical nav-wrapper-class="col-md-2 col-lg-2" content-class="col-md-10 col-lg-8" lazy
            nav-class="text-break w-100">
      <b-tab :title="key" v-for="(sheet, key) in sheets" :key="key">
        <sheet :sheet="sheet"></sheet>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>

import {EventBus} from "../../../../eventbus";
import Sheet from "./editors/Sheet.vue";

export default {
  components: {Sheet},
  props: {
    sheets: Object,
  },
  data() {
    return {
      detailSettingsOpen: false
    };
  },
  computed: {
    sheet: () => import('./editors/Sheet.vue'),
  },
}
</script>
