<template>
  <div id="order-content" class="w-100">
    <div
      :class="{
        'h-100': true
      }"
      role="tabpanel"
    >
      <div class="d-flex h-100 position-relative">
        <div class="d-flex flex-column justify-content-center px-2 position-absolute h-100"
             style="width: 1.9rem;"
             v-if="map[currentCategoryIndex] !== undefined && map[currentCategoryIndex].length > 1">
          <b-nav vertical
                 class="align-items-center">
            <b-nav-item
              v-for="(unit, index) in map[currentCategoryIndex]"
              :key="unit.unit_label"
              v-b-popover.hover.right="{
                content: $t('configunits', unit.unit_label),
                delay: 0,
                animation: false,
              }"
              :class="{
                'square-fa-extra-small border border-dark rounded-circle mb-1': true,
                'bg-dark': currentUnitIndex === index,
              }"
              :active="currentUnitIndex === index"
              @click="updateCurrentUnitIndex(index)"
            >

            </b-nav-item>
          </b-nav>
        </div>
        <order-content-category
          v-if="cats.length > 0"
          :key="currentCategoryIndex"
          ref="order-content-category"
          :current-category-index="currentCategoryIndex"
          :cat="cats[currentCategoryIndex]"
          @update:currentUnitIndex="updateCurrentUnitIndex"
        >
        </order-content-category>
      </div>
    </div>
  </div>
</template>
<script>
import OrderContentCategory from "./OrderContentCategory.vue";

import {mapGetters} from "vuex";

export default {
  components: {OrderContentCategory},
  props: {
    currentCategoryIndex: {
      required: false,
      type: Number,
      default: 0,
    },
    currentUnitIndex: {
      required: false,
      type: Number,
      default: 0,
    },
  },
  computed: {
    ...mapGetters({
      cats: "getCats",
      map: "getMap",
    }),
  },
  methods: {
    updateCurrentUnitIndex(index) {
      this.$emit("update:currentUnitIndex", index);
    },
    scrollParentToChild(parent, child) {
      let parentRect = parent.getBoundingClientRect();
      let childRect = child.getBoundingClientRect();

      if (childRect.top < parentRect.top || childRect.bottom > parentRect.bottom) {
        let distance = childRect.top - parentRect.top;
        let increments = distance / 30; // Adjust the int value for different speed

        let animateScroll = setInterval(() => {
          if (Math.abs(distance) <= Math.abs(increments)) {
            parent.scrollTop += distance;
            clearInterval(animateScroll);
          } else {
            parent.scrollTop += increments;
            distance -= increments;
            parentRect = parent.getBoundingClientRect();
          }
        }, 1000 / 60);  // Adjust the '60' value for different speed (60 frames per second)
      }
    }
  },
  watch: {
    currentUnitIndex: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        let element = document.getElementById("unit-content-" + this.map[this.currentCategoryIndex][newVal].unit_label);
        // If the element is not visible it should wait until its visible
        if (element === null) {
          let interval = setInterval(() => {
            element = document.getElementById("unit-content-" + this.map[this.currentCategoryIndex][newVal].unit_label);
            if (element !== null) {
              clearInterval(interval);
              this.scrollParentToChild(element.parentElement, element);
            }
          }, 100);
        } else {
          this.scrollParentToChild(element.parentElement, element);
        }
      }
    },
  },
};
</script>
