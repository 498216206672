<template>
  <b-img :src="url" blank-src class="d-block img-fluid w-auto mh-250px" thumbnail></b-img>
</template>
<script>
import {mapActions} from "vuex";

export default {
  props: {
    mediaId: {
      type: Number,
      required: true,
    },
    datasourceId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      url: "",
    };
  },
  watch: {
    mediaId: function () {
      this.loadUrl();
    },
  },
  mounted() {
    this.loadUrl();
  },
  methods: {
    ...mapActions(["loadMedia"]),
    async loadUrl() {
      this.url = await this.loadMedia({
        datasourceId: this.datasourceId,
        mediaId: this.mediaId,
      });
    },
  },
};
</script>
