import {mapActions, mapGetters} from "vuex";

export const parameter = {
    data() {
        return {
            busy: false,

            // Additional state for caching
            configOptionsCache: {
                lastArgsHash: null,
                lastResult: null
            },
            // Debounce timer
            debounceTimer: null,
            isSavingParam: false,
            uiValue: '',
            reset: false
        };
    },
    props: {
        isui: {
            required: false,
            type: Boolean,
            default: false,
        },
        param: {
            required: true,
            type: Object,
            default: null,
        },
        grid: {
            required: false,
            type: Number,
            default: 3,
        },
        activeUnit: {
            required: true,
            type: Number,
            default: -1,
        },
        activeParam: {
            required: true,
            type: Number,
            default: -1,
        },
        driver: {
            required: true,
            type: Object,
            default: () => {
                return {show: false, groupIndex: -1};
            },
        },
        basePointer: {
            type: Object,
            required: true,
            default: null,
        },
        UiNeedle: {
            type: Array,
            required: true,
            default: () => {
                return [];
            },
        },
        configNeedle: {
            type: Array,
            required: true,
            default() {
                return [];
            },
        },
        idStub: {
            type: String,
            required: true,
            default: ''
        },
        unitConfig: {
            type: Array,
            required: true,
            default() {
                return [];
            },
        }
    },
    methods: {
        ...mapActions(["saveParam", "loadParamOptions", "queuedComputeParameter"]),
        async submitParam(newValue) {
            if (this.isSavingParam) {
                return;
            }
            this.isSavingParam = true;
            this.busy = true;
            try {
                if (!this.isui && this.configValue !== newValue) {
                    const paramUiMapPointer = this.$calcUiPointer(
                        this.basePointer.unitLabel,
                        this.UiNeedle
                    );
                    const upData = {
                        basePointer: this.basePointer.unitLabel,
                        mastervariable: this.param.mastervariable,
                        configNeedle: this.paramConfigNeedle,
                        uiMapPointer: paramUiMapPointer,
                        value: newValue,
                        text: this.calcText(newValue),
                    };
                    await this.saveParam(upData);
                }
            } catch (error) {
                console.error('Failed to save parameter:', error);
            } finally {
                this.isSavingParam = false;
                this.busy = false;
            }
        },
        async updateParameterValue(newValue) {
            if (newValue && newValue !== '') {
                this.reset = false
            }
            await this.submitParam(newValue);
        },
        async resetParam() {
            this.reset = true;
            if (this.configAttribs.defaultValue) {
                this.uiValue = null;
                await this.submitParam(this.uiValue);
                return;
            }
            this.uiValue = '';
            await this.submitParam(this.uiValue);
        },
        async reviseValue() {
            if (this.isui || this.activeParam === -1) {
                return true;
            }
            if (_.isEmpty(this.activeTrigger)) {
                if (this.configValue) {
                    await this.submitParam(null);
                }
                return true;
            }

            if (this.configAttribs.disabled &&
                this.activeTrigger.calculation &&
                this.activeTrigger.calculation.latex) {

                let paramUiMapPointer = this.$calcUiPointer(
                    this.basePointer.unitLabel,
                    this.UiNeedle
                );

                let cArgs = {
                    configNeedle: this.configNeedle,
                    paramConfigNeedle: this.paramConfigNeedle,
                    mastervariable: this.param.mastervariable,
                    activeTrigger: this.activeTrigger,
                    activeUnit: this.activeUnit,
                    basePointer: this.basePointer,
                    configValue: this.configValue,
                    UiNeedle: this.UiNeedle,
                    uiMapPointer: paramUiMapPointer

                };

                this.queuedComputeParameter(cArgs);
                return true;
            }

            if (this.configValue) {
                if (this.$asyncComputed.configOptions.state == "success") {
                    let inOptions = this.checkInOptions(this.configValue);
                    if (!inOptions) {
                        await this.submitParam(null);
                        return true;
                    }
                }

            }
            return true;
        },
        calcText(newValue) {
            let hasText = this.param.has_datasource &&
                Array.isArray(this.configOptions) &&
                this.configOptions.length > 0 &&
                newValue !== null;

            if (!hasText) {
                return newValue;
            }
            let optionIndex = _.findIndex(this.configOptions, (element) => element.value === newValue);
            return optionIndex > -1 ? this.configOptions[optionIndex].text : newValue;
        },
        checkInOptions(checkValue) {
            if (this.isui) {
                return true;
            }
            if (!this.param.has_datasource) {
                return true;
            }
            if (!Array.isArray(this.configOptions)) {
                return false;
            }
            if (this.configOptions.length == 0) {
                return false;
            }
            if (!this.driver.show) {
                return false;
            }

            let valueAttrib = this.isComplex
                ? this.activeTrigger.datasource.config
                    .overwrites.primary.source_param
                : "value";

            if (
                typeof this.configOptions[0] === "object" &&
                this.configOptions[0] !== null
            ) {
                if (this.isComplex) {
                    if (
                        !this.configOptions
                            .map((op) => op[valueAttrib].value)
                            .includes(checkValue)
                    ) {
                        return false;
                    }
                } else {
                    if (
                        !this.configOptions
                            .map((op) => op[valueAttrib])
                            .includes(checkValue)
                    ) {
                        return false;
                    }
                }
            }
            return true;
        },
    },
    watch: {
        async uiValue(newValue, oldValue) {
            if (newValue !== null) {
                await this.submitParam(newValue);
            }
        }
    },
    computed: {
        ...mapGetters(["getParamValue", "getParamText", "getParamFeedback", "getDatasourceTransKey", "getIsOnCalQ", "getFileNames"]),
        paramConfigNeedle() {
            return this.configNeedle.concat([
                [this.activeUnit, this.activeParam],
            ]);
        },
        activeTrigger() {
            return this.driver.groupIndex > -1 ? this.param.triggers[this.driver.groupIndex] : {};
        },
        invalidInput() {
            let paramFeedback = this.getParamFeedback(this.param.mastervariable, this.basePointer, this.paramConfigNeedle);

            let retState = paramFeedback ? false : null;

            let invalidInput = {state: retState, message: paramFeedback}

            return invalidInput;
        },
        isComplex() {
            if (!this.param.has_datasource) {
                return false;
            }
            if (this.param.triggers.length == 0) {
                return false;
            }
            if (this.driver.groupIndex < 0) {
                return false;
            }
            return (
                Object.keys(this.activeTrigger).length > 0 &&
                [5, 2].includes(this.activeTrigger.datasource.type)
            );
        },
        simpleHasMedia() {
            if (!this.param.has_datasource) {
                return false;
            }
            if (this.param.triggers.length == 0) {
                return false;
            }
            if (this.driver.groupIndex < 0) {
                return false;
            }
            return (
                (
                    Object.keys(this.activeTrigger).length > 0 &&
                    [3, 4].includes(this.activeTrigger.datasource.type)
                ) &&
                this.activeTrigger.datasource.show_media);
        },
        configValue() {
            if (this.isui || this.activeParam === -1) {
                return null;
            }
            if (this.param.triggers.length == 0) {
                return null;
            }
            if ([null, undefined].includes(this.activeTrigger)) {
                return null;
            }
            this.uiValue = this.getParamValue({
                basePointer: this.basePointer,
                configNeedle: this.configNeedle,
                activeUnit: this.activeUnit,
                activeParam: this.activeParam,
            });

            if (!this.reset && this.uiValue === null && this.configAttribs.defaultValue) {
                this.uiValue = this.configAttribs.defaultValue;
            }

            return this.getParamValue({
                basePointer: this.basePointer,
                configNeedle: this.configNeedle,
                activeUnit: this.activeUnit,
                activeParam: this.activeParam,
            });
        },
        configText() {
            if (this.isui || this.activeParam === -1) {
                return null;
            }
            return this.getParamText({
                basePointer: this.basePointer,
                configNeedle: this.configNeedle,
                activeUnit: this.activeUnit,
                activeParam: this.activeParam,
            });
        },
        fileNames() {
            return this.getFileNames();
        },
        configAttribs() {
            let retAttribs = this.isui ? {} : {trim: true};
            if (this.driver.groupIndex === -1) {
                if (Object.keys(this.param.attributes).length > 0) {
                    retAttribs = {...retAttribs, ...this.param.attributes};
                    delete this.param.attributes.options;
                }
            } else if (
                Object.keys(this.activeTrigger.attribs)
                    .length > 0
            ) {
                retAttribs = {...retAttribs, ...this.activeTrigger.attribs};
                if (retAttribs.hasOwnProperty('options')) {
                    delete retAttribs.options;
                }
            }
            return retAttribs;
        },
        configFileSettings() {
            if(!this.activeTrigger.file_upload) {
                return {
                    allowed_datatypes:".png, .jpg, .jpeg, .gif",
                    carousel_fade:false,
                    carousel_interval:0,
                    maxHeight:300,
                    multiple:false
                }
            }
            return this.activeTrigger.file_upload;
        },
        configTooltip() {
            if (
                this.configAttribs.hasOwnProperty("tooltip") &&
                this.configAttribs.tooltip
            ) {
                let tooltipText = this.$t('tooltips', this.configAttribs.tooltip);
                return {
                    html: true,
                    title: this.paramTranslation,
                    content: tooltipText,
                    disabled: false,
                };
            } else {
                return {disabled: true};
            }
        },
        paramTranslation() {
            if (this.isui == true) {
                return this.$t(
                    this.param.content.trans_group,
                    this.param.content.trans_key
                );
            }
            return this.$t("parameters", this.param.label);
        },
        gridCSS() {
            let gridCSS = {}
            if (this.driver.show) {
                let gridClass = "col";
                if (this.activeTrigger.design.grid !== null) {
                    gridClass = gridClass + "-" + this.activeTrigger.design.grid;
                } else if (!this.isui) {
                    gridClass = gridClass + "-4";
                } else {
                    gridClass = gridClass + "-12";
                }
                gridCSS[gridClass] = true;
            }
            return gridCSS;
        },
        wrapperCSS() {
            let wrapperCSS = {};
            if (this.driver.show && !this.isui) {
                if (this.activeTrigger.design.wrapper !== null) {
                    let wrapperClasses = this.activeTrigger.design.wrapper;
                    wrapperCSS[wrapperClasses] = true;
                }
                wrapperCSS["form-group"] = true;
            }
            return wrapperCSS;
        },
        labelCSS() {
            let labelCSS = {}
            if (this.driver.show && !this.isui) {
                if (this.activeTrigger.design.label !== null) {
                    let labelClasses = this.activeTrigger.design.label;
                    labelCSS[labelClasses] = true;
                }

            }
            return labelCSS;
        },
        elementCSS() {
            let elementCSS = {}
            if (this.driver.show && this.activeTrigger.design.element !== null) {
                let elementClasses = this.activeTrigger.design.element;
                elementCSS[elementClasses] = true;
            }
            if (!this.isui && this.param.element !== 'wysiwyg-editor') {
                elementCSS["form-control"] = true;
            }
            return elementCSS;
        },
        imageUpload() {
            let imageUpload = {};
            if (this.driver.show && this.param.element === 'img') {
                imageUpload = this.activeTrigger?.file_upload;
            }
            imageUpload.imgTitle = this.paramTranslation;

            return imageUpload;
        },
        hasValidConfigValue() {
            return this.uiValue !== null && this.uiValue !== '';
        },
    },
    async updated() {
        await this.reviseValue();
    },
    asyncComputed: {
        async configOptions() {
            if (this.param.has_datasource) {
                this.busy = true;
                let uiMapPointer = this.$calcUiPointer(
                    this.basePointer.unitLabel,
                    this.UiNeedle
                );
                const retOps = await this.loadParamOptions({
                    param: this.param,
                    configNeedle: this.configNeedle,
                    basePointer: this.basePointer,
                    driver: this.driver,
                    activeUnit: this.activeUnit,
                    activeParam: this.activeParam,
                    uiMapPointer: uiMapPointer
                });
                this.busy = false;
                return retOps;
            }
            return [];
        },
    },

};
