<template>
  <div class="my-auto">
    <b-button
      id="generationListButton"
      v-b-toggle.order-generation-list
      v-b-tooltip.hover
      :title="$t('order', 'generated_files')"
      class="text-white nav-link"
      variant="link"
    >
      <i class="fal fa-cogs nav-icon"></i>
    </b-button>
  </div>
</template>

<script>
import {EventBus} from "../../../eventbus";

export default {
  data() {
    return {};
  },
  methods: {
    onGenerationCreate() {
      const buttonElement = document.getElementById("generationListButton");
      if (buttonElement) {
        buttonElement.click();
      }
    },
  },
  created() {
    EventBus.$on("generationCreated", this.onGenerationCreate);
  },
};
</script>
