const CalcUiPointer = {
    install(Vue, options) {
        Vue.prototype.$calcUiPointer = function (base, uiNeedle) {
            var uiPointer = "/" + base + "/canvas";
            if (uiNeedle.length > 0) {
                for (let index = 0; index < uiNeedle.length - 1; index++) {
                    uiPointer += "/" + uiNeedle[index] + "/parametermap/canvas";
                }
                uiPointer += "/" + uiNeedle[uiNeedle.length - 1];
            }
            return uiPointer;
        }
    }
};
export default CalcUiPointer;
