const BuildSnakePointer = {
    install(Vue, options) {
        Vue.prototype.$buildSnakePointer = function (param) {
            let snakePointer = "params__" + param.basePointer;


            for (let index = 0; index < param.configNeedle.length - 1; index++) {
                const step = param.configNeedle[index];
                if (step[0] > -1) {
                    snakePointer = snakePointer + "__" + step[0];
                }
                snakePointer = snakePointer + "__" + step[1];
            }
            return snakePointer;
        }
    }
};
export default BuildSnakePointer;
