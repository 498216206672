import axios from 'axios';

const state = {
    notifications: []
};

const getters = {
    notifications: state => {
        return state.notifications
    }
};

const actions = {
    loadNotifications({commit}, orderId) {
        axios.get('/api/notifications?orderId=' + orderId,)
            .then(res => {
                {
                    commit('setNotifications', res.data.data)
                }
            })
            .catch(err => {
                console.log(err)
            })
    }
};

const mutations = {
    setNotifications(state, notifications) {
        state.notifications = notifications
    },
    addNotification(state, notification) {
        state.notifications.unshift(notification)
    },
    emptyNotification(state) {
        state.notifications = []
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};
