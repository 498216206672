import {mapActions, mapGetters} from "vuex";

export const singleUnit = {
    props: {
        idStub: {
            type: String,
            required: true,
            default: ''
        },
        basePointer: {
            type: Object,
            required: true,
            default: null,
        },
        activeUnit: {
            required: false,
            type: Number,
            default: -1,
        },
        unitCount: {
            required: false,
            type: Number,
            default: 0,
        },
        showUnitTable: {
            required: false,
            type: Boolean,
            default: true,
        },
        grid: {
            required: false,
            type: Number,
            default: 3,
        },
        driver: {
            required: false,
            type: Object,
            default: () => {
                return {show: true, groupIndex: -1};
            },
        },
        UiNeedle: {
            type: Array,
            required: true,
            default: () => {
                return [];
            },
        },
        configNeedle: {
            type: Array,
            required: true,
            default() {
                return [];
            },
        },
    },
    methods: {
        ...mapActions(["setParamToDefault"]),
        closeEditor() {
            this.$parent.$parent.activeUnit = -1;
            this.$parent.$parent.showUnitTable = true;
        },
        editNext() {
            if (this.activeUnit < this.unitCount - 1) {
                this.$parent.$parent.activeUnit++;
            }
        },
        editPrev() {
            if (this.activeUnit > 0) {
                this.$parent.$parent.activeUnit--;
            }
        },
        expandEditor() {
            this.$parent.$parent.showUnitTable = false;
        },
        shrinkEditor() {
            this.$parent.$parent.showUnitTable = true;
        },
    },
    computed: {
        ...mapGetters(["getConfigUnit", "getUnit", "getParamText"]),
        unit() {
            return this.getUnit(this.basePointer, this.UiNeedle);
        },
        unitConfig() {
            return this.getConfigUnit({
                basePointer: this.basePointer,
                configNeedle: this.configNeedle,
                activeUnit: this.activeUnit
            });
        },
        firstParamValue() {
            return this.getParamText({
                basePointer: this.basePointer,
                configNeedle: this.configNeedle,
                activeUnit: this.activeUnit,
                activeParam: 0,
            });
        },
    },

};
