import {mapGetters, mapActions} from "vuex";

export const unitContainer = {
    data() {
        return {
            idStub: '',
            expanded: true
        }
    },
    props: {
        multiple: Number,
        basePointer: {
            type: Object,
            required: true,
            default: () => {
                return {};
            },
        },
        UiNeedle: {
            type: Array,
            required: true,
            default: () => {
                return [];
            },
        },
        configNeedle: {
            type: Array,
            required: true,
            default: () => {
                return [];
            },
        },
        driver: {
            required: true,
            type: Object,
            default: () => {
                return {show: true, groupIndex: -1};
            },
        },
    },
    methods: {
        ...mapActions(["truncateUnit"]),
        calcIdStub() {
            this.idStub = this.basePointer.unitLabel;
            if (this.configNeedle.length > 0) {
                this.idStub = this.idStub + "-" + this.configNeedle.join("-").replaceAll(",", "-");
            }
        },
        toggleExpand() {
            this.expanded = !this.expanded;
        },
        reviseUnit() {
            if (this.driver.show == false && !this.getIsEmpty(this.unitConfig)) {
                const upData = {
                    basePointer: this.basePointer.unitLabel,
                    uiNeedle: this.UiNeedle,
                    configNeedle: this.configNeedle,
                    multiple: this.multiple
                };
                this.truncateUnit(upData);
            }
        }
    },
    async updated() {
        await this.reviseUnit();
    },
    mounted() {
        this.calcIdStub();
    },
    computed: {
        ...mapGetters(["getUnitLabel", "getConfigUnit", "getIsEmpty"]),
        unitLabel() {
            return this.getUnitLabel(this.basePointer, this.UiNeedle);
        },
        unitConfig() {
            return this.getConfigUnit({
                basePointer: this.basePointer,
                configNeedle: this.configNeedle,
                activeUnit: -1
            });
        },
    }

};
