<template>
  <b-modal
    id="complex-parameter-modal"
    :title="paramName"
    body-class="p-0"
    centered
    no-fade
    ok-only
    scrollable
    size="xl"
  >
    <div class="row no-gutters">
      <div class="col-12 sticky p-3 bg-white border-bottom shadow-sm">
        <div class="w-100 p-0">
          <b-input-group class="mb-2" size="lg">
            <b-form-input
              v-model="searchVal"
              :placeholder="$t('app', 'search')"
            ></b-form-input>
          </b-input-group>
        </div>
      </div>
      <div class="col-12 p-3">
        <b-overlay :show="busyLoading">
          <table class="table table-hover table-bordered">
            <thead>
            <tr>
              <th
                v-for="(header, hIndex) in translatedHeaders"
                :key="hIndex"
                class="text-nowrap"
              >
                {{ header }}
              </th>
            </tr>
            </thead>
            <tbody>
            <tr
              v-for="(option, opIndex) in options"
              :key="opIndex"
              :class="{
                  'd-none': isHidden(opIndex),
                  'bg-primary': isSelected(opIndex),
                  'cursor-pointer': true,
                }"
              @click="submitParam(opIndex, option[headers[0]].value)"
            >
              <td
                v-for="(header, hIndex) in headers"
                :key="hIndex"
                :class="{ 'text-white': isSelected(opIndex) }"
              >
                <span v-html="option[headers[hIndex]].text"></span>
              </td>
              <td v-if="isComplexMedia" style="width: 20%">
                <b-carousel
                  id="carousel-1"
                  :interval="4000"
                  background="#ababab"
                  controls
                  indicators
                  style="text-shadow: 1px 1px 2px #333"
                >
                  <b-carousel-slide
                    v-for="media in option.medias.value"
                    :key="media.id"
                  >
                    <template #img>
                      <media-image
                        :datasource-id="
                              param.triggers[driver.groupIndex].datasource.suid
                            "
                        :media-id="media.id"
                      ></media-image>
                    </template>
                  </b-carousel-slide>
                </b-carousel>
              </td>
            </tr>
            </tbody>
          </table>
        </b-overlay>
      </div>
    </div>
  </b-modal>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  data() {
    return {
      busyLoading: false,
      sourceType: this.param.triggers[this.driver.groupIndex].datasource.type,
    };
  },
  props: {
    param: {
      required: true,
      type: Object,
      default: null,
    },
    driver: {
      required: true,
      type: Object,
      default: () => {
        return {show: false, groupIndex: -1};
      },
    },
    value: {
      required: true,
      validator: (prop) => typeof prop === "string" || prop === null,
      default: null,
    },
    text: {
      required: true,
      validator: (prop) => typeof prop === "string" || prop === null,
      default: null,
    },
    configAttribs: {
      required: true,
      type: Object,
      default: null,
    },
    options: {
      required: true,
      validator: (prop) => Array.isArray(prop) || prop === null,
      default: () => {
        return [];
      },
    },
    paramName: {
      required: true,
      type: String,
      default: "",
    },
    invalidFeedback: {
      required: false,
      type: String,
      default: null,
    },
    basePointer: {
      type: Object,
      required: true,
      default: null,
    },
    configNeedle: {
      type: Array,
      required: true,
      default() {
        return [];
      },
    },
    UiNeedle: {
      type: Array,
      required: true,
      default: () => {
        return [];
      },
    },
    activeUnit: {
      required: true,
      type: Number,
      default: -1,
    },
    activeParam: {
      required: true,
      type: Number,
      default: -1,
    },
  },
  methods: {
    ...mapActions(["saveParam", "updateUnit"]),
    async submitParam(optionIndex, newValue) {
      if (this.value === newValue) {
        return;
      }
      let valueColumn = this.headers[0];

      let paramConfigNeedle = this.configNeedle.concat([
        [this.activeUnit, this.activeParam],
      ]);
      let paramUiMapPointer = this.$calcUiPointer(
        this.basePointer.unitLabel,
        this.UiNeedle
      );
      let newText =
        optionIndex > -1
          ? this.options[optionIndex][valueColumn].text
          : newValue;

      const upData = {
        basePointer: this.basePointer.unitLabel,
        mastervariable: this.param.mastervariable,
        configNeedle: paramConfigNeedle,
        uiMapPointer: paramUiMapPointer,
        value: newValue,
        text: newText,
      };
      const response = await this.saveParam(upData);
      this.invalidFeedback = response;
      if (optionIndex > -1) {
        await this.overwriteParams(optionIndex);
      }
    },
    async overwriteParams(optionIndex) {
      if (this.overwrites.secondary.length > 0) {
        let overwriteGuide = [];
        for (let i = 0; i < this.overwrites.secondary.length; i++) {
          const element = this.overwrites.secondary[i];
          if (element.target_param) {
            let overwriteValue =
              this.options[optionIndex][element.source_param].text;
            overwriteValue = this.$mutateValue(
              overwriteValue,
              element.operator,
              element.argument,
              null
            );
            overwriteGuide.push({
              mastervariable: element.target_param,
              value: overwriteValue,
            });
          }
        }
        const unitUpdatedReversed = await this.updateUnit({
          configNeedle: this.configNeedle,
          basePointer: this.basePointer.unitLabel,
          activeUnit: this.activeUnit,
          overwrites: overwriteGuide,
        });
        return !unitUpdatedReversed;
      }
      return false;
    },
    reset() {
      this.submitParam(-1, null);
    },
    isHidden(opIndex) {
      if (!this.searchVal) {
        return false;
      }
      let optionValues = Object.values(this.options[opIndex]).filter((attrib) =>
        attrib.text.toLowerCase().includes(this.searchVal.toLowerCase())
      );
      return optionValues.length === 0;
    },
    isSelected(index) {
      return this.selectedOption.opIndex == index;
    },
    calcOptionIndex(v) {
      let valueColumn = this.headers[0];
      return _.findIndex(
        this.options,
        (element) => element[valueColumn].value === v
      );
    },
  },
  computed: {
    ...mapGetters(["getDatasourceTransKey", "getComplexProps"]),
    headers() {
      var headers = [];
      let primaryHeader = this.overwrites.primary.source_param;
      headers.push(primaryHeader);

      for (let i = 0; i < this.overwrites.secondary.length; i++) {
        const element = this.overwrites.secondary[i];
        if (!headers.includes(element.source_param)) {
          headers.push(element.source_param);
        }
      }
      return headers;
    },
    translatedHeaders() {
      let translatedHeaders = [];
      let translationStub =
        this.sourceType == 5 ? "datasources_" + this.label : "parameters";
      let primaryHeader = this.$t(
        translationStub,
        this.overwrites.primary.source_param
      );
      translatedHeaders.push(primaryHeader);

      for (let i = 0; i < this.overwrites.secondary.length; i++) {
        const element = this.overwrites.secondary[i];
        let secondaryHeader = this.$t(translationStub, element.source_param);
        if (!translatedHeaders.includes(secondaryHeader)) {
          translatedHeaders.push(secondaryHeader);
        }
      }
      return translatedHeaders;
    },
    selectedOption() {
      let retObj = {
        opIndex: -1,
        opText: this.text,
      };
      if (this.options !== null && this.options.length && this.headers.length) {
        retObj.opIndex = this.calcOptionIndex(this.value);
      }
      return retObj;
    },
    label() {
      return this.getDatasourceTransKey(
        this.param.triggers[this.driver.groupIndex].datasource.suid
      );
    },
  },
};
</script>
