const __ = {
    install(Vue, options) {
        Vue.prototype.__ = function (key, params = {}) {
            const allTexts = this.$store.getters.getAllTexts;
            // allText need to be retrieved from the store which is retrieved from the API
            // So it should wait for the API call to finish before it can be used.
            if (Object.keys(allTexts).length === 0) {
                return '..';
            }

            //Split the key into a path
            let path = key.split(".");

            // Search for the correct translation by the path parts.
            let translatedText = path.reduce((acc, part) => {
                return (acc && acc.hasOwnProperty(part)) ? acc[part] : undefined;
            }, allTexts);

            //Check if translation was found
            if (translatedText) {
                // Perform parameter replacement in the translated text
                // It replaces every :param with the value of params[param]
                if (Object.keys(params).length > 0) {
                    Object.keys(params).forEach(function (param) {
                        translatedText = translatedText.replace(':' + param, params[param]);
                    });
                }
                return translatedText;
            } else {
                // Do something if translation is not found
                console.error(`Translation not found for key "${key}"`);
                return key;
            }
        };
    }
};

export default __;
