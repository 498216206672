<template>
  <transition
    enter-active-class="animate__animated animate__fadeIn"
    leave-active-class="animate__animated animate__fadeOut"
    name="uielement-transition"
  >
    <div v-if="driver.show" class="w-100">
      <div class="w-100">
        <component :is="element.element" v-bind="configAttribs">
          <span v-if="element.has_content">{{ element.content }}</span>
        </component>
      </div>
    </div>
  </transition>
</template>
<script>
export default {
  props: {
    element: {
      required: true,
      type: Object,
      default: null,
    },
    driver: {
      required: true,
      type: Object,
      default: () => {
        return {show: false, groupIndex: -1};
      },
    },
  },
  computed: {
    configAttribs() {
      let retAttribs = {};
      let groupIndex = this.driver.groupIndex;
      if (groupIndex == -1 && Object.keys(this.element.attributes).length > 0) {
        retAttribs = this.element.attributes;
      } else {
        retAttribs = this.element.triggers[groupIndex].attribs;
      }
      if (Object.keys(retAttribs).length > 0) {
        return retAttribs;
      }
      return {};
    },
  },
};
</script>
