import {mapGetters, mapActions} from "vuex";

export const multipleUnit = {
    data() {
        return {
            activeUnit: -1,
            showUnitTable: true,
            inputFile: null,
            tableShow: [],
            tableKeep: [],
            perPageOptions: [5, 10, 25, 50, 100],
            currentPage: 1,
            perPage: 10,
            totalUnits: 1,
            tableInFocus: true,
            busy: false
        };
    },
    props: {
        idStub: {
            type: String,
            required: true,
            default: ''
        },
        basePointer: {
            type: Object,
            required: true,
            default: null,
        },
        driver: {
            required: false,
            type: Object,
            default: () => {
                return {show: true, groupIndex: -1};
            },
        },
        UiNeedle: {
            type: Array,
            required: true,
            default: () => {
                return [];
            },
        },
        configNeedle: {
            type: Array,
            required: true,
            default() {
                return [];
            },
        },
    },
    methods: {
        ...mapActions([
            "newUnit",
            "deleteUnit",
            "loadUnit",
            "importUnitFlat",
            "exportUnitFlat",
            "importUnit",
            "exportUnit",
            "changeOrderMultipleUnit"
        ]),
        async addUnit(unitIndex = -1) {
            this.busy = true;
            var newIndex = -1;
            const response = await this.newUnit({
                basePointer: this.basePointer.unitLabel,
                configNeedle: this.configNeedle,
                uIndex: unitIndex,
            });
            newIndex = response;
            this.editUnit(newIndex - 1);
            this.currentPage = Math.floor(this.totalUnits / this.perPage) + 1;
            this.busy = false;
        },
        async removeUnit(unitIndex) {
            this.busy = true;
            this.activeUnit = -1;
            this.$root.$emit("bv::dropdown::hide");
            await this.deleteUnit({
                basePointer: this.basePointer.unitLabel,
                configNeedle: this.configNeedle,
                uIndex: unitIndex,
            });
            this.busy = false;
        },
        editUnit(unitIndex) {
            this.activeUnit = unitIndex >= 0 ? parseInt(unitIndex) : -1;
            this.tableInFocus = this.activeUnit > -1 ? true : false;
        },
        async importExcel() {
            if (this.inputFile) {
                var formData = new FormData();
                formData.append("inputFile", this.inputFile);
                formData.append("basePointer", this.basePointer.unitLabel);
                formData.append("configNeedle", JSON.stringify(this.configNeedle));
                await this.importUnitFlat({
                    formData: formData,
                    unitData: {
                        basePointer: this.basePointer.unitLabel,
                        configNeedle: this.configNeedle,
                    },
                });
                this.resetInputFile();
            }
        },
        async importJSON() {
            if (this.inputFile) {
                var formData = new FormData();
                formData.append("inputFile", this.inputFile);
                formData.append("basePointer", this.basePointer.unitLabel);
                formData.append("configNeedle", JSON.stringify(this.configNeedle));
                await this.importUnit({
                    formData: formData,
                    unitData: {
                        basePointer: this.basePointer.unitLabel,
                        configNeedle: this.configNeedle,
                    },
                });
                this.resetInputFile();
            }
        },
        async checkChangeRecursive(evt) {
            if (!evt.hasOwnProperty('moved')) {
                return;
            }
            this.busy = true;
            let oldIndex = evt.moved.oldIndex;
            let newIndex = evt.moved.newIndex;
            if (newIndex !== oldIndex) {
                await this.prepareMove(oldIndex, newIndex);
            }
            this.busy = false;
        },
        async checkMove(evt) {
            this.busy = true;
            let oldIndex = evt.oldIndex;
            let newIndex = evt.newIndex;
            if (newIndex !== oldIndex) {
                await this.prepareMove(oldIndex, newIndex);
            }
            this.busy = false;
        },
        async prepareMove(oldIndex, newIndex) {
            if (this.currentPage > 1) {
                oldIndex = ((this.currentPage - 1) * this.perPage) + oldIndex;
                newIndex = ((this.currentPage - 1) * this.perPage) + newIndex;
            }
            await this.changeOrderMultipleUnit({
                configNeedle: this.configNeedle,
                basePointer: this.basePointer.unitLabel,
                oldIndex: oldIndex,
                newIndex: newIndex
            });
        },
        validateDraggable(evt) {
            if (this.activeUnit > -1 || evt.from.id !== evt.to.id) {
                return false;
            }

            return true;

        },
        exportExcel() {
            this.exportUnitFlat({
                basePointer: this.basePointer.unitLabel,
                configNeedle: this.configNeedle,
            }).then((response) => {
                var url = window.URL.createObjectURL(new Blob([response.data]));
                var link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", this.basePointer.unitLabel + ".xlsx");
                link.setAttribute("target", "_blank");
                document.body.appendChild(link);
                link.click();
                link.remove();
            });
        },
        exportJSON() {
            this.exportUnit({
                basePointer: this.basePointer.unitLabel,
                configNeedle: this.configNeedle,
            }).then((response) => {
                var url = window.URL.createObjectURL(new Blob([response.data]));
                var link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", this.basePointer.unitLabel + ".json");
                link.setAttribute("target", "_blank");
                document.body.appendChild(link);
                link.click();
                link.remove();
            });
        },
        resetInputFile() {
            this.inputFile = null;
        },
        calcIndex(paramMasterVariable) {
            if (this.unitConfig.length === 0 || !paramMasterVariable) {
                return -1;
            }
            for (let index = 0; index < this.unitConfig[0].length; index++) {
                if (this.unitConfig[0][index].mastervariable == paramMasterVariable) {
                    return index;
                }
            }

            return -1; // null vs false vs message ?
        },
        planTable() {
            for (let index = 0; index < this.unit.canvas.length; index++) {
                if (
                    this.unit.canvas[index].hasOwnProperty("table_view") &&
                    this.unit.canvas[index].hasOwnProperty("parameter_id")
                ) {
                    switch (this.unit.canvas[index].table_view.show) {
                        case 1:
                            this.tableShow.push(this.unit.canvas[index]);
                            break;
                        case 2:
                            this.tableShow.push(this.unit.canvas[index]);
                            this.tableKeep.push(this.unit.canvas[index]);
                            break;

                        default:
                            break;
                    }

                    // sort by order
                    this.tableShow.sort(function (a, b) {
                        return a.order - b.order;
                    });
                    this.tableKeep.sort(function (a, b) {
                        return a.order - b.order;
                    });
                }
            }
        },
    },
    watch: {
        activeUnit: function (val) {
            if (val > -1) {
                if (val < this.perPage * (this.currentPage - 1)) {
                    this.currentPage--;
                } else if (val >= this.perPage * this.currentPage) {
                    this.currentPage++;
                }
            }
        },
    },
    created() {
        this.planTable();
    },
    computed: {
        ...mapGetters(["getUnit", "getConfigUnitPaginated"]),
        unit() {
            return this.getUnit(this.basePointer, this.UiNeedle);
        },
        unitConfig() {
            const configUnit = this.getConfigUnitPaginated({
                basePointer: this.basePointer,
                configNeedle: this.configNeedle,
                currentPage: this.currentPage,
                perPage: this.perPage,
                activeUnit: -1,
            });
            this.totalUnits = configUnit.totalUnits;
            return configUnit.loadedUnits;
        },
        tableView() {
            return this.activeUnit == -1 ? this.tableShow : this.tableKeep;
        },
        offset() {
            return this.perPage * (this.currentPage - 1);
        },
        singleUnitHighlighted() {
            return this.activeUnit === -1
                ? false
                : this.showUnitTable
                    ? !this.tableInFocus
                    : false;
        },
    }
};
