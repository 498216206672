<template>
  <div class="container mt-4">
    <input
      v-model="inputData1"
      class="form-control mb-2"
      placeholder="Enter data for Curve 1 separated by commas (e.g., 10, 20, 30)"
      type="text"
      @input="updateChart"
    />
    <input
      v-model="inputData2"
      class="form-control mb-2"
      placeholder="Enter data for Curve 2 separated by commas (e.g., 5, 15, 25)"
      type="text"
      @input="updateChart"
    />
    <canvas ref="myChartCanvas"></canvas>
  </div>
</template>
<script>
import Chart from "chart.js";

export default {
  props: {
    inputData: [],
  },
  data() {
    return {
      inputData1: "", // Der Benutzer gibt die Daten hier im Textfeld ein (z.B., "10, 20, 30")
      inputData2: "",
      chartData1: [], // Die eingegebenen Daten werden in dieses Array umgewandelt und im Diagramm verwendet
      chartData2: [],
      myChart: null, // Referenz zum Chart.js-Diagramm
    };
  },
  mounted() {
    this.updateChart(); // Aktualisieren des Diagramms bei der Initialisierung
  },
  methods: {
    updateChart() {
      this.chartData1 = this.parseInputData(this.inputData1);
      this.chartData2 = this.parseInputData(this.inputData2); // Umwandeln der Eingabedaten in ein Array von Zahlen
      this.updateChartData(); // Aktualisieren des Diagramms mit den neuen Daten
    },
    parseInputData(inputData) {
      return inputData
        .split(",")
        .map((value) => parseFloat(value.trim()))
        .filter((value) => !isNaN(value));
    },
    updateChartData() {
      if (this.myChart) {
        this.myChart.data.labels = this.chartData1.map(
          (_, index) => `Label ${index + 1}`
        );
        this.myChart.data.datasets[0].data = this.chartData1;
        this.myChart.data.datasets[1].data = this.chartData2;
        this.myChart.update();
      } else {
        this.createChart();
      }
    },
    createChart() {
      const ctx = this.$refs.myChartCanvas.getContext("2d");
      this.myChart = new Chart(ctx, {
        type: "line", // Oder ein anderes Diagrammtyp wie 'bar', 'line', 'pie', 'doughnut', etc.
        data: {
          labels: this.chartData1.map(
            (_, index) => `Label ${index + 1}`
          ),
          datasets: [
            {
              label: "Curve 1",
              data: this.chartData1,
              backgroundColor: "rgba(75, 192, 192, 0.2)",
              borderColor: "rgba(75, 192, 192, 1)",
              borderWidth: 1,
              fill: false,
            },
            {
              label: "Curve 2",
              data: this.chartData2,
              borderColor: "rgba(192, 75, 192, 1)",
              borderWidth: 1,
              fill: false,
            },
          ],
        },
        options: {
          scales: {
            y: {
              beginAtZero: true,
            },
          },
        },
      });
    },
  },
};
</script>
