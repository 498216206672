<template>
  <div :class="{ 'highlight': isHighlighted }" class="document-display">
    <a :href="fileName" :title="__('central.datasource.download_document_title',{'fileName': fileName})"
       download
       @click.prevent="downloadDocument">
      <div class="document-icon">
        <i v-if="isPdf" class='fal fa-file-pdf'></i>
        <i v-if="isDoc" class='fal fa-file-word'></i>
        <i v-if="isXls" class='fal fa-file-excel'></i>
        <div class="file-name">{{ fileName }}</div>
      </div>
    </a>
    <span
      v-if="entryId"
      :title="__('central.datasource.delete_document')"
      style="cursor: pointer; margin-left: 20px;"
      @click="removeDocument"
    >
       <i class="far fa-fw fa-trash-alt"></i>
    </span>
  </div>
</template>
<script>
export default {
  props: {
    document: {
      type: Object,
      required: true,
    },
    datasourceId: {
      type: Number,
      required: true,
    },
    entryId: {
      type: Number,
      required: false,
    },
    highlightedDocuments: {
      type: Array,
      required: false,
      default: () => {
        return [];
      },
    }
  },
  data() {
    return {
      fileName: this.document.file_name,
      fileType: this.document.mime_type,
    };
  },
  computed: {
    isPdf() {
      return this.fileType === 'application/pdf';
    },
    isDoc() {
      return this.fileType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
    },
    isXls() {
      return this.fileType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    },
    isHighlighted() {
      return this.highlightedDocuments.includes(this.document.id);
    },

  },
  methods: {
    downloadDocument() {
      axios({
        url: '/api/download-document',
        method: 'POST',
        data: {
          datasourceId: this.datasourceId,
          documentId: this.document.id,
          fileName: this.fileName,
        },
        responseType: 'blob', // This ensures the response is handled as a Blob
      })
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const a = document.createElement('a');
          a.style.display = 'none';
          a.href = url;
          // Use the fileName or a default name if not available
          a.download = this.fileName || 'downloaded-file';
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
          a.remove();
        })
        .catch(error => {
          console.error('There was an issue downloading the file:', error);
        });
    },
    removeDocument() {
      if (this.document.id && this.entryId) {
        this.$bvModal
          .msgBoxConfirm(this.__('messages.confirm_delete_document_msg'), {
            title: this.__('messages.confirm_title'),
            size: "ml",
            buttonSize: "sm",
            okVariant: "danger",
            okTitle: this.__('messages.confirm_yes'),
            cancelTitle: this.__('messages.confirm_no'),
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          })
          .then((value) => {
            if (value == true) {
              axios({
                url: '/api/datasources/' + this.datasourceId + '/delete-document',
                method: 'POST',
                data: {
                  document_id: this.document.id,
                  entry_id: this.entryId,
                }
              })
                .then((response) => {
                  this.$emit("deleteDocument", response.data);
                })
                .catch((error) => {
                  console.error('There was an issue on deleting the file:', error);
                });
            }
          })
          .catch((error) => {
            console.error('Delete the document failed:', error);
          });
      }
    },
  },
};
</script>

<style scoped>
.document-display {
  padding: 10px;
}

.document-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.document-icon img {
  width: 25px;
  height: 25px;
}

.document-icon i {
  font-size: 2em;
}

.file-name {
  font-size: 10px;
  margin-top: 8px;
}

.highlight {
  background-color: #E6FFB6;
}
</style>

