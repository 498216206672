<template>
  <div class="w-100">
    <b-form-group v-if="this.param.element == 'param-image-upload'">
      <div v-if="value">
        <b-img
          :src="imgShowSrc"
          alt="Bild"
          class="image border-bottom-0"
          @click="openImageModal"
        />
      </div>
      <image-modal
        :img-url="imgShowSrc"
        :show-image-modal="showImageModal"
        @close="hideImageModal"
      ></image-modal>
      <b-input-group>
        <template v-if="value" #prepend>
          <b-dropdown
            no-caret
            toggle-class="text-decoration-none"
            variant="outline-dark"
          >
            <template #button-content>
              <i class="fas fa-bars"></i>
            </template>
            <b-dropdown-item @click="downloadFile">
              <i class="fas fa-download"></i> Download
            </b-dropdown-item>

            <b-dropdown-item variant="danger" @click="openDeleteModal">
              <i class="fas fa-trash-alt"></i> Löschen
            </b-dropdown-item>
          </b-dropdown>
        </template>
        <b-form-file
          v-model="file"
          :disabled="busy"
          :multiple="false"
          :placeholder="placeholderText"
          accept=".jpg, .jpeg, .gif, .png"
          @change="uploadSelectedFile"
        >
          <template v-if="!value">
            <div>Neue Datei hochladen</div>
          </template>

          <!-- Anzeige, wenn eine Datei ausgewählt ist -->
          <template v-else>
            <div>Existierende Datei ersetzen</div>
          </template>
        </b-form-file>
      </b-input-group>
    </b-form-group>
    <b-form-group v-else-if="this.param.element == 'param-file-upload'">
      <b-input-group v-if="value" class="w-100">
        <b-button class="w-100 d-flex align-items-center" disabled>
          <i
            v-if="value.endsWith('docx')"
            class="fal fa-2x fa-file-word mr-2"
          ></i>
          <i
            v-else-if="value.endsWith('pdf')"
            class="fal fa-2x fa-file-pdf mr-2"
          ></i>
          <i
            v-else-if="value.endsWith('zip')"
            class="fal fa-2x fa-file-archive mr-2"
          ></i>
          <i
            v-else-if="value.endsWith('xlsx')"
            class="fal fa-2x fa-file-excel mr-2"
          ></i>
          <i
            v-else-if="value.endsWith('stp')"
            class="fal fa-2x fa-file-cube mr-2"
          ></i>
          <i v-else class="fal fa-2x fa-file mr-2"></i>
          <span> {{ fileName }}</span>
        </b-button>
      </b-input-group>
      <b-input-group>
        <template v-if="value" #prepend>
          <b-dropdown
            no-caret
            toggle-class="text-decoration-none"
            variant="outline-dark"
          >
            <template #button-content>
              <i class="fas fa-bars"></i>
            </template>
            <b-dropdown-item @click="downloadFile">
              <i class="fas fa-download"></i> Download
            </b-dropdown-item>

            <b-dropdown-item variant="danger" @click="openDeleteModal">
              <i class="fas fa-trash-alt"></i> Löschen
            </b-dropdown-item>
          </b-dropdown>
        </template>
        <b-form-file
          v-model="file"
          :disabled="busy"
          :multiple="false"
          :placeholder="placeholderText"
          @change="uploadSelectedFile"
        >
          <template v-if="!value">
            <div>Neue Datei hochladen</div>
          </template>

          <!-- Anzeige, wenn eine Datei ausgewählt ist -->
          <template v-else>
            <div>Existierende Datei ersetzen</div>
          </template>
        </b-form-file>
      </b-input-group>
    </b-form-group>
    <delete-modal
      ref="deleteModal"
      :show-delete-modal="this.showDeleteModal"
      @close="closeDeleteModal"
      @file-deleted="deleteFile"
    />
  </div>
</template>
<script>
import {mapActions} from "vuex";
import POST from "../../../../../../node_modules/laravel-file-manager/src/http/post";
import GET from "../../../../../../node_modules/laravel-file-manager/src/http/get";
import ImageModal from "./ImageModal.vue";
import DeleteModal from "./DeleteModal.vue";

export default {
  components: {ImageModal, DeleteModal},
  props: {
    value: {
      required: true,
      default: null,
    },
    activeUnit: {
      required: true,
      type: Number,
      default: -1,
    },
    activeParam: {
      required: true,
      type: Number,
      default: -1,
    },
    basePointer: {
      type: Object,
      required: true,
      default: null,
    },
    configNeedle: {
      type: Array,
      required: true,
      default() {
        return [];
      },
    },
    isui: {
      required: false,
      type: Boolean,
      default: false,
    },
    UiNeedle: {
      type: Array,
      required: true,
      default: () => {
        return [];
      },
    },
    param: {
      required: true,
      type: Object,
      default: null,
    },
    driver: {
      required: true,
      type: Object,
      default: () => {
        return {show: false, groupIndex: -1};
      },
    },
    configAttribs: {
      required: true,
      type: Object,
      default: null,
    },
    translation: {
      required: true,
      type: String,
      default: "",
    },
  },
  data() {
    return {
      selectedFiles: [],
      imgFolder: "parameter_uploads",
      imgPath: null,
      imgDisk: "order",
      imgOverwrite: 0, //bedeutet, dass eine Datei, die bereits existiert, NICHT überschrieben wird
      imgUrl: null,
      imgShowSrc: `/media-pool/preview?disk=order&path=${this.value}`,
      isFileUploaded: false,
      showImageModal: false,
      showDeleteModal: false,

      file: null,

      busy: false,
      invalidFeedback: null,
    };
  },
  methods: {
    ...mapActions(["saveParam"]),

    // Value of parameter will be saved to json-structure
    async submitParam() {
      this.busy = true;
      let newValue = this.imgUrl;
      if (!this.isui && this.configValue !== newValue) {
        const paramConfigNeedle = this.configNeedle.concat([
          [this.activeUnit, this.activeParam],
        ]);
        const paramUiMapPointer = this.$calcUiPointer(
          this.basePointer.unitLabel,
          this.UiNeedle
        );
        const upData = {
          basePointer: this.basePointer.unitLabel,
          mastervariable: this.param.mastervariable,
          configNeedle: paramConfigNeedle,
          uiMapPointer: paramUiMapPointer,
          value: newValue,
          text: newValue,
        };
        const response = await this.saveParam(upData);

        if (response) {
          this.invalidFeedback = response;
        }
      }
      this.busy = false;
    },

    openImageModal() {
      this.showImageModal = true;
    },
    hideImageModal() {
      this.showImageModal = false;
    },

    uploadSelectedFile(event) {
      this.selectedFiles = event.target.files;
      this.imgPath = this.imgFolder;
      // Funktion analog zu Upload Funktion des File-Managers in: C:\xampp\htdocs\iso\node_modules\laravel-file-manager\src\store\actions.js
      const data = new FormData(); // create new form data

      data.append("disk", this.imgDisk);
      data.append("path", this.imgPath);
      data.append("overwrite", this.imgOverwrite);
      // add file(s)
      for (let i = 0; i < this.selectedFiles.length; i += 1) {
        data.append("files[]", this.selectedFiles[i]);
      }
      // upload file(s)
      return POST.upload(data).then((response) => {
        if (response.data.result.status === "success") {
          this.imgUrl = `${this.imgFolder}/${this.selectedFiles[0].name}`;
          this.submitParam(); //gibt this.imgUrl als value des Parameters zurück
          this.file = null; // Zurücksetzen des File-Namens im b-form-file
        }
        return response;
      });
    },

    openDeleteModal() {
      this.showDeleteModal = true;
    },
    closeDeleteModal() {
      this.showDeleteModal = false;
    },
    deleteFile() {
      //Funktion aus Laravel-File-Manager, nur so angepasst, dass man Datei mit Pfadangabe löschen kann
      const itemsToDelete = [
        {
          path: this.value,
          type: "file", // 'dir' für Verzeichnisse
        },
      ];

      this.file = null; // Zurücksetzen des File-Namens im b-form-file
      POST.delete({
        disk: this.imgDisk,
        items: itemsToDelete,
      }).then((response) => {
        if (response.data.result.status === "success") {
          console.log("Löschen erfolgreich");
          this.imgUrl = null;
          this.submitParam(); //gibt this.imgUrl als value des Parameters zurück
          // Erfolgreich gelöscht
        } else {
          // Fehler beim Löschen
        }
      });
      // this.$bvModal.hide("deleteModal");
      this.showDeleteModal = false;
    },
    downloadFile() {
      const tempLink = document.createElement("a");
      tempLink.style.display = "none";
      tempLink.setAttribute("download", this.value);
      GET.download(this.imgDisk, this.value).then((response) => {
        tempLink.href = window.URL.createObjectURL(new Blob([response.data]));
        document.body.appendChild(tempLink);
        tempLink.click();
        document.body.removeChild(tempLink);
      });
    },
  },
  computed: {
    placeholderText() {
      return !this.value
        ? "Neue Datei hochladen"
        : "Existierende Datei ersetzen";
    },
    fileName() {
      return this.value.substring(this.value.lastIndexOf("/") + 1);
    },
  },
  watch: {
    value(newValue) {
      this.imgShowSrc = `/media-pool/preview?disk=${this.imgDisk}&path=${newValue}`;
    },
  },
};
</script>
<style>
.image {
  max-width: 100%; /* Bildbreite überschreitet nicht die Breite des div */
  max-height: 400px; /* Maximale Bildhöhe von 150px */
  width: auto; /* Breite flexibel */
  height: auto; /* Höhe flexibel */

  border-radius: 2px; /* Abrundung der Ecken */
  border: 1px solid rgb(0, 0, 0); /* Dünner Rahmen */
  cursor: pointer;
}
</style>
