<template>
  <div class="w-100" :class="{ 'is-invalid': invalidInput.message }">
    <div class="input-group">
      <tinymce-editor
        :init="tinyInit"
        v-model="tinyVal"
        model-events="blur"
        initial-value=""
        @input="updateBladeVal"
      />
      <textarea v-if="bladeInput" :name="bladeInput" class="d-none"
      >{{ bladeVal }}
      </textarea>
    </div>
  </div>
</template>

<script>
import "tinymce";
import tinyMCEEditor from "@tinymce/tinymce-vue";

import "tinymce/icons/default";
import "tinymce/themes/silver";

import {mapGetters} from "vuex";

export default {
  data() {
    return {
      bladeVal: "",
    };
  },
  props: {
    value: {
      required: true,
      validator: (prop) => typeof prop === "string" || prop === null,
      default: null,
    },
    id: {
      required: true,
      validator: (prop) => typeof prop === "string" || prop === null,
      default: null,
    },
    invalidInput: {
      required: true,
    },
    bladeInput: {
      required: false,
      validator: (prop) => typeof prop === "string" || prop === null,
      default: null,
    },
  },
  components: {
    "tinymce-editor": tinyMCEEditor,
  },
  methods: {
    updateBladeVal(val) {
      if (this.bladeInput) {
        this.bladeVal = val;
      }
    },
  },
  created() {
    if (this.bladeInput) {
      this.bladeVal = this.value;
    }
  },
  computed: {
    ...mapGetters(["getLang"]),
    tinyInit() {
      return {
        plugins: "paste lists",
        menubar: false,
        statusbar: true,
        toolbar:
          "undo redo | bold italic underline | numlist bullist | outdent indent",
        branding: false,
        language: this.getLang,
        width: "100%",
        height: "300px",
        entity_encoding: "named",
        paste_auto_cleanup_on_paste: true,
        paste_remove_spans: true,
        paste_remove_styles: true,
        paste_text_sticky: true,
        paste_strip_class_attributes: "all",
        paste_as_text: true,
        resize: true
      };
    },
    tinyVal: {
      get() {
        if (this.value) {
          let cleanVal = this.value.replace(/(\r\n|\n|\r)/gm, "");
          return cleanVal;
        }
      },
      set(val) {
        let cleanVal = val.replace(/(\r\n|\n|\r)/gm, "");
        this.$emit("change", cleanVal);
      },
    },
  },
};
</script>
