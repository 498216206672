<template>
  <transition
    enter-active-class="animate__animated animate__fadeIn"
    leave-active-class="animate__animated animate__fadeOut"
    name="recursive-multiple-unit-transition"
  >
    <b-overlay :show="busy" variant="transparent" z-index="1000">
      <div v-if="driver.show" class="d-flex">
        <div
          v-if="showUnitTable == true"
          :class="{
            'flex-grow-1 pr-3': activeUnit === -1,
            'flex-grow-0 pr-15px': activeUnit > -1,
            'h-100 multiple-unit-section-transition': true,
          }"
          @mouseover="tableInFocus = true"
        >
          <div class="d-flex flex-column w-100 h-100">
            <table
              :id="'table-' + idStub"
              class="table table-bordered recursive-table"
            >
              <thead :id="'table-head-' + idStub">
              <tr>
                <th v-if="activeUnit === -1"></th>
                <th
                  v-for="(tableHeader, thIndex) in tableView"
                  :key="thIndex"
                  class="p-2 text-truncate"
                  scope="col"
                >
                  <span>{{ $t("parameters", tableHeader.label) }}</span>
                </th>
                <th class="p-2" width="10%">
                  <div class="d-flex justify-content-center">
                    <b-dropdown
                      v-if="activeUnit == -1"
                      :id="'import-dropdown-' + idStub"
                      v-b-tooltip.hover
                      :title="$t('order', 'import')"
                      class="dropdown-toggle-no-caret mx-1"
                      menu-class="p-2 border border-dark dd-upload-form shadow"
                      no-caret
                      right
                      toggle-class="text-decoration-none"
                      variant="outline-warning"
                    >
                      <template v-slot:button-content>
                        <i class="fas fa-download fa-rotate-180"></i>
                      </template>
                      <b-dropdown-form>
                        <b-form-file
                          :id="'file-input-' + idStub"
                          v-model="inputFile"
                          accept=".json"
                          class="w-100"
                          drop-placeholder="Drop file here..."
                          placeholder="Choose or drop file..."
                        ></b-form-file>
                        <div class="w-100 mt-2 text-right">
                          <b-button
                            v-b-tooltip.hover
                            :disabled="busy"
                            :title="$t('app', 'reset')"
                            variant="outline-dark"
                            @click="resetInputFile"
                          >
                            <i class="fas fa-times"></i>
                          </b-button>
                          <b-button
                            v-b-tooltip.hover
                            :disabled="busy"
                            :title="$t('order', 'import')"
                            variant="outline-warning"
                            @click="importJSON"
                          >
                            <i class="fas fa-check"></i>
                          </b-button>
                        </div>
                      </b-dropdown-form>
                    </b-dropdown>
                    <b-button
                      v-b-tooltip.hover
                      :disabled="busy"
                      :title="$t('order', 'export')"
                      class="mx-1"
                      variant="outline-dark"
                      @click="exportJSON"
                    >
                      <i class="fas fa-upload fa-rotate-180"></i>
                    </b-button>
                    <b-button
                      v-if="activeUnit == -1"
                      :disabled="!contentLevel || busy"
                      class="mx-1"
                      variant="outline-dark"
                    >
                      <i class="fas fa-lock"></i>
                    </b-button>

                    <span
                      v-b-tooltip.hover
                      :title="$t('app', 'create')">
                        <b-button
                          v-b-tooltip.hover
                          :disabled="busy"
                          :title="$t('app', 'create')"
                          class="mx-1"
                          variant="outline-dark"
                          @click="addUnit(-1)"
                        >
                          <i class="fas fa-plus"></i>
                        </b-button>
                      </span>
                  </div>
                </th>
              </tr>
              </thead>
              <draggable
                v-if="unitConfig.length > 0 && tableView.length > 0"
                :id="'table-body-' + idStub"
                :list="unitConfig"
                :move="validateDraggable"
                animation="150"
                class="w-100 list-group-multiple"
                ghost-class="ghost"
                handle=".handle"
                tag="div"
                @change="checkChangeRecursive"
              >
                <tbody
                  v-for="(cUnit, cindex) in unitConfig"
                  :key="'table-row-group-' + idStub + (cindex + +offset)"
                >
                <tr
                  :id="'unit-row-' + idStub + '-' + (+cindex + +offset)"
                  :key="'table-row-overview-' + idStub + (cindex + +offset)"
                  :class="{
                      'table-secondary': cindex + offset == activeUnit,
                    }"
                >
                  <td
                    v-if="activeUnit === -1"
                    class="handle-column align-middle"
                  >
                    <i
                      v-b-tooltip.hover
                      :title="$t('app', 'reorder')"
                      class="fas fa-grip-lines handle"
                    ></i>
                  </td>
                  <td
                    v-for="(tableCell, tcIndex) in tableView"
                    :key="tcIndex"
                    class="text-truncate align-middle max-w-150px"
                  >
                      <span
                        v-html="
                          unitConfig[cindex][
                            calcIndex(tableCell.mastervariable)
                          ].text
                        "
                      ></span>
                  </td>
                  <td>
                    <div class="d-flex justify-content-center">
                      <b-dropdown
                        :id="'del-unit-' + idStub + '-' + (+cindex + +offset)"
                        v-b-tooltip.hover
                        :disabled="busy"
                        :title="$t('app', 'delete')"
                        class="mx-1"
                        dropleft
                        menu-class="p-0 mr-1 min-w-0 border-0 bg-transparent"
                        no-caret
                        offset="0"
                        variant="outline-warning"
                      >
                        <template v-slot:button-content>
                          <i class="fas fa-times"></i>
                        </template>
                        <b-dropdown-item link-class="p-0 bg-transparent">
                          <b-button
                            :disabled="busy"
                            variant="outline-danger"
                            @click="removeUnit(cindex + offset)"
                          >
                            <i class="far fa-trash-alt"></i>
                          </b-button>
                        </b-dropdown-item>
                      </b-dropdown>
                      <b-button
                        v-b-tooltip.hover
                        :disabled="busy"
                        :title="$t('app', 'edit')"
                        class="mx-1"
                        variant="outline-dark"
                        @click="editUnit(cindex + offset)"
                      >
                        <i class="fas fa-edit"></i>
                      </b-button>
                      <b-button
                        v-if="activeUnit == -1"
                        v-b-tooltip.hover
                        :disabled="busy"
                        :title="$t('app', 'duplicate')"
                        class="mx-1"
                        variant="outline-dark"
                        @click="addUnit(cindex + offset)"
                      >
                        <i class="far fa-copy"></i>
                      </b-button>
                      <b-button
                        v-if="activeUnit == -1 && level < maxLevels - 1"
                        v-b-toggle="
                            'table-row-details-collapse-' +
                            idStub +
                            '-' +
                            (cindex + +offset)
                          "
                        :disabled="!(level <= maxLevels) || busy"
                        class="mx-1"
                        variant="outline-primary"
                      >
                          <span class="closed">
                            <i class="fas fa-chevron-down"></i>
                          </span>
                        <span class="open">
                            <i class="fas fa-chevron-up"></i>
                          </span>
                      </b-button>
                    </div>
                  </td>
                </tr>
                <b-collapse
                  v-if="activeUnit == -1 && level < maxLevels - 1"
                  :id="
                      'table-row-details-collapse-' +
                      idStub +
                      '-' +
                      (cindex + +offset)
                    "
                  :key="
                      'table-row-details-' + idStub + '-' + (cindex + +offset)
                    "
                  tag="tr"
                >
                  <td :colspan="tableView.length + 2" class="bg-light">
                    <recursive-multiple-unit
                      :base-pointer="basePointer"
                      :config-needle="calcConfigNeedle(cindex)"
                      :id-stub="idStub + '-' + (cindex + +offset)"
                      :level="level + 1"
                      :ui-needle="UiNeedle"
                    />
                  </td>
                </b-collapse>
                </tbody>
              </draggable>
            </table>
            <div
              :class="{
                'w-100': true,
                'd-flex justify-content-between': activeUnit === -1,
              }"
            >
              <div :class="{ 'mb-n1px': activeUnit > -1 }">
                <b-form-select
                  v-model="perPage"
                  :disabled="busy"
                  :options="perPageOptions"
                  class="border"
                ></b-form-select>
              </div>
              <div>
                <b-pagination
                  v-model="currentPage"
                  :disabled="busy"
                  :per-page="perPage"
                  :total-rows="totalUnits"
                  align="right"
                  class="mb-0"
                  first-text="First"
                  last-text="Last"
                  next-text="Next"
                  prev-text="Prev"
                >
                  <template #first-text
                  ><i class="far fa-chevron-double-left"></i
                  ></template>
                  <template #prev-text
                  ><i class="far fa-chevron-left"></i
                  ></template>
                  <template #next-text
                  ><i class="far fa-chevron-right"></i
                  ></template>
                  <template #last-text
                  ><i class="far fa-chevron-double-right"></i
                  ></template>
                </b-pagination>
              </div>
            </div>
          </div>
        </div>
        <div
          :class="{
            'mh-100 multiple-unit-section-transition bg-white z-900': true,
            'flex-grow-1': activeUnit > -1,
            'highlighted-single-unit shadow': singleUnitHighlighted,
          }"
          @mouseover="tableInFocus = false"
        >
          <div v-if="activeUnit > -1" class="w-100 h-100 border border-dark">
            <recursive-single-unit
              :key="idStub + '-' + activeUnit"
              :active-unit="activeUnit"
              :base-pointer="basePointer"
              :config-needle="configNeedle"
              :content-level="contentLevel"
              :grid="4"
              :id-stub="idStub + '-' + activeUnit"
              :level="level"
              :show-unit-table="showUnitTable"
              :ui-needle="UiNeedle"
              :unit-count="totalUnits"
            ></recursive-single-unit>
          </div>
        </div>
      </div>
      <template #overlay>
        <div class="text-center">
          <div class="unit-loader-content">
            <div class="unit-loader">
              <div class="unit-loader"></div>
            </div>
          </div>
        </div>
      </template>
    </b-overlay>
  </transition>
</template>

<script>
import {multipleUnit} from "../../../../../mixins/app/multipleUnit";

export default {
  mixins: [multipleUnit],
  props: {
    level: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  methods: {
    calcConfigNeedle(activeUnit) {
      return this.configNeedle.concat([
        [activeUnit, this.unitConfig[activeUnit].length - 1],
      ]);
    },
    planTable() {
      for (let index = 0; index < this.unit.canvas.length; index++) {
        let paramHasProps =
          this.unit.canvas[index].hasOwnProperty("level_view") &&
          this.unit.canvas[index].hasOwnProperty("table_view") &&
          this.unit.canvas[index].hasOwnProperty("parameter_id");

        if (!paramHasProps) {
          continue;
        }

        let showLevel = this.unit.canvas[index].level_view.show;

        if (showLevel == 0 && this.contentLevel) {
          continue;
        } else if (showLevel == 1 && !this.contentLevel) {
          continue;
        }

        switch (this.unit.canvas[index].table_view.show) {
          case 1:
            this.tableShow.push(this.unit.canvas[index]);
            break;
          case 2:
            this.tableShow.push(this.unit.canvas[index]);
            this.tableKeep.push(this.unit.canvas[index]);
            break;

          default:
            break;
        }

        // sort by order
        this.tableShow.sort(function (a, b) {
          return a.order - b.order;
        });
        this.tableKeep.sort(function (a, b) {
          return a.order - b.order;
        });
      }
    },
  },
  computed: {
    minLevels() {
      var targetRule = this.unit.rules.find(
        (element) => element.name == "min_levels"
      );
      if (targetRule !== undefined) {
        return targetRule.value;
      }
      return 0;
    },
    maxLevels() {
      var targetRule = this.unit.rules.find(
        (element) => element.name == "max_levels"
      );
      if (targetRule !== undefined) {
        return targetRule.value;
      }
      return 10;
    },
    contentLevel() {
      return this.level >= this.minLevels - 1 && this.level < this.maxLevels;
    },
  },
};
</script>
