// lodash
window._ = require('lodash');

// jQuery and jQuery Plugins
window.$ = window.jQuery = require('jquery');
require('select2');

// Bootstrap
window.Popper = require('@popperjs/core').default;
require('bootstrap');

// Vuejs
window.Vue = require('vue').default; // TBR
Vue.config.productionTip = false;

import __ from './plugins/common/translate-all';

Vue.use(__);


// AsyncComputed
window.AsyncComputed = require('vue-async-computed');
Vue.use(AsyncComputed);

// PortalVue
// window.portal-vue = require('portal-vue');
// Vue.use(PortalVue);

// BootstrapVue
window.BootstrapVue = require('bootstrap-vue');
Vue.use(BootstrapVue);

// Axios
import axios from 'axios';

window.axios = axios;

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

let token = document.head.querySelector('meta[name="csrf-token"]');

if (token) {
    window.axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content;
} else {
    console.error('CSRF token not found');
}

let locale = document.documentElement.lang ? document.documentElement.lang : 'de';
//let aioPath = '../../lang/' + locale + '/aio.json';

//let translation = import aioPath from "";

if (locale) {
    window.axios.defaults.headers.common['Accept-Language'] = locale;
}

// define axios interceptors

const MAX_REQUESTS_COUNT = process.env.MIX_API_MAX_CONCURRENT_REQUESTS ?
    process.env.MIX_API_MAX_CONCURRENT_REQUESTS : 5;
const INTERVAL_MS = 10;
let PENDING_REQUESTS = 0;

/**
 * Axios Request Interceptor
 */
window.axios.interceptors.request.use(function (config) {
    return new Promise((resolve, reject) => {
        let interval = setInterval(() => {
            if (PENDING_REQUESTS < MAX_REQUESTS_COUNT) {
                PENDING_REQUESTS++
                clearInterval(interval)
                resolve(config)
            }
        }, INTERVAL_MS)
    })
})

/**
 * Axios Response Interceptor
 */
window.axios.interceptors.response.use(function (response) {
    PENDING_REQUESTS = Math.max(0, PENDING_REQUESTS - 1)
    return Promise.resolve(response)
}, function (error) {
    PENDING_REQUESTS = Math.max(0, PENDING_REQUESTS - 1)
    return Promise.reject(error)
})


/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

import Echo from 'laravel-echo';
import {isUndefined} from 'lodash';

window.Pusher = require('pusher-js');

window.Echo = new Echo({
    broadcaster: 'pusher',
    key: process.env.MIX_PUSHER_APP_KEY,
    cluster: process.env.MIX_PUSHER_APP_CLUSTER,
    wsHost: process.env.MIX_PUSHER_HOST,
    wsPort: process.env.MIX_PUSHER_PORT,
    wssPort: process.env.MIX_PUSHER_PORT,
    forceTLS: process.env.MIX_PUSHER_SCHEME === 'https',
    encrypted: true,
    disableStats: true,
    enabledTransports: ['ws', 'wss'],
});
