<template>
  <li class="nav-item">
    <div class="h-100 d-flex flex-column">
      <div class="dropdown h-100 ">
        <div class="nav-link py-0 h-100">
          <a
            id="dropdownMenuLink"
            :aria-expanded="extendedDropdownOpen"
            aria-haspopup="true"
            class="text-ci-primary-contrast dropdown-toggle-no-caret text-decoration-none d-flex justify-content-start align-items-center flex-column border-left border-ci-primary-contrast-light px-2 h-100"
            data-toggle="dropdown"
            href="#"
            v-bind:title="$t('database','app.extended')"
            @click="extendedDropdownOpen = !extendedDropdownOpen">
            <span
              class="h-2rem mt-1 d-flex align-items-center justify-content-center min-w-3rem">
              <i :class="extendedDropdownOpen ? 'fal fa-chevron-circle-up' : 'fal fa-chevron-circle-down'"
                 class="nav-icon "></i>
            </span>
            <span
              class="extra-small mt-1 font-weight-lighter  max-w-5rem text-center">
              {{ $t('app', 'navbar-button-extended') }}
            </span>
          </a>
          <div class="dropdown-menu dropdown-menu-right mt-1">
            <div class="list-group font-weight-light list-group-minimal">
              <a :href="'/orders/' + orderId + '/import'"
                 class="text-dark list-group-item-action list-group-item text-nowrap border-left-0 border-right-0 d-flex align-items-center justify-content-between text-decoration-none">
                <span class="text-dark">{{ $t('order', 'import_order') }}</span>
                <span class="text-dark d-flex justify-content-center">
                  <i class="fal fa-download fa-rotate-180 nav-icon"></i>
                </span>
              </a>
              <b-button v-b-modal.order-export-modal
                        class="text-dark list-group-item-action list-group-item text-nowrap border-left-0 border-right-0 d-flex align-items-center justify-content-between text-decoration-none"
                        variant="link">
                <span class="text-dark">{{ $t('order', 'export_order') }}</span>
                <span class="text-dark d-flex justify-content-center">
                  <i class="fal fa-upload fa-rotate-180 nav-icon"></i>
                </span>
                <order-export-modal></order-export-modal>
              </b-button>
              <b-button v-b-toggle.order-snapshot-list
                        class="text-dark list-group-item-action list-group-item text-nowrap border-left-0 border-right-0 d-flex align-items-center justify-content-between text-decoration-none"
                        variant="link">
                <span class="text-dark">{{ $t('order', 'order_snapshots') }}</span>
                <span class="text-dark d-flex justify-content-center">
                  <i class="fal fa-history nav-icon"></i>
                </span>
              </b-button>
              <b-button v-b-toggle.order-file-manager
                        class="text-dark list-group-item-action list-group-item text-nowrap border-left-0 border-right-0 d-flex align-items-center justify-content-between text-decoration-none"
                        variant="link">
                <span class="text-dark">{{ $t('app', 'media') }}</span>
                <span class="text-dark d-flex justify-content-center">
                  <i class="fal fa-folder-open nav-icon"></i>
                </span>
              </b-button>
              <a :href="'/orders/' + orderId + '/info'"
                 class="text-dark list-group-item-action list-group-item text-nowrap border-left-0 border-right-0 d-flex align-items-center justify-content-between text-decoration-none">
                <span>{{ $t('app', 'edit_custom_attributes') }}</span>
                <span class="d-flex justify-content-center">
                  <i class="fal fa-edit nav-icon"></i>
                </span>
              </a>
              <a v-if="hasDevPermission" :href="'/orders/' + orderId + '/export-json'"
                 class="text-dark list-group-item-action list-group-item text-nowrap border-left-0 border-right-0 d-flex align-items-center justify-content-between text-decoration-none">
                <span class="text-truncate">{{
                    $t('order', 'export_order_json')
                  }}</span>
                <span class="d-flex justify-content-center">
                  <i class="fal fa-brackets-curly nav-icon"></i>
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </li>
</template>

<script>
export default {
  props: {
    orderId: {
      required: true
    },
    hasDevPermission: {
      required: true
    }
  },
  data() {
    return {
      extendedDropdownOpen: false, // this is added
    };
  },
};
</script>
