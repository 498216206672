import axios from 'axios';

const state = {
    translation: {},
    lang: document.documentElement.lang
};

const getters = {
    getLang: (state) => state.lang,
    getAllTexts: (state) => state.translation,
    getOrderTexts: (state) => {
        return state.translation.hasOwnProperty('database') ? state.translation.database.order : ''
    },
    getText: (state) => param => {
        return state.translation.hasOwnProperty('database') &&
        state.translation.database.hasOwnProperty(param.trans_group) &&
        state.translation.database[param.trans_group].hasOwnProperty(param.trans_key) ?
            state.translation.database[param.trans_group][param.trans_key] : '';
    },
    getCatsText: (state) => state.translation.database.configcats,
    getCatText: (state) => catLabel => state.translation.database.configcats[catLabel],
    getUnitsText: (state) => state.translation.database.configunits,
    getUnitText: (state) => unitlabel => state.translation.database.configunits[unitlabel],
    getParametersText: (state) => state.translation.database.parameters,
};

const actions = {
    async loadTranslation({commit}) {
        const locale = state.lang;
        const response = await axios.get("/api/translation/" + locale);
        commit('setTranslation', response.data);
    },
};

const mutations = {
    setTranslation: (state, transRes) => {
        state.translation = transRes.translation;
    },
};

export default {
    state,
    getters,
    actions,
    mutations
};
