export const ExcelHelper = {
    columnNumberToLetter(columnNumber) {
        let temp, letter = '';
        columnNumber++;
        while (columnNumber > 0) {
            temp = (columnNumber - 1) % 26;
            letter = String.fromCharCode(temp + 65) + letter;
            columnNumber = (columnNumber - temp - 1) / 26;
        }
        return letter;
    }
}
