<template>
  <b-card>
    <b-card-body>
      <b-row>
        <b-col v-if="unit.type !== 'flatSingle'" md="3">
          <b-select v-model="unit.status.mode" :disabled="unit.status.running">
            <option :value="null">{{ __('tenants.views.order_import.mode') }}</option>
            <!-- <option value="append">{{ __('tenants.views.order_import.mode_append') }}</option> -->
            <option value="overwrite">{{ __('tenants.views.order_import.mode_overwrite') }}
            </option>
          </b-select>
        </b-col>
        <b-col md="3">
          <b-btn v-if="unit.status.currentImportStepName === 'waiting'"
                 :disabled="unit.type !== 'flatSingle' && !unit.status.mode" class="w-100"
                 variant="primary" @click="importUnit(unit)"><i
            class="fal fa-fw fa-play-circle"></i>
            {{ __('tenants.views.order_import.start') }}
          </b-btn>
          <b-btn v-if="unit.status.currentImportStepName === 'finished'"
                 class="w-100" @click="importUnit(unit)"><i class="fal fa-fw fa-redo"></i>
            {{ __('tenants.views.order_import.restart') }}
          </b-btn>
          <b-btn
            v-if="unit.status.currentImportStepName !== 'waiting' && unit.status.currentImportStepName !== 'finished'"
            class="w-100" variant="danger" @click="abortImport(unit)"><i
            class="fal fa-fw fa-stop-circle"></i> {{ __('tenants.views.order_import.cancel') }}
          </b-btn>
        </b-col>
        <b-col md="6">
          <b-alert v-if="unit.status.mode === 'overwrite' && !unit.status.running" show>
            <i class="fal fa-fw fa-exclamation-triangle"></i>
            <span v-html="__('tenants.views.order_import.overwrite_notice')"></span><br><br>
            <strong v-if="unit.type !== 'flatSingle'">{{
                __('tenants.views.order_import.overwrite_amount_items_to_lose', {amount: unit.count})
              }}</strong>
          </b-alert>
        </b-col>
      </b-row>
      <hr class="mt-3 mb-2">
      <unit-progress-container :status="unit.status" :unit="unit"></unit-progress-container>
      <div v-if="unit.status.importFailed && Object.keys(unit.status.importFailed).length > 0"
           class="mh-400px"
           style="overflow-y: scroll">
        <hr class="mt-3 mb-2">
        <b-table :items="unit.status.importFailed" hover striped>
          <template v-slot:head(item)="data">
            <span>{{ __('tenants.views.order_import.row') }}</span>
          </template>
        </b-table>
      </div>
    </b-card-body>
  </b-card>
</template>
<script>

import UnitProgressContainer from "./UnitProgressContainer.vue";

export default {
  components: {UnitProgressContainer},
  props: {
    unit: Object,
    sheets: Object,
    sheetHeaders: [],
    orderData: Object,
  },
  data() {
    return {
      unitInProcess: this.unit,
      parentRow: null,
      parentAnchor: null,
      defaultItemStatus: {
        running: false,
        aborted: false,
        progress: 0,
        imported: false,
        error: false,
        info: "",
      },
      defaultItemParameterStatus: {
        imported: false,
        error: false,
        info: "",
      },
    }
  },
  methods: {
    async importUnit(unit, status = null, parentPointer = [], basePointer = null, parent = null) {
      status = status ?? unit.status;
      status.abort = false;
      status.currentImportStep = 0;
      status.running = true;
      switch (unit.type) {
        case 'flatMultiple':
        case 'nestedMultiple':
          await this.importMultipleUnit(unit, status, parentPointer, basePointer, parent);
          break;
        case 'flatSingle':
          await this.importFlatSingleUnit(unit, status, parentPointer, basePointer, parent);
          break;
      }
      status.running = false;
      status.currentImportStep++;
      status.currentImportStepName = 'finished';

    },
    async importFlatSingleUnit(unit, status = null, parentPointer = [], basePointer = null, parent = null) {
      status = status ?? unit.status;
      status.currentImportStepRemainingTime = 0;
      status.currentImportStep++;
      status.currentImportStepName = 'importing';
      status.deltaTimes = [];

      let startTime = Date.now();
      let finishedTime = Date.now();
      for (let i = 0; i < unit.data.length; i++) {
        const item = unit.data[i];
        item.status = {...this.defaultItemStatus};
        item.status.running = true;
        if (status.abort) {
          return;
        }
        startTime = Date.now();
        await this.patchParametersWithinUnit(unit, -1, item, status, parentPointer = [], basePointer = null, parent);
        finishedTime = Date.now();
        status.deltaTimes.push(finishedTime - startTime);
        item.status.imported = true;
        item.status.running = false;
        this.calculateRemainingTime(status, unit.data.length - i - 1);
        this.calculateImportProgress(unit, status);
      }
    },
    async importMultipleUnit(unit, status = null, parentPointer = [], basePointer = null, parent = null) {
      status = status ?? unit.status;
      for (let i = 0; i < unit.data.length; i++) {
        // Reset status for each item
        unit.data[i].status = {...this.defaultItemStatus};
        // Reset Parameter Status
        const keys = Object.keys(unit.data[i].parameters);
        for (let j = 0; j < keys.length; j++) {
          unit.data[i].parameters[keys[j]].status = {...this.defaultItemParameterStatus};
        }
      }
      if (status.mode === "overwrite") {
        status.importSteps = 2;
        await this.runStepCleaning(unit, status, parentPointer, basePointer, unit.count);
      }
      await this.runStepImporting(unit, status, parentPointer, basePointer, parent);
    },

    async deleteUnit(unit, basePointer, parentPointer, index, currentItemLength) {
      const startTime = Date.now();
      await axios.delete("/api/orders/" + this.orderData.info.id + "/units/" + JSON.stringify({
        basePointer: basePointer ?? unit.label,
        configNeedle: parentPointer,
        uIndex: 0, // uIndex is always 0 for deleting units, so it always deletes the first unit because the indices are shifting
      }));
      unit.count--;

      const finishedTime = Date.now();
      unit.status.deltaTimes.push(finishedTime - startTime);
      this.calculateRemainingTime(unit.status, index + 1);
      unit.status.deletionProcess = Math.round(((currentItemLength - index + 1) / currentItemLength) * 100);
    },

    async runStepCleaning(unit, status = null, parentPointer = [], basePointer = null, count) {
      status = status ?? unit.status;
      status.deletionProcess = 0;
      status.currentImportStep++;
      status.currentImportStepName = 'cleaning';
      status.deltaTimes = [];

      for (let i = count - 1; i >= 0; i--) {
        if (status.abort) {
          break;
        }
        await this.deleteUnit(unit, basePointer, parentPointer, i, count);
      }
    },
    async runStepImporting(unit, status = null, parentPointer = [], basePointer = null, parent = null) {
      status = status ?? unit.status;
      status.currentImportStepRemainingTime = 0;
      status.currentImportStep++;
      status.currentImportStepName = 'importing';
      status.deltaTimes = [];
      let startTime = Date.now();
      let finishedTime = Date.now();
      for (let i = 0; i < unit.data.length; i++) {
        if (status.abort) {
          return;
        }
        startTime = Date.now();
        const item = unit.data[i];
        if (!item.config.nexus ||
          (parent && item.config.nexus.id === parent.anchor
            && item.config.nexus.sheet.row === parent.item.config.row)
        ) {
          await this.importUnitItem(unit, item, status, parentPointer, basePointer, parent);
        }
        finishedTime = Date.now();
        status.deltaTimes.push(finishedTime - startTime);
        this.calculateRemainingTime(status, unit.data.length - i - 1);
        this.calculateImportProgress(unit, status);
      }
    },
    calculateAverage(arr) {
      if (arr.length === 0) {
        return 0; // Handle empty array to avoid division by zero
      }

      const sum = arr.reduce((acc, currentValue) => acc + currentValue, 0);
      return sum / arr.length;
    },
    calculateRemainingTime(status, itemsLeft) {
      let avgDeltaTime = this.calculateAverage(status.deltaTimes);
      status.currentImportStepRemainingTime = Math.round(itemsLeft * avgDeltaTime / 1000);
      status.currentImportStepRemainingMinutes = Math.floor(status.currentImportStepRemainingTime / 60);
      status.currentImportStepRemainingSeconds = status.currentImportStepRemainingTime % 60
    },
    abortImport(unit) {
      unit.status.abort = true;
      unit.status.currentImportStepName = 'finished';
      unit.status.running = false;

      // abort children
      const keys = Object.keys(unit.children);
      for (let i = 0; i < keys.length; i++) {
        const childKey = keys[i];
        const child = unit.children[childKey];
        this.abortImport(child);
      }
    },
    async importUnitItem(unit, item, status = null, parentPointer = [], basePointer = null, parent) {
      status = status ?? unit.status;
      let id = this.orderData.info.id;
      item.status.running = true;
      const response = await axios.put("/api/orders/" + id + "/units", {
        basePointer: basePointer ?? unit.label,
        configNeedle: parentPointer,
        uIndex: -1
      });
      unit.count++;
      let success = false;
      if (response.status === 200) {
        success = await this.patchParametersWithinUnit(unit, response.data.pointer, item, status, parentPointer, basePointer, parent);
        if (success) {
          status.items = {};
          const keys = Object.keys(unit.children);
          for (let i = 0; i < keys.length; i++) {
            if (status.abort) {
              return;
            }
            const childKey = keys[i];
            const child = unit.children[childKey];
            await this.importUnit(child, status.items[childKey], [
              ...parentPointer,
              [response.data.pointer, child.pointer],
            ], basePointer ?? unit.label, {
              anchor: unit.id,
              item: item
            });
          }
          item.status.imported = true;
        }
      } else {
        item.status.error = true;
        item.status.info = response.data.exception + ":" + response.data.message;
        item.status.imported = false;
      }

      item.status.running = false;

      return success;
    },
    async patchParametersWithinUnit(unit, unitItemPointer, item, status = null, parentPointer = [], basePointer = null, parent = null) {
      status = status ?? unit.status;

      const id = this.orderData.info.id;
      const keys = Object.keys(item.parameters);
      let errorOccurred = false;
      for (let i = 0; i < keys.length; i++) {
        if (status.abort) {
          item.status.running = false;
          item.status.aborted = true;
          return;
        }
        const paramKey = keys[i];
        const value = item.parameters[paramKey].value;
        let unitParameter = null;
        item.parameters[paramKey].status.running = true;

        Object.keys(unit.params).forEach((parameterKey) => {
          const parameter = unit.params[parameterKey];
          if (parameter.mastervariable === item.parameters[paramKey].mastervariable) {
            unitParameter = parameter;
          }
        });

        if (unitParameter) {
          let paramPointer = unitParameter.pointer;
          let configNeedle = [
            ...parentPointer,
            [unitItemPointer, paramPointer],

          ];

          try {
            const response = await axios.patch("/api/orders/" + id + "/param", {
              param: {
                basePointer: basePointer ?? unit.label,
                configNeedle: configNeedle,
                mastervariable: item.parameters[paramKey].mastervariable,
                value: value,
                text: value,
                uiMapPointer: "/" + (basePointer ?? unit.label) + "/canvas/" + (parseInt(paramPointer) + 1)
              }
            });
            item.parameters[paramKey].status.imported = true;
          } catch (error) {
            console.log(error); // or handle error appropriately
            const response = error.response;
            if (response.status !== 200) {
              errorOccurred = true;
              item.parameters[paramKey].status.error = true;
              item.parameters[paramKey].status.info = response.data.errors;
              item.parameters[paramKey].status.imported = false;
            }
          }

          item.parameters[paramKey].status.running = false;

          item.status.progress = ((i + 1) / keys.length) * 100;
          this.calculateImportProgress(unit, status);
        }
      }

      if (!errorOccurred) {
        item.imported = true;
        return true;
      }
      return false;

    },

    calculateImportProgress(unit, status) {
      const items = Object.keys(unit.data);
      let importedItems = 0;
      items.forEach((key) => {
        if (unit.data[key].status.imported) {
          importedItems += 1.0;
        } else if (unit.data[key].status.progress > 0) {
          importedItems += unit.data[key].status.progress / 100;
        }
      });

      status.importProcess = Math.round((importedItems / items.length) * 100);
    },

  }
}

</script>
