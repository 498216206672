<template>
  <b-modal
    :visible.sync="localShowImageModal"
    centered
    hide-footer
    scrollable
    size="xl"
    @hide="$emit('close')"
  >
    <div>
      <b-img :src="imgUrl" center class="modal-image"/>
    </div>
  </b-modal>
</template>

<script>
export default {
  props: {
    imgUrl: String,
    showImageModal: Boolean,
  },
  data() {
    return {
      localShowImageModal: false,
    };
  },
  computed: {},
  methods: {},
  watch: {
    showImageModal(newValue) {
      this.localShowImageModal = newValue;
    },
  },
};
</script>

<style>
.modal-image {
  max-width: 100%;
  max-height: 100%;
  height: auto;
}

.zoom-container {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  height: 400px; /* Setzen Sie hier eine geeignete Höhe */
  cursor: grab;
}
</style>
