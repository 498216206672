var _ = require('lodash');

import CalcValue from "./calc-value";
import MutateValue from "./mutate-value";

const MutateQuery = {
    install(Vue, options) {
        Vue.prototype.$mutateQuery = function (qConfig, configUnit, configNeedle = [], activeUnit = -1) {

            var tConfigUnit = _.cloneDeep(unitConfig);

            var mutatedQuery = [];

            for (let index = 0; index < qConfig.query.length; index++) {
                const subQuery = qConfig.query[index];
                mutatedQuery.push({
                    prefix: subQuery.prefix,
                    parameter: subQuery.parameter,
                    operator: subQuery.operator
                });
                if (subQuery.indirect) {
                    var referenceValue = null;
                    if (!("unit" in subQuery) || _.isEmpty(subQuery.uiMap)) {
                        referenceValue = this.$calcValue(subQuery.reference, configUnit);
                    } else {
                        referenceValue = this.getUnitcalcValue({
                            mastervariable: subQuery.reference,
                            unit: subQuery.unit,
                            uiMap: subQuery.uiMap,
                            targetConfigUnit: tConfigUnit,
                            targetConfigNeedle: configNeedle,
                            targetActiveUnit: activeUnit,
                        });
                    }

                    referenceValue = this.$mutateValue(referenceValue, subQuery.mutator, subQuery.argument, subQuery.fallback);
                    mutatedQuery[index].reference = referenceValue;
                } else {
                    mutatedQuery[index].reference = subQuery.reference;
                }
            }

            return {query: mutatedQuery};

        }
    }
};
export default MutateQuery;
