var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-100",attrs:{"id":"order-content"}},[_c('div',{class:{
      'h-100': true
    },attrs:{"role":"tabpanel"}},[_c('div',{staticClass:"d-flex h-100 position-relative"},[(_vm.map[_vm.currentCategoryIndex] !== undefined && _vm.map[_vm.currentCategoryIndex].length > 1)?_c('div',{staticClass:"d-flex flex-column justify-content-center px-2 position-absolute h-100",staticStyle:{"width":"1.9rem"}},[_c('b-nav',{staticClass:"align-items-center",attrs:{"vertical":""}},_vm._l((_vm.map[_vm.currentCategoryIndex]),function(unit,index){return _c('b-nav-item',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.right",value:({
              content: _vm.$t('configunits', unit.unit_label),
              delay: 0,
              animation: false,
            }),expression:"{\n              content: $t('configunits', unit.unit_label),\n              delay: 0,\n              animation: false,\n            }",modifiers:{"hover":true,"right":true}}],key:unit.unit_label,class:{
              'square-fa-extra-small border border-dark rounded-circle mb-1': true,
              'bg-dark': _vm.currentUnitIndex === index,
            },attrs:{"active":_vm.currentUnitIndex === index},on:{"click":function($event){return _vm.updateCurrentUnitIndex(index)}}})}),1)],1):_vm._e(),_vm._v(" "),(_vm.cats.length > 0)?_c('order-content-category',{key:_vm.currentCategoryIndex,ref:"order-content-category",attrs:{"current-category-index":_vm.currentCategoryIndex,"cat":_vm.cats[_vm.currentCategoryIndex]},on:{"update:currentUnitIndex":_vm.updateCurrentUnitIndex}}):_vm._e()],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }