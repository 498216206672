require('./common');

require('vuedraggable');

// vue select2
//import vSelect from 'vue-select'
//Vue.component('v-select', vSelect);
Vue.component('order', require('./components/app/orders/Order.vue').default);
Vue.component('order-sidebar', require('./components/app/orders/OrderSidebar.vue').default);
Vue.component('validation-list', require('./components/app/orders/ValidationList.vue').default);
Vue.component('generation-list', require('./components/app/orders/GenerationList.vue').default);
Vue.component('snapshot-list', require('./components/app/orders/SnapshotList.vue').default);
Vue.component('order-content', require('./components/app/orders/OrderContent.vue').default);
Vue.component('order-content-navigation', require('./components/app/orders/OrderContentNavigation.vue').default);
Vue.component('order-footer', require('./components/app/orders/OrderFooter.vue').default);
Vue.component('order-import', require('./components/app/orders/OrderImport.vue').default);
Vue.component('order-export-modal', require('./components/app/orders/OrderExportModal.vue').default);

Vue.component('parameter', require('./components/app/orders/elements/Parameter.vue').default);

Vue.component('image-modal', require('./components/app/orders/elements/ImageModal.vue').default);
Vue.component('delete-modal', require('./components/app/orders/elements/DeleteModal.vue').default);
Vue.component('ui-chart', require('./components/app/orders/elements/UiChart.vue').default);
Vue.component('complex-modal', require('./components/app/orders/elements/ComplexModal.vue').default);
Vue.component('complex-parameter', require('./components/app/orders/elements/ComplexParameter.vue').default);
Vue.component('ui-element', require('./components/app/orders/elements/UiElement.vue').default);
Vue.component('wysiwyg-editor', require('./components/app/orders/elements/WysiwygEditor.vue').default);
Vue.component('form-checkbox', require('./components/app/orders/elements/FormCheckbox.vue').default);
Vue.component('simple-has-media-paramter', require('./components/app/orders/elements/SimpleHasMediaParameter.vue').default);

Vue.component('flat-unit-container', require('./components/app/orders/units/flat/FlatUnitContainer.vue').default);
Vue.component('nested-unit-container', require('./components/app/orders/units/nested/NestedUnitContainer.vue').default);
Vue.component('recursive-unit-container', require('./components/app/orders/units/recursive/RecursiveUnitContainer.vue').default);

Vue.component('flat-single-unit', require('./components/app/orders/units/flat/FlatSingleUnit.vue').default);
Vue.component('flat-multiple-unit', require('./components/app/orders/units/flat/FlatMultipleUnit.vue').default);
Vue.component('nested-single-unit', require('./components/app/orders/units/nested/NestedSingleUnit.vue').default);
Vue.component('nested-multiple-unit', require('./components/app/orders/units/nested/NestedMultipleUnit.vue').default);
Vue.component('recursive-single-unit', require('./components/app/orders/units/recursive/RecursiveSingleUnit.vue').default);
Vue.component('recursive-multiple-unit', require('./components/app/orders/units/recursive/RecursiveMultipleUnit.vue').default);
Vue.component('media-image', require('./components/shared/media/MediaImage.vue').default);
Vue.component('media-pool', require('./components/shared/media/MediaPool.vue').default);
Vue.component('order-media', require('./components/app/orders/OrderMedia.vue').default);
Vue.component('notification-list', require('./components/app/orders/NotificationList.vue').default);
Vue.component('extended-menu', require('./components/app/orders/ExtendedMenu.vue').default);
Vue.component('preference-form', require('./components/app/account/PreferenceForm.vue').default);
Vue.component('generation-list-sidebar', require('./components/app/orders/GenerationListSidebar.vue').default);

Vue.component('image-parameter', require('./components/app/orders/elements/ImageParameter.vue').default);
Vue.component('media-documents', require('./components/shared/media/MediaDocuments.vue').default);


window.event = new Vue();

import store from './store/app';

import FileManager from 'laravel-file-manager';
import t from './plugins/common/translate';
import __ from './plugins/common/translate-all';
import CalcIndex from './plugins/app/calc-index';
import CalcValue from './plugins/app/calc-value';
import MutateValue from './plugins/app/mutate-value';
import CalcTrigger from './plugins/app/calc-trigger';
import MutateQuery from './plugins/app/mutate-query';
import CalcUiPointer from './plugins/app/calc-ui-pointer';
import CalcIsUi from "./plugins/app/calc-is-ui";
import ReadExcel from "./plugins/app/read-excel";
import {mapActions} from "vuex";

// VueRouter
import VueRouter from 'vue-router';

Vue.use(FileManager, {store});

Vue.use(t);

Vue.use(__);

Vue.use(CalcIndex);

Vue.use(CalcValue);

Vue.use(MutateValue);

Vue.use(CalcTrigger);

Vue.use(MutateQuery);

Vue.use(CalcUiPointer);

Vue.use(CalcIsUi);

Vue.use(ReadExcel);

Vue.use(VueRouter);
const routes = [];
const router = new VueRouter({mode: 'history', routes});

// Initialize Vuejs
window.onload = function () {
    const app = new Vue({
        router,
        store,
        el: '#app',
        data() {
            return {
                busy: false,
                translate: parseInt(document.getElementById('app').getAttribute('data-translate')),
                tenantLabel: document.getElementById('app').getAttribute('data-tlabel'),
            }
        },
        methods: {
            ...mapActions(["loadTranslation"]),
            orderScroll(orderId) {
                var offseT = document.getElementById('orderListItemOverview-' + orderId).offsetTop;
                var headerHeight = document.getElementById('orderTableHead').offsetHeight;
                var prevDetailMaxHeight = 0;
                $('#orderListItemOverview-' + orderId).prevAll('tr.bg-light').each(function () {
                    if ($(this).height() > prevDetailMaxHeight) {
                        prevDetailMaxHeight = $(this).height();
                    }
                });
                $('#orderTableBody').animate({
                    scrollTop: offseT - headerHeight - prevDetailMaxHeight
                }, 350);
            },
            /* todo: add optional offset argument */
            scrollUnitIntoView(activeNavCat, activeNavUnit) {
                const el = document.getElementById(
                    "unit-content-" + activeNavCat + "-" + activeNavUnit
                );
                if (el) {
                    el.scrollIntoView({behavior: "smooth"});
                }
            },
            hideOverlay() {
                this.busy = false;
            },
            prepareDelModal(orderNumber, orderId) {
                $('#delOrderNumber').text(orderNumber);
                $('#deleteForm').attr('action', '/orders/' + orderId);
                $('#delmodal').modal('show');
            },
            subscribeToOrder(orderId) {
                var checkbox = document.getElementById("subscribebutton_" + orderId);
                var checkboxLabel = document.getElementById("checkboxLabel_" + orderId);
                let value = "";
                if (checkbox.checked == true) {
                    value = "subscribe";
                } else {
                    value = "unsubscribe";
                }
                const response = axios.get("/api/orders/" + orderId + "/" + value);
                if (response) {
                    if (checkbox.checked == true) {
                        checkboxLabel.innerHTML = "Subscribed";
                        this.$bvToast.toast("Order Subscribed", {
                            title: "Subscribed",
                            variant: "primary",
                            solid: true,
                        });
                    } else {
                        checkboxLabel.innerHTML = "Unsubscribed";
                        this.$bvToast.toast("Order Unsubscribed", {
                            title: "Unsubscribed",
                            variant: "primary",
                            solid: true,
                        });
                    }
                }
            },
        },
        created() {
            if (this.translate) {
                this.loadTranslation();
            }
        },
    });
}
