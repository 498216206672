var _ = require('lodash');

import store from "../../store/app";

import CalcValue from "./calc-value";
import MutateValue from "./mutate-value";

const CalcTrigger = {
    install(Vue, options) {

        Vue.prototype.$calcTrigger = function (param, unitConfig, configNeedle = [], activeUnit = -1, synthesized = false) {

            var targetConfigUnit = _.cloneDeep(unitConfig);

            if (_.isEmpty(param.triggers)) {
                return {show: true, groupIndex: -1};
            }

            for (let grIndex = 0; grIndex < param.triggers.length; grIndex++) {
                if (!param.triggers[grIndex].props.active) {
                    continue;
                }

                const condition = param.triggers[grIndex].config;
                if (condition.length === 0) {
                    return {show: true, groupIndex: grIndex};
                }

                let formula = "";
                for (let cIndex = 0; cIndex < condition.length; cIndex++) {

                    let condichen = condition[cIndex];
                    if (condichen.prefix) {
                        formula += condichen.prefix;
                    }

                    let paramValue = null;

                    if (!("unit" in condichen)) {
                        paramValue = this.$calcValue(condichen.parameter, targetConfigUnit);
                    } else if (synthesized && _.isEmpty(condichen.uiMap)) {
                        paramValue = this.$calcValue(condichen.parameter, targetConfigUnit);
                    } else {
                        let args = {
                            unit: condichen.unit,
                            uiMap: condichen.uiMap,
                            uiNeedle: condichen.ui_needle,
                            mastervariable: condichen.parameter,
                            targetConfigUnit: targetConfigUnit,
                            targetConfigNeedle: configNeedle,
                            targetActiveUnit: activeUnit,
                        };

                        paramValue = store.getters.getUnitcalcValue(args);
                    }
                    let paramReference = condichen.reference;

                    if (condichen.indirect) {
                        if (!("ref_unit" in condichen) || _.isEmpty(condichen.ref_uiMap)) {
                            paramReference = this.$calcValue(paramReference, targetConfigUnit);
                        } else if (synthesized && _.isEmpty(condichen.ref_uiMap)) {
                            paramReference = this.$calcValue(paramReference, targetConfigUnit);
                        } else {
                            let refArgs = {
                                unit: condichen.ref_unit,
                                uiMap: condichen.ref_uiMap,
                                uiNeedle: condichen.ref_ui_needle,
                                mastervariable: paramReference,
                                targetConfigUnit: targetConfigUnit,
                                targetConfigNeedle: configNeedle,
                                targetActiveUnit: activeUnit,
                            };

                            paramReference = store.getters.getUnitcalcValue(refArgs);
                        }
                    }

                    paramReference = this.$mutateValue(paramReference, condichen.mutator, condichen.argument, condichen.fallback);

                    paramValue = paramValue ? "'" + paramValue + "'" : null;
                    paramReference = paramReference ? paramReference : null;

                    formula += "(";

                    if (["in", "! in", "!in"].includes(condichen.operator)) {
                        switch (condichen.operator) {
                            case "in":
                                formula =
                                    formula +
                                    "(" +
                                    JSON.stringify(paramReference.split("|")) +
                                    ".includes( " +
                                    paramValue +
                                    " ))";
                                break;
                            case "! in":
                            case "!in":
                                formula =
                                    formula +
                                    "!(" +
                                    JSON.stringify(paramReference.split("|")) +
                                    ".includes( " +
                                    paramValue +
                                    "))";
                                break;

                            default:
                                break;
                        }
                    } else if (
                        [
                            "startsWith",
                            "endsWith",
                            "!startsWith",
                            "!endsWith",
                            "regExp",
                            "includes",
                            "!includes",
                        ].includes(condichen.operator)
                    ) {
                        switch (condichen.operator) {
                            case "startsWith":
                                var teilFormula = paramValue
                                    ? paramValue +
                                    ".startsWith( '" +
                                    paramReference +
                                    "' )"
                                    : false;
                                formula = formula + "(" + teilFormula + ")";
                                break;
                            case "endsWith":
                                var teilFormula = paramValue
                                    ? paramValue +
                                    ".endsWith( '" +
                                    paramReference +
                                    "' )"
                                    : false;
                                formula = formula + "(" + teilFormula + ")";
                                break;
                            case "!startsWith":
                                var teilFormula = paramValue
                                    ? paramValue +
                                    ".startsWith( '" +
                                    paramReference +
                                    "' )"
                                    : false;
                                formula = formula + "!(" + teilFormula + ")";
                                break;
                            case "endsWith":
                                var teilFormula = paramValue
                                    ? paramValue +
                                    ".endsWith( '" +
                                    paramReference +
                                    "' )"
                                    : false;
                                formula = formula + "!(" + teilFormula + ")";
                                break;
                            case "includes":
                                var teilFormula = paramValue
                                    ? paramValue +
                                    ".includes( '" +
                                    paramReference +
                                    "' )"
                                    : false;
                                formula = formula + "(" + teilFormula + ")";
                                break;
                            case "!includes":
                                var teilFormula = paramValue
                                    ? paramValue +
                                    ".includes( '" +
                                    paramReference +
                                    "' )"
                                    : false;
                                formula = formula + "!(" + teilFormula + ")";
                                break;
                            default:
                                break;
                        }
                    } else if (["<", "<=", ">", ">="].includes(condichen.operator)) {

                        paramValue = paramValue ? Number(paramValue.slice(1, -1)) : 0;
                        paramValue = isNaN(paramValue) ? 0 : paramValue;

                        paramReference = Number(paramReference);
                        paramReference = isNaN(paramReference) ? 0 : paramReference;

                        formula =
                            formula +
                            paramValue +
                            " " +
                            condichen.operator +
                            " " +
                            paramReference;
                    } else if (["== null", "!= null"].includes(condichen.operator)) {
                        formula = formula + paramValue + " " + condichen.operator + " ";
                    } else {
                        formula =
                            formula +
                            paramValue +
                            " " +
                            condichen.operator +
                            " " +
                            "'" +
                            paramReference +
                            "'";
                    }
                    formula = formula + ")";
                }
                if (eval(formula)) {
                    return {show: true, groupIndex: grIndex};
                }

            }
            return {show: false, groupIndex: -1};

        }
    }
};
export default CalcTrigger;
