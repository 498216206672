var _ = require('lodash');
const CalcIsUi = {
    install(Vue, options) {

        Vue.prototype.$calcIsUi = function (param) {
            let pId = _.clone(param.id);
            return pId.toString().startsWith('ui');
        }
    }
};
export default CalcIsUi;
