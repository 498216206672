import axios from 'axios';

var md5 = require('md5');

const state = {
    datasources: {
        static: {},
        dynamic: {},
        complex: {},
        labels: {},
        translations: {},
    },
    pendingRequests: {}, // Initialize pendingRequests
};

const getters = {
    getDatasourceTransKey: (state) => suid => {
        return state.datasources.labels["cds_" + suid];
    },
};

const actions = {
    async loadStaticDatasource({commit, state}, args) {
        let suid = args.param.triggers[args.driver.groupIndex].datasource.suid;
        let retOps = args.param.triggers[args.driver.groupIndex].datasource.static;
        let order_id = args.order_id;

        // Generate a unique key to identify this specific request (based on order_id)
        const requestKey = `${order_id}`;

        if (Array.isArray(retOps) && retOps.length === 0) {
            return [];
        }

        // Initialize the cache for ongoing requests if it doesn't exist
        if (!state.pendingRequests) {
            Vue.set(state, 'pendingRequests', {});
        }

        // Check if the data is already cached
        if (state.datasources.static.hasOwnProperty(suid)) {
            return state.datasources.static[suid];
        }

        if (state.datasources.translations.hasOwnProperty(order_id)) {
            let translations = state.datasources.translations[order_id];
            if (translations.hasOwnProperty(suid)) {
                commit('setStatic', {sKey: suid, sVal: translations[suid]});
                return translations[suid];
            }
        }

        // Check if a request for the same key is already in progress
        if (!state.pendingRequests[requestKey]) {
            // Create a promise to handle the API call and store it in the cache
            const requestPromise = (async () => {
                try {
                    // Make the API call
                    const response = await axios.get(`/api/datasources/${order_id}/translations`);
                    commit('setDatasourceTranslations', {sKey: order_id, sVal: response.data});
                    commit('setStatic', {sKey: suid, sVal: response.data[suid]});
                    return response.data[suid];
                } catch (error) {
                    console.error(`Error fetching data for requestKey: ${requestKey}`, error);
                    throw error; // Rethrow the error after logging it
                } finally {
                    // Clean up the pendingRequests cache
                    Vue.delete(state.pendingRequests, requestKey);
                }
            })();

            // Store the promise in the pending requests cache
            Vue.set(state.pendingRequests, requestKey, requestPromise);
        }

        // Wait for the existing request to complete and return its result
        return state.pendingRequests[requestKey];
    },

    async loadDynamicDatasource({commit, state}, args) {
        let suid = args.sid;

        // Initialize the cache for ongoing requests if it doesn't exist
        if (!state.ongoingRequests) {
            state.ongoingRequests = {};
        }

        // If data already exists in the state, return it
        if (state.datasources.dynamic.hasOwnProperty(suid)) {
            return state.datasources.dynamic[suid];
        }

        // If a request is already ongoing with the same suid, return the existing promise
        if (state.ongoingRequests[suid]) {
            return state.ongoingRequests[suid];
        }

        let params = {
            basePointer: args.params.basePointer.unitLabel,
            configNeedle: args.params.configNeedle,
            mastervariable: args.params.param.mastervariable,
            uiMapPointer: args.params.uiMapPointer,
            groupIndex: args.params.driver.groupIndex
        };

        // Store the ongoing request promise in the cache
        const requestPromise = axios.post("/api/dynamicdatasources" + "/" + args.order_id + "/" + suid, {param: params})
            .then(response => {
                // Once the request is resolved, commit the data to the state
                commit('setDynamic', {sKey: suid, sVal: response.data.data});
                // Remove the promise from the cache
                delete state.ongoingRequests[suid];
                return response.data.data;
            })
            .catch(error => {
                // If there is an error, remove the promise from the cache
                delete state.ongoingRequests[suid];
                throw error;
            });

        // Store the ongoing request promise in the state
        state.ongoingRequests[suid] = requestPromise;

        // Return the ongoing request promise
        return requestPromise;
    },

    async loadComplexDatasource({commit, state}, args) {
        let dsId = args.sid;
        let config = args.config;
        let sourceKey = md5(JSON.stringify(config)) + "_" + dsId;

        // Initialize the cache for ongoing requests if it doesn't exist
        if (!state.ongoingComplexRequests) {
            state.ongoingComplexRequests = {};
        }

        // If data already exists in the state, return it
        if (state.datasources.complex.hasOwnProperty(sourceKey)) {
            return state.datasources.complex[sourceKey];
        }

        // If a request is already ongoing with the same sourceKey, return the existing promise
        if (state.ongoingComplexRequests[sourceKey]) {
            return state.ongoingComplexRequests[sourceKey];
        }

        var retOps = [];
        var transKey = {sKey: "cds_" + args.sid, sVal: null};

        let paramConfigNeedle = args.params.configNeedle.concat([
            [args.params.activeUnit, args.params.activeParam],
        ]);

        let params = {
            basePointer: args.params.basePointer.unitLabel,
            configNeedle: paramConfigNeedle,
            mastervariable: args.params.param.mastervariable,
            uiMapPointer: args.params.uiMapPointer,
            groupIndex: args.params.driver.groupIndex
        };

        // Store the ongoing request promise in the cache
        const requestPromise = axios.post("/api/complexdatasources" + "/" + args.order_id + "/" + args.sid, {param: params})
            .then(response => {
                retOps = response.data.data;
                transKey.sVal = response.data.translation.label;

                commit('setComplex', {sKey: sourceKey, sVal: retOps});
                commit('setDatasourceTransKey', transKey);

                // Remove the promise from the cache
                delete state.ongoingComplexRequests[sourceKey];

                return retOps;
            })
            .catch(error => {
                // If there is an error, remove the promise from the cache
                delete state.ongoingComplexRequests[sourceKey];
                throw error;
            });

        // Store the ongoing request promise in the state
        state.ongoingComplexRequests[sourceKey] = requestPromise;

        // Return the ongoing request promise
        return requestPromise;
    },
};

const mutations = {
    setStatic: (state, StaticSource) => (state.datasources.static[StaticSource.sKey] = StaticSource.sVal),
    setDynamic: (state, dynamicSource) => (state.datasources.dynamic[dynamicSource.sKey] = dynamicSource.sVal),
    setComplex: (state, complexSource) => (state.datasources.complex[complexSource.sKey] = complexSource.sVal),
    setDatasourceTransKey: (state, transPair) => (state.datasources.labels[transPair.sKey] = transPair.sVal),
    setDatasourceTranslations: (state, translations) => (state.datasources.translations[translations.sKey] = translations.sVal),
};

export default {
    state,
    getters,
    actions,
    mutations
};
