<template>
  <b-tabs content-class="col-md-10 col-lg-8" lazy nav-class="text-break w-100" nav-wrapper-class="col-md-2 col-lg-2"
          pills
          vertical>
    <b-tab v-for="(unit, unitKey) in importableUnits"
           :key="unitKey" :title="__('database.configunits.'+ unit.label)">
      <unit-container :order-data="orderData" :sheet-headers="sheetHeaders" :sheets="sheets"
                      :unit="unit"></unit-container>
    </b-tab>
  </b-tabs>
</template>

<script>

import UnitContainer from "./import/UnitContainer.vue";

export default {
  components: {UnitContainer},
  props: {
    sheets: Object,
    sheetHeaders: [],
    units: Object,
    orderData: Object
  },
  data() {
    return {
      importableUnits: {},
    };
  },
  methods: {
    setImportableUnits() {
      for (let unitKey in this.units) {
        const unit = this.units[unitKey];
        if ((unit.type === 'flatSingle'
            || unit.type === 'flatMultiple'
            || unit.type === 'nestedMultiple'
          )
          && unit.data && unit.data.length > 0
        ) {
          this.importableUnits[unitKey] = unit;
        }
      }
    },
  },
  beforeMount() {
    this.setImportableUnits();
  }
}
</script>
