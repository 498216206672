import {OrderImportHelper} from "../../../../../mixins/app/OrderImportHelper";

const defaultStatus = {
    abort: false,
    importProcess: 0,
    importFailed: [],
    currentImportStep: 0,
    importSteps: 1,
    currentImportStepName: 'waiting',
    currentImportStepRemainingTime: 0,
    currentImportStepRemainingMinutes: 0,
    currentImportStepRemainingSeconds: 0,
    deltaTimes: [],
    nexus: {},
    mode: 'overwrite',
};

export class Unit {

    constructor(unitData, pointer, parents = [], isInMultiple = false, anchor = null) {
        this.mastervariable = unitData.mastervariable;
        this.pointer = pointer;
        this.name = unitData.name;
        this.label = unitData.label;
        this.id = unitData.id;
        this.params = {};
        this.children = {};
        this.status = {...defaultStatus};
        this.path = parents;
        this.type = 'parameter';
        this.count = unitData.count ?? 0;
        this.isDropdown = unitData.element === 'b-form-select';
        this.hasDatasource = Boolean(unitData.has_datasource) ?? false;
        this.map = [];
        this.isInMultiple = isInMultiple;
        this.anchor = anchor ?? unitData.id;
        this.config = {
            leadingSheet: null,
            primaryColumn: null,
            limits: {
                start: 1,
                end: 10000,
            },
            join: {
                mode: null,
                columns: {},
            },
            nexus: {
                mode: null,
                sheet: {
                    id: null,
                    column: null,
                },
                unit: {
                    id: null,
                    parameter: null,
                },
            },
            needles: {},
            sorting: {
                parameter: null,
                direction: 'asc',
            },
        }

    }

    hasDataForMultiple() {
        return ((this.type === 'flatMultiple' || this.type === 'nestedMultiple' || (this.type === 'flatSingle' && !this.isInMultiple)) && this.data && this.data.length > 0)
            || ((this.type === 'flatSingle' || this.type === 'nestedSingle') && this.isInMultiple && Object.keys(this.filterParamsByMapping()).length > 0);
    }

    hasContent() {
        return this.hasDataForMultiple() || Object.keys(this.filterParamsByContent()).length > 0 || Object.keys(this.filterChildrenWithContent(this)).length > 0;
    }

    filterChildrenWithContent(unit) {
        let children = {};
        for (const childId in unit.children) {
            const child = unit.children[childId];
            const hasContent = Object.values(child.params).some(param => param.data);
            if (hasContent || (child.data && child.data.length > 0) || this.hasAnyChildWithContent(child)) {
                children[childId] = child;
            }
        }
        return children;
    }

    hasAnyChildWithContent(unit) {
        if (!unit.children) {
            return false;
        }

        for (const childId in unit.children) {
            const child = unit.children[childId];
            const hasContent = Object.values(child.params).some(param => param.data);
            if (hasContent || (child.data && child.data.length > 0) || this.hasAnyChildWithContent(child)) {
                return true;
            }
        }

        return false;
    }

    filterParamsByContent() {
        return Object.fromEntries(
            Object.entries(this.params)
                .filter(([paramId, param]) => param.data)
        );
    }

    filterParamsByMapping() {
        return Object.fromEntries(
            Object.entries(this.params)
                .filter(([paramId, param]) => param.map.column)
        );
    }

    optimizeUnitData(unit, root, sheets) {

        Object.keys(unit.children).forEach((id) => {
            this.optimizeUnitData(unit.children[id], root, sheets);
        });

        switch (true) {
            case unit.type === "flatSingle" && unit.isInMultiple:
            case unit.type === "nestedSingle" && unit.isInMultiple:
            case unit.type === "flatMultiple":
            case unit.type === "nestedMultiple":
                unit.data = this.getFlatMultipleUnitData(unit, root, sheets);
                break;
            case unit.type === "flatSingle":
                this.setFlatSingleUnitData(unit, root, sheets);
        }

    }

    setFlatSingleUnitData(unit, root, sheets) {
        unit.data = [{
            parameters: {},
            config: {
                row: 0,
            },
            status: {
                imported: false,
                error: false,
                running: false,
                info: ""
            }
        }];
        // Get the values of the parameters from the selected sheet
        for (let paramId in unit.params) {
            let param = unit.params[paramId];
            let value = null;
            if (!param.map.source.sheet || !param.map.source.row || !param.map.source.column) continue;
            if (param.map.source) {
                let sheet = sheets[param.map.source.sheet];
                let row = sheet.data[param.map.source.row];
                value = row[param.map.source.column];
            }
            if (param.map.mutator && value) {
                value = this.modifyValue(param.map, value);
            }
            unit.data[0].parameters[paramId] = {
                label: param.label,
                mastervariable: param.mastervariable,
                value: value,
                columnName: param.value,
                status: {
                    imported: false,
                    error: false,
                    running: false,
                    info: ""
                }
            }
        }

        if (Object.keys(unit.data[0].parameters).length === 0) {
            unit.data = [];
        }

    }

    getFlatMultipleUnitData(unit, root, sheets) {
        const anchor = unit.anchor === unit.id ? unit : OrderImportHelper.findUnitByAnchorId(root, unit.anchor);
        const leadingSheetName = anchor.config.leadingSheet;

        if (!leadingSheetName) return [];

        let items = []; // An empty array to collect items

        let relativeIndex = 0;

        // Iterating through the data of the leading sheet
        Object.keys(sheets[leadingSheetName].data).forEach(absoluteIndex => {
            absoluteIndex = parseInt(absoluteIndex);
            const itemExcelRow = absoluteIndex + 1;
            let item = {
                parameters: {},
                config: {
                    row: itemExcelRow,
                },
                status: {
                    imported: false,
                    error: false,
                    running: false,
                    info: ""
                }
            }; // An empty object to store item data
            let leadingSheetConfig = sheets[leadingSheetName].config; // The config of the leading sheet
            let leadingSheetData = sheets[leadingSheetName].data; // The data of the leading sheet

            if (parseInt(leadingSheetConfig.row.header) >= absoluteIndex) return;

            relativeIndex++;

            // Checking if the item should be skipped based on configuration conditions
            if (
                parseInt(anchor.config.limits.start) > relativeIndex ||
                parseInt(anchor.config.limits.end) < relativeIndex
            ) return;

            // Iterating through the parameters of unit.map
            Object.keys(unit.params).forEach(parameterId => {
                if (!item) return; // If the item has already been excluded, terminate the iteration
                let parameter = unit.params[parameterId].map;
                if (!parameter.column) return;
                let value = null; // The value variable

                switch (anchor.config.join.mode) {
                    case 'keys':
                        let leadColumn = anchor.config.join.columns[leadingSheetName];
                        let foreignColumn = anchor.config.join.columns[parameter.sheet];
                        let leadValue = leadingSheetData[absoluteIndex][leadColumn];
                        let actualIndex = Object.keys(sheets[parameter.sheet].data).find(
                            foreignIndex => sheets[parameter.sheet].data[foreignIndex][foreignColumn] === leadValue
                        );
                        if (actualIndex) {
                            value = sheets[parameter.sheet].data[actualIndex][parameter.column];
                        }
                        break;
                    default:
                        value = !sheets[parameter.sheet].data[absoluteIndex] ? null : sheets[parameter.sheet].data[absoluteIndex][parameter.column];
                }

                const parameterHasFilter = parameter.filterGroups && parameter.filterGroups.length > 0 && (parameter.filterGroups[0].filters.length || parameter.filterGroups[0].groups.length);

                // Get the previous values of the parameter in the items array
                const previousValues = items.map(item => item.parameters[parameterId].value);
                if (parameterHasFilter && parameter.filterGroups[0].mode !== 'after') {
                    if (!this.valueIsMatchingFilterGroup(parameter.filterGroups[0], value, previousValues)) {
                        item = null; // Exclude the item if the filter matches (or not)
                        return;
                    }
                }


                if (parameter.mutator && value) {
                    value = this.modifyValue(parameter, value);
                }

                if (parameterHasFilter && parameter.filterGroups[0].mode === 'after') {
                    if (!this.valueIsMatchingFilterGroup(parameter.filterGroups[0], value, previousValues)) {
                        item = null; // Exclude the item if the filter matches (or not)
                        return;
                    }
                }

                item.parameters[parameterId] = {
                    label: unit.params[parameterId].label,
                    mastervariable: unit.params[parameterId].mastervariable,
                    value: value,
                    columnName: parameter.value,
                    status: {
                        imported: false,
                        error: false,
                        running: false,
                        info: ""
                    }
                };
            });


            if (item && Object.keys(item.parameters).length > 0) {
                if (unit.config.nexus.mode === 'sheet' && unit.config.nexus.sheet.id) {
                    item.config.nexus = {...unit.config.nexus.sheet}
                    // get the leading sheet name from the nexus unit id
                    const self = this;
                    const nexusUnit = OrderImportHelper.findUnitByAnchorId(this.root, unit.config.nexus.sheet.id);
                    const nexusSheetName = nexusUnit.config.leadingSheet;
                    const nexusColumn = nexusUnit.config.join.columns[nexusSheetName];
                    const dataLength = Object.keys(sheets[nexusSheetName].data).length;
                    if (!nexusUnit.status.nexus[unit.id]) {
                        nexusUnit.status.nexus[unit.id] = {}
                    }

                    if (dataLength > 0) {
                        Object.keys(sheets[nexusSheetName].data).forEach(row => {
                            if (item.config.nexus.sheet) return;

                            const nexusExcelRow = parseInt(row) + 1;

                            const data = sheets[nexusSheetName].data[row];
                            if (data[nexusColumn] === leadingSheetData[absoluteIndex][item.config.nexus.column]) {
                                item.config.nexus.sheet = {};
                                item.config.nexus.sheet.row = nexusExcelRow;
                                if (!nexusUnit.status.nexus[unit.id][nexusExcelRow]) {
                                    nexusUnit.status.nexus[unit.id][nexusExcelRow] = {};
                                }
                                nexusUnit.status.nexus[unit.id][nexusExcelRow][itemExcelRow] = {...defaultStatus};
                            }
                        });
                    }
                    if (!item.config.nexus.sheet) {
                        item = null;
                    }
                }
                if (item) {
                    items.push(item)
                }
            }
        });

        // Sorting items if a sorting parameter is specified
        if (anchor.config.sorting.parameter) {
            items.sort((a, b) => {
                let param = anchor.config.sorting.parameter;
                const typeA = typeof a[param];
                const typeB = typeof b[param];

                if (typeA === typeB) {
                    if (typeA === "string") {
                        return a[param].localeCompare(b[param]);
                    } else {
                        return a[param] - b[param];
                    }
                } else {
                    return typeA === "string" ? 1 : -1;
                }
            });
        }

        // Reverse items if the sorting direction is 'desc'
        if (anchor.config.sorting.direction === 'desc') {
            items.reverse();
        }

        // Return the final array of items
        return items;

    }

    /**
     * Traverses through the filter groups and checks if the value matches any of the filters.
     *
     * @param {object} group - Group to check.
     * @param {any} value - The value to be checked against the filters.
     * @param {array} previousValues - The values which have been included before.
     * @return {boolean} - Returns true if the value matches any of the filters, otherwise false.
     */
    valueIsMatchingFilterGroup(group, value, previousValues = []) {
        let filterResult = false;
        let groupResult = false;
        if (group.filters.length) {
            switch (group.connection) {
                case 'and':
                    if (group.filters.every(filter => this.valueIsMatchingFilter(filter, value, previousValues))) {
                        filterResult = true;
                    }
                    break;
                case 'or':
                    if (group.filters.some(filter => this.valueIsMatchingFilter(filter, value, previousValues))) {
                        filterResult = true;
                    }
                    break;
                case 'xor':
                    if (group.filters.filter(filter => this.valueIsMatchingFilter(filter, value, previousValues)).length === 1) {
                        filterResult = true;
                    }
                    break;
                case 'not_and':
                    if (!group.filters.every(filter => this.valueIsMatchingFilter(filter, value, previousValues))) {
                        filterResult = true;
                    }
                    break;
                case 'not_or':
                    if (!group.filters.some(filter => this.valueIsMatchingFilter(filter, value, previousValues))) {
                        filterResult = true;
                    }
                    break;
                case 'not_xor':
                    if (group.filters.filter(filter => this.valueIsMatchingFilter(filter, value, previousValues)).length !== 1) {
                        filterResult = true;
                    }

            }
        }
        if (group.groups.length) {
            switch (group.connection) {
                case 'and':
                    if (group.groups.every(subGroup => this.valueIsMatchingFilterGroup(subGroup, value, previousValues))) {
                        groupResult = true;
                    }
                    break;
                case 'or':
                    if (group.groups.some(subGroup => this.valueIsMatchingFilterGroup(subGroup, value, previousValues))) {
                        groupResult = true;
                    }
                    break;
                case 'xor':
                    if (group.groups.filter(subGroup => this.valueIsMatchingFilterGroup(subGroup, value, previousValues)).length === 1) {
                        groupResult = true;
                    }
                    break;
                case 'not_and':
                    if (!group.groups.every(subGroup => this.valueIsMatchingFilterGroup(subGroup, value, previousValues))) {
                        groupResult = true;
                    }
                    break;
                case 'not_or':
                    if (!group.groups.some(subGroup => this.valueIsMatchingFilterGroup(subGroup, value, previousValues))) {
                        groupResult = true;
                    }
                    break;
                case 'not_xor':
                    const matches = group.groups.filter(subGroup => this.valueIsMatchingFilterGroup(subGroup, value, previousValues)).length;
                    groupResult = matches === 0 || matches === group.groups.length || matches % 2 === 0;
            }
        }
        if (group.filters.length && group.groups.length) {
            switch (group.connection) {
                case 'and':
                    return filterResult && groupResult;
                case 'or':
                    return filterResult || groupResult;
                case 'xor':
                    return filterResult !== groupResult;
                case 'not_and':
                    return filterResult && !groupResult;
                case 'not_or':
                    return filterResult || !groupResult;
                case 'not_xor':
                    return filterResult !== groupResult;
            }

        } else if (group.filters.length) {
            return filterResult;
        } else if (group.groups.length) {
            return groupResult;
        }
        return true;
    }

    valueIsMatchingFilter(filter, value, previousValues = []) {
        let result = false;
        switch (filter.name) {
            case 'not_null':
                result = value !== null && value !== undefined && value !== '';
                break;
            case 'starts_with':
                value = value ?? value + "";
                result = !!value.toString().match(new RegExp('^' + filter.value + '.*', 'ig'));
                break;
            case 'ends_with':
                value = value ?? value + "";
                result = !!value.toString().match(new RegExp('.*' + filter.value + '$', 'ig'));
                break;
            case 'equal':
                result = filter.value + "" === value + "";
                break;
            case 'regex':
                value = value ?? value + "";
                result = !!value.toString().match(new RegExp(filter.value, 'ig'));
                break;
            case 'contains':
                value = value ?? value + "";
                result = !!value.toString().match(new RegExp('.*' + filter.value + '.*', 'ig'));
                break;
            case 'greater':
                result = parseFloat(value) > parseFloat(filter.value);
                break;
            case 'greater_equals':
                result = parseFloat(value) >= parseFloat(filter.value);
                break;
            case 'lesser':
                result = parseFloat(value) < parseFloat(filter.value);
                break;
            case 'lesser_equals':
                result = parseFloat(value) <= parseFloat(filter.value);
                break;
            case 'distinct':
                result = !previousValues.includes(value);
                break;
        }
        return result;
    }

    modifyValue(parameter, value) {
        // ToDo: Merge/Replace this with plugin/app/mutate-value
        switch (parameter.mutator) {
            case "add":
                if (parameter.mutatorValue) {
                    value = parseInt(value) + parseInt(parameter.mutatorValue);
                }
                break;
            case "subtract":
                if (parameter.mutatorValue) {
                    value = parseInt(value) - parseInt(parameter.mutatorValue);
                }
                break;
            case "multiply":
                if (parameter.mutatorValue) {
                    value = parseInt(value) * parseInt(parameter.mutatorValue);
                }
                break;
            case "divide":
                if (parameter.mutatorValue) {
                    value = parseInt(value) / parseInt(parameter.mutatorValue);
                }
                break;
            case "absolute":
                value = Math.abs(parseInt(value));
                break;
            case "round":
                if (parameter.mutatorValue) {
                    let precision = parseInt(parameter.mutatorValue) * 10;
                    value = Math.round(parseInt(value) * precision) / precision;
                } else {
                    value = Math.round(parseInt(value));
                }
                break;
            case "ceil":
                value = Math.ceil(parseInt(value));
                break;
            case "floor":
                value = Math.floor(parseInt(value));
                break;
            case "trunc":
                value = Math.trunc(parseInt(value));
                break;
            case "append":
                if (parameter.mutatorValue && value) {
                    value = value.toString() + parameter.mutatorValue;
                } else if (parameter.mutatorValue) {
                    value = parameter.mutatorValue;
                }
                break;
            case "prepend":
                if (parameter.mutatorValue && value) {
                    value = parameter.mutatorValue + value.toString();
                } else if (parameter.mutatorValue) {
                    value = parameter.mutatorValue;
                }
                break;
            case "regex":
                if (parameter.mutatorValue && parameter.mutatorReplacement) {
                    value = value.toString().replace(new RegExp(parameter.mutatorValue, 'm'), parameter.mutatorReplacement);
                }
                break;
            case "omit":
                if (parameter.mutatorValue) {
                    value = value.toString().replaceAll(new RegExp(parameter.mutatorValue, 'gm'), '');
                }
                break;
            case "keepNS":
                if (parameter.mutatorValue) {
                    value = value.toString().substring(0, parameter.mutatorValue);
                }
                break;
            case "keepNE":
                if (parameter.mutatorValue) {
                    value = value.toString().substring(value.length - parameter.mutatorValue);
                }
                break;
            case "omitNS":
                if (parameter.mutatorValue) {
                    value = value.toString().substring(parameter.mutatorValue);
                }
                break;
            case "omitNE":
                if (parameter.mutatorValue) {
                    value = value.toString().substring(0, value.length - parameter.mutatorValue);
                }
                break;
            default:
                break;
        }

        return value;
    }

    resetUnitStatus(unit) {
        unit.status = {...defaultStatus};
        const keys = Object.keys(unit.children);
        for (let i = 0; i < keys.length; i++) {
            const childKey = keys[i];
            const child = unit.children[childKey];
            this.resetUnitStatus(child);
        }
    }
}
