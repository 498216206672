<template>
  <div class="w-100 order-body">
    <div class="p-2">
      <div class="w-100 text-right">
        <b-button v-b-toggle.collapse-new-snapshot variant="outline-dark">
          {{ __("database.order.create_order_snapshot") }}
          <span class="collapsed"><i class="far fa-fw fa-plus"></i></span>
          <span class="not-collapsed"><i class="far fa-fw fa-times"></i></span>
        </b-button>
      </div>
      <b-collapse id="collapse-new-snapshot" class="mt-2">
        <b-card>
          <b-form @submit="createSnapshot">
            <b-form-group>
              <b-textarea
                v-model="newSnapDesc"
                :placeholder="__('database.config.description')"
                :state="newSnapDesc.length > 200 ? false : null"
                maxlength="200"
                no-resize
                required
              ></b-textarea>
            </b-form-group>
            <div class="w-100 mb-1 text-right">
              <b-button type="submit" variant="outline-primary"
              ><i class="far fa-fw fa-plus"></i
              >{{ __("database.order.create_order_snapshot") }}
              </b-button>
            </div>
          </b-form>
        </b-card>
      </b-collapse>
    </div>
    <transition-group
      enter-active-class="animate__animated animate__fadeIn animate__faster"
      leave-active-class="animate__animated animate__fadeOut animate__faster"
      name="snapshot-list-transition"
      tag="div"
    >
      <div
        v-for="(snapshot, sIndex) in snapshots"
        :key="snapshot.id"
        class="p-2"
      >
        <b-card>
          <h4 class="card-title">
            {{ snapshot.created_at }}
            <span class="align-text-bottom float-right">
              <b-button
                :id="'del-pop-' + snapshot.id"
                class="p-0"
                variant="link"
              >
                <i class="far fa-times text-dark"></i>
              </b-button>
              <b-popover
                :target="'del-pop-' + snapshot.id"
                placement="auto"
                triggers="focus"
              >
                <b-button
                  variant="outline-danger"
                  @click="deleteSnapshot(snapshot.id, sIndex)"
                >
                  <i class="far fa-trash-alt"></i>
                </b-button>
              </b-popover>
            </span>
          </h4>
          <h6 class="card-subtitle text-muted mb-2">
            {{ snapshot.created_by }}
          </h6>
          <b-card-text class="py-2 border-top border-light font-weight-light"
          >{{ snapshot.description }}
          </b-card-text>
          <b-button
            :id="'snapshot-restore-pop-' + snapshot.id"
            v-b-tooltip.hover
            :title="__('database.order.restore_order_snapshot')"
            variant="outline-warning"
          >
            <i class="far fa-sync"></i>
          </b-button>
          <b-popover
            :target="'snapshot-restore-pop-' + snapshot.id"
            :title="__('database.order.restore_order_snapshot')"
            placement="auto"
            triggers="focus"
          >
            <p class="text-danger">
              <strong>{{ __("database.app.irreversible") }}</strong>
            </p>
            <b-button
              :disabled="busyRestoring"
              variant="outline-danger"
              @click="restoreSnapshot(snapshot.id)"
            >
              <i
                :class="{
                  'far fa-fw fa-sync': true,
                  'fa-spin': busyRestoring,
                }"
              ></i>
              {{ __("database.app.restore") }}
            </b-button>
          </b-popover>
        </b-card>
      </div>
    </transition-group>
  </div>
</template>
<script>
import {mapActions} from "vuex";

export default {
  data() {
    return {
      snapshots: [],
      newSnapDesc: "",
      busyRestoring: false,
    };
  },
  props: {
    orderId: Number,
  },
  methods: {
    ...mapActions(["loadOrder"]),
    loadSnapshots() {
      axios
        .get("/api/orders/" + this.orderId + "/snapshots")
        .then(({data}) => (this.snapshots = data.data));
    },
    createSnapshot(event) {
      event.preventDefault();
      axios
        .post("/api/orders/" + this.orderId + "/snapshots", {
          order_snapshot_description: this.newSnapDesc,
        })
        .then(({data}) => {
          this.snapshots.unshift(data);
          this.newSnapDesc = "";
        });
    },
    deleteSnapshot(snapshotId, snapIndex) {
      this.$root.$emit("bv::hide::popover");
      axios
        .delete("/api/orders/" + this.orderId + "/snapshots/" + snapshotId)
        .then(({data}) => this.$delete(this.snapshots, snapIndex));
    },
    restoreSnapshot(snapshotId) {
      this.busyRestoring = true;
      axios
        .post(
          "/api/orders/" +
          this.orderId +
          "/snapshots/" +
          snapshotId +
          "/restore"
        )
        .then(({data}) => {
          this.$bvToast.toast(this.__("database.order.backup_imported"), {
            title: this.__("database.app.updated"),
            variant: "success",
            solid: true,
          });
          this.getOrderDetails();
          this.$root.$emit("bv::hide::popover");
          this.busyRestoring = false;
        });
    },
    async getOrderDetails() {
      const response = await this.loadOrder(this.orderId);
      if (response) {
        this.$bvToast.toast(response, {
          title: this.__("database.order.error_on_order"),
          variant: "error",
          solid: true,
        });
      }
    },
  },
  created() {
    this.loadSnapshots(this.orderId);
  },
};
</script>
