<template>
  <div
    :id="'cat-content'"
    :class="{
            'cat-content flex-grow-1 overflow-auto h-100 w-100': true
          }"
    :style="{
            'padding-left': '1.9rem',
          }"
    :ref="'cat-content'"
  >
    <div
      v-for="(unit, unitIndex) in map[currentCategoryIndex]"
      :key="'unit-container-' + unit.unit_label"
      :id="'unit-content-' + unit.unit_label"
      :ref="'unit-content'"
      :class="{
                'unit-content w-100': true,
                'pb-5': unitIndex === map[currentCategoryIndex].length - 1
              }"
    >
      <keep-alive>
        <flat-unit-container
          v-if="unit.unit_structure == 0"
          :multiple="unit.unit_multiple"
          :base-pointer="{
                catIndex: currentCategoryIndex,
                unitIndex: unitIndex,
                unitLabel: unit.unit_label,
              }"
          :config-needle="initConfigNeedle"
          :ui-needle="initUiNeedle"
          :driver="initDriver"
          ref="unit-container"
        />
        <nested-unit-container
          v-else-if="unit.unit_structure == 1"
          :multiple="unit.unit_multiple"
          :base-pointer="{
                catIndex: currentCategoryIndex,
                unitIndex: unitIndex,
                unitLabel: unit.unit_label,
              }"
          :config-needle="initConfigNeedle"
          :ui-needle="initUiNeedle"
          :driver="initDriver"
          ref="unit-container"
        />
        <recursive-unit-container
          v-else
          :multiple="unit.unit_multiple"
          :base-pointer="{
                catIndex: currentCategoryIndex,
                unitIndex: unitIndex,
                unitLabel: unit.unit_label,
              }"
          :config-needle="initConfigNeedle"
          :ui-needle="initUiNeedle"
          :driver="initDriver"
          ref="unit-container"
        />
      </keep-alive>
    </div>
  </div>
</template>
<style scoped>
.nav > .active {
  background-color: #343a40;
  transform: scale(1.1);
  transition: transform 0.2s ease-in-out;
}
</style>
<script>
import {mapGetters} from "vuex";

export default {
  data() {
    return {
      containerKey: 0,
      initConfigNeedle: [],
      initUiNeedle: [],
      initDriver: {show: true, groupIndex: -1},
    };
  },
  props: {
    currentCategoryIndex: {
      required: false,
      type: Number,
      default: 0,
    },
    cat: Object,
  },
  computed: {
    ...mapGetters({
      map: "getMap",
      complexProps: "getComplexProps",
    }),
  },
  methods: {
    handleScroll() {
      let parentContainer = this.$refs['cat-content'];

      let bounding = parentContainer.getBoundingClientRect();

      let offsetFromViewport = bounding.top;
      const unitContents = Array.from(this.$refs["unit-content"]);

      let elementVisible = false;
      unitContents.forEach((unit, unitIndex) => {
        let bounding = unit.getBoundingClientRect();
        if (
          bounding.top >= offsetFromViewport &&
          bounding.bottom <= (parentContainer.innerHeight || document.documentElement.clientHeight)
        ) {
          if (!elementVisible && (unitIndex === 0 || unitIndex > 0)) {
            this.$emit("update:currentUnitIndex", unitIndex);
            if (unitIndex === 0) {
              elementVisible = true;
            }
          }
        }
      });
    },
  },
  mounted() {
    this.handleScroll()
    this.$refs["cat-content"].addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    this.$refs["cat-content"].removeEventListener("scroll", this.handleScroll);
  },

};
</script>
