<template>
  <b-modal
    id="deleteModal"
    :visible.sync="localShowDeleteModal"
    centered
    hide-footer
    title="Datei Löschen"
    @hide="$emit('close')"
  >
    <p>Möchten Sie diese Datei wirklich löschen?</p>
    <b-button variant="danger" @click="deleteFile"
    ><i class="fas fa-trash-alt"></i> Löschen
    </b-button
    >
    <b-button @click="closeDeleteModal">Abbrechen</b-button>
  </b-modal>
</template>

<script>
export default {
  props: {
    showDeleteModal: Boolean,
  },
  data() {
    return {
      localShowDeleteModal: false,
    };
  },
  computed: {},
  methods: {
    closeDeleteModal() {
      this.$bvModal.hide("deleteModal");
      this.$emit("close"); // Emit ein 'close'-Event an die aufrufende Komponente
    },
    deleteFile() {
      this.$bvModal.hide("deleteModal");
      this.$emit("file-deleted");
    },
  },
  watch: {
    showDeleteModal(newValue) {
      this.localShowDeleteModal = newValue;
    },
  },
};
</script>

<style>
.modal-image {
  max-width: 100%;
  max-height: 100%;
  height: auto;
}
</style>
