var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:'cat-content',class:{
          'cat-content flex-grow-1 overflow-auto h-100 w-100': true
        },style:({
          'padding-left': '1.9rem',
        }),attrs:{"id":'cat-content'}},_vm._l((_vm.map[_vm.currentCategoryIndex]),function(unit,unitIndex){return _c('div',{key:'unit-container-' + unit.unit_label,ref:'unit-content',refInFor:true,class:{
              'unit-content w-100': true,
              'pb-5': unitIndex === _vm.map[_vm.currentCategoryIndex].length - 1
            },attrs:{"id":'unit-content-' + unit.unit_label}},[_c('keep-alive',[(unit.unit_structure == 0)?_c('flat-unit-container',{ref:"unit-container",refInFor:true,attrs:{"multiple":unit.unit_multiple,"base-pointer":{
              catIndex: _vm.currentCategoryIndex,
              unitIndex: unitIndex,
              unitLabel: unit.unit_label,
            },"config-needle":_vm.initConfigNeedle,"ui-needle":_vm.initUiNeedle,"driver":_vm.initDriver}}):(unit.unit_structure == 1)?_c('nested-unit-container',{ref:"unit-container",refInFor:true,attrs:{"multiple":unit.unit_multiple,"base-pointer":{
              catIndex: _vm.currentCategoryIndex,
              unitIndex: unitIndex,
              unitLabel: unit.unit_label,
            },"config-needle":_vm.initConfigNeedle,"ui-needle":_vm.initUiNeedle,"driver":_vm.initDriver}}):_c('recursive-unit-container',{ref:"unit-container",refInFor:true,attrs:{"multiple":unit.unit_multiple,"base-pointer":{
              catIndex: _vm.currentCategoryIndex,
              unitIndex: unitIndex,
              unitLabel: unit.unit_label,
            },"config-needle":_vm.initConfigNeedle,"ui-needle":_vm.initUiNeedle,"driver":_vm.initDriver}})],1)],1)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }