/**
 * Object to interact with the ImportConfig model
 *
 * @type {{get(*): Promise<*|undefined>, index(): Promise<*|undefined>, update(): Promise<*|undefined>, store(): Promise<*|undefined>}}
 */
export const ImportConfig = {
    async index(type = null) {
        const response = await axios.get("/api/import-configs", {type: type});
        if (response.status === 200) {
            return response.data;
        } else {
            return false;
        }
    },
    async get(id) {
        const response = await axios.get("/api/import-configs/" + id);
        if (response.status === 200) {
            return response.data;
        } else {
            return false;
        }
    },
    async update(id, data) {
        const response = await axios.patch("/api/import-configs/" + id, data);
        if (response.status === 200) {
            return response.data;
        } else {
            return false;
        }
    },
    async store(data) {
        const response = await axios.post("/api/import-configs", data);
        if (response.status === 201) {
            return response.data;
        } else {
            return false;
        }
    },
    async destroy(id) {
        const response = await axios.delete("/api/import-configs/" + id);
        if (response.status === 204) {
            return response.data;
        } else {
            return false;
        }
    },
}
