const MutateValue = {
    install(Vue, options) {
        Vue.prototype.$mutateValue = function (value, mutator, argument, fallback) {

            if (!value) {
                return fallback;
            }
            if (!mutator) {
                return value;
            }

            switch (mutator) {
                case "add":
                    return isNaN(value) ? +argument : (+value + +argument);
                case "subtract":
                    return isNaN(value) ? (-1 * +argument) : (+value - +argument);
                case "multiply":
                    return isNaN(value) ? (+argument) : (+value * +argument);
                case "devide":
                    return isNaN(value) ? (1 / +argument) : (+value / +argument);
                case "absolute":
                    return isNaN(value) ? 0 : Math.abs(value);
                case "ceil":
                    return isNaN(value) ? 0 : Math.ceil(value);
                case "floor":
                    return isNaN(value) ? 0 : Math.floor(value);
                case "trunc":
                    return isNaN(value) ? 0 : Math.trunc(value);
                case "append":
                    return [value, argument].join("");
                case "prepend":
                    return [argument, value].join("");
                case "omit":
                    return String(value).replace(String(argument), "");
                case "keepNS":
                    return String(value).slice(0, parseInt(argument));
                case "keepNE":
                    return String(value).slice(-1 * parseInt(argument));
                case "omitNS":
                    return String(value).slice(parseInt(argument));
                case "omitNE":
                    return String(value).slice(0, parseInt(-1 * parseInt(argument)));
                default:
                    return null;
            }
        }
    }
};
export default MutateValue;
