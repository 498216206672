const CalcValue = {
    install(Vue, options) {
        Vue.prototype.$calcValue = function (masterVar, unitConfig) {
            for (let index = 0; index < unitConfig.length; index++) {
                if (unitConfig[index].mastervariable == masterVar) {
                    return unitConfig[index].value;
                }
            }
            return null;
        }
    }
};
export default CalcValue;
