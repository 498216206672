import axios from 'axios';

const state = {
    media: {},
};

const getters = {};

const mutations = {
    setMedia: (state, mediaSource) => (state.media[mediaSource.sKey] = mediaSource.sVal),
};

const actions = {
    async loadMedia({commit, state}, args) {

        let datasourceId = args.datasourceId;
        let mediaId = args.mediaId;

        let storeIndex = datasourceId + '_' + mediaId;

        if (state.media.hasOwnProperty(storeIndex)) {
            return state.media[storeIndex];
        }

        const response = await axios.get("/api/datasources/" + datasourceId + "/media/content/" + mediaId);
        if (response.status == 200) {
            commit('setMedia', {sKey: storeIndex, sVal: "data:image/png;base64," + response.data});
            return "data:image/png;base64," + response.data;
        } else {
            return "";
        }
    },
};

export default {
    state,
    getters,
    actions,
    mutations
};
