<template>
  <div
    :class="{
        'card w-100 mb-4 img-card': true,
      }"
  >
    <h6 class="card-header img-card-header">
      <span> {{ imageUpload.imgTitle }} </span>
      <span class="float-right">
          <b-button
            variant="outline-dark"
            class="ml-1"
            size="sm"
            tabindex="-1"
            @click="toggleExpand"
          >
            <i v-if="expanded" class="far fa-chevron-up"></i>
            <i v-else class="far fa-chevron-down"></i>
          </b-button>
        </span>
    </h6>
    <b-collapse class="card-body w-100" v-model="expanded">
      <img
        v-if="imageSrc"
        :src="imageSrc"
        :alt="imageUpload.imgTitle"
        :title="imageUpload.imgTitle"
        class="img-thumbnail centered-image"
        :style="{maxHeight: imageUpload.maxHeight + 'px' }"
      >
    </b-collapse>
  </div>

</template>

<script>
export default {
  props: {
    imageUpload: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      imageSrc: '', // Initialize imageSrc as an empty string
    };
  },
  computed: {
    expanded: {
      get() {
        return (this.imageUpload.fold === false);
      },
      set(value) {
        this.imageUpload.fold = !value;
      }
    }
  },
  methods: {
    toggleExpand() {
      this.expanded = !this.expanded;
    },
    fetchImageUrl() {
      // Assuming your backend is set up to accept a parameter for the image path
      axios.post('/api/image-data', {imagePath: this.imageUpload.imagePath})
        .then(response => {
          this.imageSrc = response.data.url; // Set the imageSrc to the URL from the response
        })
        .catch(error => {
          console.error('Error fetching image URL:', error);
          this.imageSrc = ''; // Optionally set a default image or handle error
        });
    },
  },
  mounted() {
    if (this.imageUpload.imagePath) {
      this.fetchImageUrl(); // Fetch the image URL when the component is mounted
    } else {
      this.imageSrc = '/assets/images/no-image-icon.png';
    }
  },
}
</script>

<style>
.centered-image {
  display: block;
  margin-left: auto;
  margin-right: auto;
  border: none !important;
}
.img-card-header {
  padding: 0.3rem 0.9375rem !important;
}
.img-card {
  margin-top: 33px;
}
</style>
