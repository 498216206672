<template>
  <div class="w-100 d-flex flex-column border-0" v-if="driver.show">
    <div class="card-header" v-if="grid == 4">
      <h5 class="m-0 p-1">
        <span>
          <b-button
            v-if="activeUnit > 0"
            variant="link"
            class="mr-1 p-0 text-dark"
            @click="editPrev"
            v-b-tooltip.hover
            :title="$t('order', 'prev_unit')"
          >
            <i class="fas fa-chevron-left"></i>
          </b-button>
          <b-button v-else variant="link" class="mr-1 p-0 text-dark" disabled>
            <i class="fas fa-chevron-left"></i>
          </b-button>
          <b-button
            v-if="activeUnit != unitCount - 1"
            variant="link"
            class="p-0 text-dark"
            @click="editNext"
            v-b-tooltip.hover
            :title="$t('order', 'next_unit')"
          >
            <i class="fas fa-chevron-right"></i>
          </b-button>
          <b-button v-else variant="link" class="p-0 text-dark" disabled>
            <i class="fas fa-chevron-right"></i>
          </b-button>
        </span>
        <span v-if="unit.canvas.length > 0"
        >{{ $t("parameters", unit.canvas[0].mastervariable) }} :
          {{ firstParamValue }}</span
        >
        <span class="float-right">
          <b-button
            v-if="showUnitTable"
            variant="link"
            class="p-0 text-dark"
            @click="expandEditor"
            v-b-tooltip.hover
            :title="$t('order', 'expand_unit')"
          >
            <i class="fas fa-expand-wide"></i>
          </b-button>
          <b-button
            v-else
            variant="link"
            class="p-0 text-dark"
            @click="shrinkEditor"
            v-b-tooltip.hover
            :title="$t('order', 'compress_unit')"
          >
            <i class="fas fa-compress-wide"></i>
          </b-button>
          <b-button
            variant="link"
            class="ml-1 p-0 text-dark"
            @click="closeEditor"
            v-b-tooltip.hover
            :title="$t('app', 'close')"
          >
            <i class="far fa-times"></i>
          </b-button>
        </span>
      </h5>
    </div>
    <div :class="{'card-body': grid == 4}">
      <div class="d-flex flex-wrap">
        <component
          v-for="(param, pIndex) in nestedCanvas"
          :is="param.component"
          v-bind="param.binds"
          :key="pIndex + '_' + param.element.id"
          :grid="grid"
          :base-pointer="basePointer"
        />
      </div>
    </div>
  </div>
</template>
<script>
import {singleUnit} from "../../../../../mixins/app/singleUnit";

export default {
  mixins: [singleUnit],
  methods: {
    clacComponent(param) {
      if (
        param.hasOwnProperty("parameter_id") ||
        param.hasOwnProperty("uielement_id")
      ) {
        return "parameter";
      }
      if (param.structure == 0) {
        return "flat-unit-container";
      } else if (param.structure == 2) {
        return "recursive-unit-container";
      } else {
        return "nested-unit-container";
      }
    },
    calcBinds(parameter, pIndex, calculatedComp) {
      if (calculatedComp == "parameter") {
        return {
          driver: this.$calcTrigger(
            parameter,
            this.unitConfig,
            this.configNeedle,
            this.activeUnit
          ),
          UiNeedle: this.UiNeedle.concat(pIndex),
          configNeedle: this.configNeedle,
          param: parameter,
          activeUnit: this.activeUnit,
          activeParam: this.$calcIndex(parameter, this.unitConfig),
          isui: this.$calcIsUi(parameter),
          idStub:
            this.idStub + "-" + this.$calcIndex(parameter, this.unitConfig),
          unitConfig: this.unitConfig,
        };
      }

      let unitConfigNeedle = this.configNeedle.concat([
        [this.activeUnit, this.$calcIndex(parameter, this.unitConfig)],
      ]);

      return {
        driver: this.$calcTrigger(
          parameter,
          this.unitConfig,
          this.configNeedle,
          this.activeUnit
        ),
        UiNeedle: this.UiNeedle.concat(pIndex),
        configNeedle: unitConfigNeedle,
        multiple: parameter.multiple,
        idStub: this.idStub + "-" + this.$calcIndex(parameter, this.unitConfig),
      };
    },
  },
  computed: {
    nestedCanvas() {
      let nestedCanvas = this.unit.canvas.map((param, pIndex) => {
        let calculatedComp = this.clacComponent(param);
        return {
          element: param,
          component: calculatedComp,
          binds: this.calcBinds(param, pIndex, calculatedComp),
        };
      });
      return nestedCanvas;
    },
  },
};
</script>
