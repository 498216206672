<template>
  <div
    class="
      row
      border-top border-dark
      fixed-bottom
      order-footer
      bg-white
      no-gutters
    "
  >
    <div class="col-md-9 border-right border-bottom border-dark">
      <div class="w-100 h-100 d-flex flex-column justify-content-center">
        <transition-group
          enter-active-class="animate__animated animate__fadeIn animate__faster"
          leave-active-class="animate__animated animate__fadeOut animate__faster"
          name="snapshot-list-transition"
          tag="div"
        >
          <span
            v-for="(item, iIndex) in broadcastLog"
            :key="'broadcat-log-' + iIndex"
            class="px-3"
          >{{ item }}</span
          >
        </transition-group>
      </div>
    </div>
    <div
      id="genFormContainer"
      class="col-md-3 col-12 p-0 border-bottom border-dark"
    >
      <b-dropdown
        id="gen-dropdown-form"
        block
        boundary="genFormContainer"
        boundery="genFormContainer"
        class="d-flex dropdown-toggle-no-caret"
        dropup
        menu-class="w-100 border border-dark genmenu"
        no-caret
        toggle-class="text-decoration-none p-3 flex-fill text-dark"
        variant="link"
      >
        <template v-slot:button-content>
          <div class="w-100 h-100">
            <span class="align-middle h3">
              {{ $t("order", "generation") }}
              <i class="fas fa-cogs"></i>
            </span>
          </div>
        </template>
        <b-dropdown-form class="px-4 py-3">
          <b-form-group v-for="(unit, index) in getGen" :key="index">
            <b-form-checkbox
              v-model="getGen[index]"
              name="check-button"
              switch
              @change="submitGen(index)"
            >{{ $t("genunits", index) }}
            </b-form-checkbox
            >
          </b-form-group>
        </b-dropdown-form>
        <div
          class="col-md-12 border-top border-dark d-flex justify-content-center"
        >
          <span class="align-middle">
            <b-button
              v-if="!getIsValid"
              v-b-toggle.order-validation-list
              class="text-dark text-decoration-none pb-2 pt-3 m-0"
              variant="link"
            >
              <h3 class="m-0 text-danger">
                <span>
                  {{ $t("order", "validation_failed") }}
                </span>
                <i class="far fa-exclamation-circle"></i>
              </h3>
            </b-button>
            <b-button
              v-else
              :disabled="getIsGenerating || !getCanGenerate || !getHasGen"
              class="text-dark text-decoration-none pb-2 pt-3 m-0"
              variant="link"
              @click="startGen"
            >
              <h3 v-if="getIsGenerating" class="m-0">
                {{ $t("order", "generating") }}
                <i class="fas fa-cog fa-spin"></i>
              </h3>
              <h3 v-else class="m-0">
                {{ $t("order", "generation_start") }}
                <i class="fas fa-flag-checkered"></i>
              </h3>
            </b-button>
          </span>
        </div>
      </b-dropdown>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  data() {
    return {
      broadcastLog: [],
    };
  },
  props: {
    channel: String,
    userUuid: String,
    orderId: Number,
  },
  methods: {
    ...mapActions(["saveGen", "startGen", "updateGeneration"]),
    submitGen(genIndex) {
      const upData = {
        label: genIndex,
        value: this.getGen[genIndex],
      };
      this.saveGen(upData);
    },
    updateBroadcastLog(message) {
      let newBroadcastLog = this.broadcastLog.slice(1);
      newBroadcastLog.push(message);
      this.broadcastLog = newBroadcastLog;
    },
  },
  computed: {
    ...mapGetters([
      "getGen",
      "getCanGenerate",
      "getHasGen",
      "getIsGenerating",
      "getIsValid",
      "getValidationMessages"
    ]),
  },
  created() {
    let c = Echo.join(this.channel)
      .here((users) => {
      })
      .joining((user) => {
      })
      .leaving((user) => {
      });

    const channel = Echo.channel("presence-" + this.channel);

    channel.listen("GenerationUpdatedBySystem", (data) => {
      this.updateGeneration(data.data);
    });

    Echo.private("users." + this.userUuid).notification((notification) => {
      if (
        notification.broadcastFlag == true &&
        this.orderId == notification.orderId
      ) {
        this.updateBroadcastLog(notification.body);
      }
    });
  },
};
</script>
