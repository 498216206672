const ReadExcel = {
    install(Vue, options) {
        Vue.prototype.readExcel = async (OrderImportInputFile) => {
            let formData = new FormData();
            formData.append("file", OrderImportInputFile);
            let importURL = "/api/order-import/read-excel";
            try {
                const response = await axios({
                    method: "post",
                    url: importURL,
                    data: formData,
                    headers: {"Content-Type": "multipart/form-data"},
                });
                return {status: "success", data: response.data};
            } catch (error) {
                return {status: "error", data: {}};
            }
        }
    }
}

export default ReadExcel;
