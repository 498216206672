var _ = require('lodash');

const CalcIndex = {
    install(Vue, options) {

        Vue.prototype.$calcIndex = function (element, unitConfig) {

            var targetConfigUnit = _.cloneDeep(unitConfig);

            if (element.hasOwnProperty("uielement_id")) {
                return -1;
            }

            if (element.hasOwnProperty("configunit_id")) {
                return _.findIndex(targetConfigUnit, function (o) {
                    return o.hasOwnProperty(element.label);
                });
            }

            return _.findIndex(targetConfigUnit, function (o) {
                return o.mastervariable == element.mastervariable;
            });

        }
    }
};
export default CalcIndex;
