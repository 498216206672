<template>
  <div>
    <children :children="units" :has-parent="false" :root="{
                id: 'root',
                children:units
              }" :sheet-headers="sheetHeaders"
              :sheets="sheets" :static-options="options"></children>
  </div>
</template>

<script>

export default {
  components: {
    children: () => import('./editors/Children.vue'),
  },
  props: {
    sheets: Object,
    sheetHeaders: [],
    units: Object,
  },
  data() {
    return {
      options: {}
    }
  },
  methods: {
    setOptions() {
      this.options = {
        modifiersWithoutValues: [
          'absolute',
          'ceil',
          'floor',
          'trunc',
        ],
        modifiersWithReplacement: [
          'regex',
        ],
        filter: [
          {value: null, text: this.__('tenants.views.order_import.please_select')},
          {
            label: this.__('tenants.views.order_import.generic'),
            options: [
              {value: "starts_with", text: this.__('tenants.views.order_import.starts_with')},
              {value: "ends_with", text: this.__('tenants.views.order_import.ends_with')},
              {value: "contains", text: this.__('tenants.views.order_import.contains')},
              {value: "equal", text: this.__('tenants.views.order_import.equal')},
              {value: "regex", text: this.__('tenants.views.order_import.regex')},
              {value: "not_null", text: this.__('tenants.views.order_import.not_null')},
              {value: "distinct", text: this.__('tenants.views.order_import.distinct')},
            ]
          },
          {
            label: this.__('tenants.views.order_import.mathematical'),
            options: [
              {value: "greater", text: this.__('tenants.views.order_import.greater')},
              {value: "greater_equals", text: this.__('tenants.views.order_import.greater_than')},
              {value: "lesser", text: this.__('tenants.views.order_import.lesser')},
              {value: "lesser_equals", text: this.__('tenants.views.order_import.lesser_than')},
            ]
          },
        ],
        modifier: [
          {value: null, text: ""},
          {
            label: this.__('tenants.views.order_import.mathematical'),
            options: [
              {value: "add", text: this.__('tenants.views.order_import.add')},
              {value: "subtract", text: this.__('tenants.views.order_import.subtract')},
              {value: "multiply", text: this.__('tenants.views.order_import.multiply')},
              {value: "divide", text: this.__('tenants.views.order_import.divide')},
              {value: "round", text: this.__('tenants.views.order_import.round')},
              {value: "ceil", text: this.__('tenants.views.order_import.ceil')},
              {value: "floor", text: this.__('tenants.views.order_import.floor')},
              {value: "absolute", text: this.__('tenants.views.order_import.absolute')},
              {value: "trunc", text: this.__('tenants.views.order_import.trunc')},
            ],
          },
          {
            label: this.__('tenants.views.order_import.generic'),
            options: [
              {value: "regex", text: this.__('tenants.views.order_import.regex')},
              {value: "prepend", text: this.__('tenants.views.order_import.prepend')},
              {value: "append", text: this.__('tenants.views.order_import.append')},
              {value: "omit", text: this.__('tenants.views.order_import.omit')},
              {value: "keepNS", text: this.__('tenants.views.order_import.keepNS')},
              {value: "keepNE", text: this.__('tenants.views.order_import.keepNE')},
              {value: "omitNS", text: this.__('tenants.views.order_import.omitNS')},
              {value: "omitNE", text: this.__('tenants.views.order_import.omitNE')},
            ],
          },
        ]
      }
    }
  },
  beforeMount() {
    this.setOptions();
  }
}
</script>
