<template>
  <b-container class="h-100 overflow-auto" fluid style="padding-bottom: 10em">
    <b-row>
      <b-col class="mb-4 pt-5 border-bottom pb-2" lg="8" offset-lg="2">
        <h3 class="text-muted ">
          {{ __('tenants.views.order_import.import_order') }}
        </h3>
        <h1 class="">
          <span class="text-muted">
            {{ __('tenants.views.order_import.wizard_step') }} {{ wizardCurrentStep + 1 }} /
          </span>
          {{ __('tenants.views.order_import.wizard_step_' + wizardCurrentStep) }}
        </h1>
      </b-col>
    </b-row>
    <b-row v-if="wizardCurrentStep === 0">
      <b-col lg="8" offset-lg="2">
        <div v-if="isUploading">
          <div class="d-flex justify-content-center align-items-center">
            <div class="pt-4 pb-5 px-4 text-center">
              <h4 class="mb-4">
                {{ __('tenants.views.order_import.processing_data') }}
              </h4>
              <i class="fas fa-spinner-third fa-spin text-primary fa-lg fa-3x"></i>
            </div>
          </div>
        </div>
        <div v-if="!isUploading">
          <b-row>
            <b-col md="4">
              <label>
                <i class="fal fa-fw fa-clipboard-list"></i> {{
                  __('tenants.views.order_import.import_template_label')
                }}
              </label>
              <b-input-group>
                <b-input-group-prepend v-if="config.id">
                  <b-button variant="outline-dark" @click="deleteConfig">
                    <i class="fal fa-trash"></i>
                  </b-button>
                </b-input-group-prepend>
                <b-form-select v-model="config.id" :options="config.list">
                </b-form-select>
              </b-input-group>
            </b-col>
            <b-col md="8">
              <b-form @submit="uploadOrderExcel">
                <b-form-group
                  :label="__('tenants.views.order_import.select_file')"
                  label-for="order-import-file-input"
                >
                  <b-input-group>
                    <b-input-group-prepend v-if="inputFile">
                      <b-button variant="outline-dark" @click="resetUploadForm"
                      ><i class="fal fa-trash"></i
                      ></b-button>
                    </b-input-group-prepend>
                    <b-form-file
                      :id="'order-import-file-input'"
                      v-model="inputFile"
                      :drop-placeholder="__('tenants.views.order_import.drop_file_here')"
                      :placeholder="__('tenants.views.order_import.choose_or_drop_file')"
                      :state="errorMessage ? false : null"
                      accept=".xlsx"
                      required
                    ></b-form-file>
                    <b-input-group-append v-if="inputFile">
                      <b-button type="submit" variant="primary">
                        <span class="mr-2">{{ __('tenants.views.order_import.upload') }}</span>
                        <i class="fal fa-cogs"></i>
                      </b-button>
                    </b-input-group-append>
                    <b-form-invalid-feedback>{{
                        errorMessage
                      }}
                    </b-form-invalid-feedback>
                  </b-input-group>
                </b-form-group>
              </b-form>
            </b-col>
          </b-row>
        </div>
      </b-col>
    </b-row>
    <b-row v-else-if="wizardCurrentStep === 1">
      <b-col>
        <worksheet-config-step :sheets="sheets"/>
      </b-col>
    </b-row>
    <b-row v-else-if="wizardCurrentStep === 2">
      <b-col>
        <column-to-parameter-step :sheets="sheets" :units="units"/>
      </b-col>
    </b-row>
    <b-row v-else-if="wizardCurrentStep === 3">
      <b-col>
        <preview-step :order-data="orderData"
                      :sheets="trimmedSheets"
                      :unit-lists-to-import="unitListsToImport"
                      :units="units"/>
      </b-col>
    </b-row>
    <b-row v-else-if="wizardCurrentStep === 4">
      <b-col>
        <import-step :order-data="orderData"
                     :sheets="trimmedSheets"
                     :unit-lists-to-import="unitListsToImport"
                     :units="units"
        />
      </b-col>
    </b-row>

    <div v-if="wizardCurrentStep > 0" class="fixed-bottom bg-light border-top border-muted shadow w-100">

      <b-col class=" pt-2 pb-2" lg="8" offset-lg="2">
        <div class="d-flex justify-content-between">
          <div class="w-50">
            <b-input-group v-if="wizardCurrentStep > 0">
              <b-input-group-prepend v-if="config.id">
                <b-input-group-text
                  :class="(config.change?'bg-warning border-warning':'bg-success border-success')+' text-light'">
                  <i v-if="config.change" class="fal fa-fw fa-exclamation"></i>
                  <i v-else class="fal fa-fw fa-badge-check"></i>
                </b-input-group-text>
              </b-input-group-prepend>
              <b-input-group-prepend v-if="config.id">
                <b-button
                  v-if="wizardCurrentStep > 0"
                  variant="outline-secondary"
                  @click="copyConfig">
                  <i class="far fa-copy"></i>
                </b-button>

              </b-input-group-prepend>
              <b-form-input v-model="config.name"
                            :placeholder="__('tenants.views.order_import.import_template_new')"></b-form-input>
              <b-input-group-append>
                <b-button
                  v-if="wizardCurrentStep > 0"
                  variant="outline-secondary"
                  @click="saveConfig">
                  {{ __('tenants.views.order_import.import_template_store') }}
                  <i class="far fa-save ml-2"></i>
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </div>
          <div>
            <b-button
              v-if="wizardCurrentStep > 1"
              variant="outline-secondary"
              @click="wizardCurrentStep--"
            >
              <i class="far fa-angle-left mr-2"></i>
              {{ __('tenants.views.order_import.prev') }}
            </b-button>
            <b-button
              v-if="wizardCurrentStep !== 4"
              :disabled="!nextStepAvailable"
              variant="primary"
              @click="wizardCurrentStep++"
            >
              {{ __('tenants.views.order_import.next') }}
              <i class="far fa-angle-right ml-2"></i>
            </b-button>
          </div>
        </div>
      </b-col>
    </div>
  </b-container>
</template>
<script>
import {mapActions} from "vuex";
import ColumnToParameterStep from "./order-import/ColumnToParameterStep.vue";
import WorksheetConfigStep from "./order-import/WorksheetConfigStep.vue";
import PreviewStep from "./order-import/PreviewStep.vue";
import ImportStep from "./order-import/ImportStep.vue";
import {Unit} from "./order-import/classes/Unit";
import {ImportConfig} from "../../../mixins/app/ImportConfig";
import {EventBus} from "../../../eventbus";

export default {
  props: {
    orderId: Number,
    orderNumber: String,
    channel: String,
    userUuid: String,
  },
  data() {
    return {
      isUploading: false,
      inputFile: null,
      errorMessage: null,
      importedData: {},
      unitListsToImport: {},
      config: {
        list: [],
        id: 0,
        name: null,
        type: 'order-excel-import',
        string: null,
        change: false
      },
      wizardCurrentStep: 0,
      nextStepAvailable: false,
      orderData: {},
      dragOptions: {
        animation: 150,
        group: "uploadedUnits",
        disabled: false,
        ghostClass: "ghost",
        swapThreshold: 0.1,
      },
      units: {},
      sheets: {},
      trimmedSheets: {},
    };
  },
  components: {
    WorksheetConfigStep,
    ColumnToParameterStep,
    PreviewStep,
    ImportStep
  },
  created() {
    EventBus.$on('order-import-change-config', () => {
      this.compareConfig();
      this.prepareImportData();
      this.nextStepAvailable = this.checkIfNextStepIsAvailable();
    });
    this.loadOrder(this.orderId);
    this.loadOrderData(this.orderId);
  },
  methods: {
    ...mapActions(["loadOrder"]),

    prepareImportData() {
      this.trimSheetData();
      for (let unitName in this.units) {
        let unit = this.units[unitName];
        unit.resetUnitStatus(unit);
        unit.optimizeUnitData(unit, unit, this.sheets);
      }
    },

    // Emit a configuration change event.
    emitConfigChange() {
      EventBus.$emit('order-import-change-config');
    },

    // Load order data asynchronously.
    async loadOrderData(id) {
      const response = await axios.get(`/api/orders/${id}`);
      if (response.status === 200) {
        this.orderData = response.data.data;
      } else {
        this.updateErrorMessage(true);
      }
    },

    // Create a JSON string from unit and sheet configurations.
    getConfigValueObjectAsString() {
      const sheetConfig = {};
      for (const name in this.sheets) {
        sheetConfig[name] = {
          config: this.sheets[name].config,
          label: this.sheets[name].label,
        };
      }

      return JSON.stringify({
        units: this.stripDownUnits(this.units),
        sheets: sheetConfig,
      });
    },

    // prepare units date and remove anything but the map, config, children and params
    stripDownUnits(units) {
      let configUnits = {};
      for (const unitKey in units) {
        const unit = units[unitKey];
        configUnits[unitKey] = {
          config: unit.config,
          map: unit.map,
          children: this.stripDownUnits(unit.children),
          params: this.stripDownUnits(unit.params),
        };
      }
      return configUnits;
    },


    trimSheetData() {
      for (let sheetName in this.sheets) {
        let sheet = this.sheets[sheetName];
        let data = {};
        for (let dataIndex in sheet.data) {
          if (
            sheet.config.column.required === null ||
            parseInt(sheet.config.row.start) > dataIndex ||
            parseInt(sheet.config.row.end) <= dataIndex
          ) continue;
          data[dataIndex] = sheet.data[dataIndex];
        }
        this.trimmedSheets[sheetName] = sheet;

      }
    },

    // Load a list of configurations.
    async loadConfigList() {
      const list = await ImportConfig.index(this.config.type);
      const subList = Object.entries(list).map(([value, text]) => ({
        text,
        value: parseInt(value),
      }));
      this.config.list = [
        {text: this.__('tenants.views.order_import.import_template_default'), value: 0},
        {
          label: this.__('tenants.views.order_import.import_template_spacer'),
          options: subList,
        },
      ];
    },

    // Load a configuration by ID.
    async loadConfig(id) {
      this.config.id = parseInt(id);
      this.config.name = null;
      if (this.config.id > 0) {
        const data = await ImportConfig.get(this.config.id);
        const config = JSON.parse(data.value);
        this.config.name = data.name;
        for (const unitKey in this.units) {
          this.applyConfigValuesToUnit(this.units[unitKey], config.units[unitKey]);
        }
        for (const sheetName in this.sheets) {
          this.sheets[sheetName] = {...this.sheets[sheetName], ...config.sheets[sheetName]};
        }
        this.config.string = this.getConfigValueObjectAsString();
      }
      this.$forceUpdate();
    },

    applyConfigValuesToUnit(unit, unitConfig) {
      if (!unitConfig) {
        return;
      }
      unit.config = {...unit.config, ...unitConfig.config};
      unit.map = {...unit.map, ...unitConfig.map};
      for (const childId in unit.params) {
        this.applyConfigValuesToUnit(unit.params[childId], unitConfig.params[childId]);
      }
      for (const childId in unit.children) {
        this.applyConfigValuesToUnit(unit.children[childId], unitConfig.children[childId]);
      }
    },

    // Save a configuration.
    async saveConfig() {
      const data = {
        name: this.config.name,
        type: this.config.type,
        value: this.getConfigValueObjectAsString(),
      };
      if (this.config.id) {
        await ImportConfig.update(this.config.id, data);
        this.config.string = this.getConfigValueObjectAsString();
      } else {
        const newData = await ImportConfig.store(data);
        await this.loadConfig(newData.id);
      }
      this.compareConfig();
      await this.loadConfigList();
      this.$bvToast.toast(this.config.name, {
        title: this.__('tenants.views.order_import.import_config_save_title'),
        autoHideDelay: 5000,
        variant: 'success',
        solid: true,
      });
      this.$forceUpdate();
    },

    // Copy a configuration.
    async copyConfig() {
      this.config.id = 0;
      return await this.saveConfig();
    },

    // Delete a configuration.
    async deleteConfig() {
      this.$bvModal
        .msgBoxConfirm(this.__('tenants.views.order_import.import_config_delete_modal_text'), {
          title: this.__('tenants.views.order_import.import_config_delete_modal_title'),
          okVariant: 'danger',
          cancelVariant: 'outline-dark',
          okTitle: this.__('tenants.views.order_import.yes_delete'),
          cancelTitle: this.__('tenants.views.order_import.no_keep'),
          footerClass: 'p-2',
          hideHeaderClose: true,
          centered: true,
        })
        .then(async (value) => {
          if (value) {
            await ImportConfig.destroy(this.config.id);
            this.config.id = 0;
            await this.loadConfigList();
            this.$bvToast.toast(this.config.name, {
              title: this.__('tenants.views.order_import.import_config_delete_title'),
              autoHideDelay: 5000,
              variant: 'primary',
              solid: true,
            });
          }
        });
      this.$forceUpdate();
    },

    // Upload an order Excel file and process it.
    async uploadOrderExcel(event) {
      event.preventDefault();
      this.isUploading = true;
      if (!this.inputFile) {
        return;
      }
      await this.loadOrder(this.orderId);
      const response = await this.readExcel(this.inputFile)
      if (response.status === 'success') {
        this.importedData = response.data;
        this.units = {};
        this.sheets = {};
        let i = 0;
        for (const sheetId in this.importedData) {
          const sheet = this.importedData[sheetId];
          this.sheets[sheet.title] = {
            id: 'sheet_' + i,
            label: sheet.title,
            data: sheet.data,
            headers: [],
            config: {
              hasMultiData: null,
              row: {
                header: 0,
                start: 1,
                end: 1000,
              },
              column: {
                required: 'A',
                start: 'A',
                end: 'ZZ',
              },
            },
          };
          i++;
        }
        const unitCanvas = [];
        this.orderData.map.forEach((category) => {
          category.forEach((unitConfig) => {
            const unitKey = unitConfig.unit_name;
            let count = 0;
            if (this.orderData.config.params[unitConfig.unit_label]) {
              count = this.orderData.config.params[unitConfig.unit_label].length;
            }
            const canvasItem = {
              id: unitKey,
              structure: unitConfig.unit_structure,
              multiple: unitConfig.unit_multiple,
              name: unitConfig.unit_name,
              label: unitConfig.unit_label,
              parametermap: {canvas: unitConfig.unit_map.canvas},
              count: count,
            };
            unitCanvas.push(canvasItem);
          });
        });
        this.units = this.iterateCanvasItems(unitCanvas);
        if (this.config.id) {
          await this.loadConfig(this.config.id);
        }
        this.isUploading = false;
        this.wizardCurrentStep = 1;
        this.resetUploadForm();
      } else {
        this.isUploading = false;
        this.inputFile = null;
        this.updateErrorMessage(true);
      }
    },

    // Iterate through canvas parameters.
    iterateCanvasItems(canvas, parents = [], isInMultiple = false, anchor = null) {
      let pointer = 0;
      const params = {};
      const parent = parents[parents.length - 1];
      canvas.forEach((param) => {
        if (param.uielement_id) {
          return;
        }
        const newParam = new Unit(param, pointer, parents, isInMultiple, anchor);
        let paramsToBe = {};
        let childIsInMultiple = false;
        let childAnchor = newParam.anchor;
        if (param.parametermap) {
          switch (true) {
            case param.structure === 1 && param.multiple === 0:
              newParam.type = 'nestedSingle';
              break;
            case param.structure === 1 && param.multiple === 1:
              newParam.type = 'nestedMultiple';
              newParam.anchor = newParam.id;
              childAnchor = newParam.anchor;
              childIsInMultiple = true;
              break;
            case param.structure === 0 && param.multiple === 1:
              newParam.type = 'flatMultiple';
              newParam.anchor = newParam.id;
              childAnchor = newParam.anchor;
              childIsInMultiple = true;
              break;
            case param.structure === 0 && param.multiple === 0:
              newParam.type = 'flatSingle';
              break;
          }
          paramsToBe = this.iterateCanvasItems(param.parametermap.canvas, [...parents, {
            mastervariable: newParam.mastervariable,
            pointer: newParam.pointer,
            name: newParam.label,
            id: newParam.id,
            parent: newParam.parent,
            type: newParam.type,
          }], isInMultiple || childIsInMultiple, childAnchor);
        }

        if (newParam.anchor !== newParam.id) {
          newParam.config = null;
        }

        if (parent && !newParam.isInMultiple) {
          switch (parent.type) {
            case 'flatSingle':
            case 'nestedSingle':
              newParam.map = {
                value: null,
                source: {
                  column: null,
                  row: null,
                  sheet: null,
                },
              };
              break;
          }
        }
        for (const paramId in paramsToBe) {
          if (paramsToBe[paramId].type === 'parameter') {
            paramsToBe[paramId].anchor = newParam.anchor;
            newParam.params[paramId] = paramsToBe[paramId];
          } else {
            newParam.children[paramId] = paramsToBe[paramId];
          }
        }
        params[param.id] = newParam;
        pointer++;
      });
      return params;
    },

    // Update error message based on the existence of an error.
    updateErrorMessage(hasErrorMessage) {
      this.errorMessage = hasErrorMessage
        ? this.__('tenants.views.order_import.failed')
        : null;
    },

    findUnitByAnchorId(unit, anchorId) {
      if (unit.id === anchorId) return unit;

      for (const childId in unit.children) {
        const child = unit.children[childId];
        const result = this.findUnitByAnchorId(child, anchorId);
        if (result) return result;
      }

      return null;
    },

    // Reset the upload form.
    resetUploadForm() {
      this.inputFile = null;
      this.errorMessage = null;
    },

    // Compare the current configuration with the saved configuration.
    compareConfig() {
      this.config.change = this.config.string !== this.getConfigValueObjectAsString();
    },

    checkIfNextStepIsAvailable() {
      if (this.wizardCurrentStep === 0) {
        return Object.keys(this.importedData).length > 0;
      }
      if (this.wizardCurrentStep === 1) {
        return Object.keys(this.sheets).length > 0;
      }
      if (this.wizardCurrentStep === 2) {
        for (const unitKey in this.units) {
          if (this.units[unitKey].hasContent()) {
            return true;
          }
        }
        return false;
      }
      return true;
    },

  },

  watch: {
    'wizardCurrentStep': {
      handler: function (val) {
        this.nextStepAvailable = this.checkIfNextStepIsAvailable();
      }
    },
  },
  beforeMount() {
    // Load the list of configurations before mounting.
    this.loadConfigList();
  },
};
</script>
