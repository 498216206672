const t = {
    install(Vue, options) {
        Vue.prototype.$t = function (tGroup, tKey, params = {}) {
            const translatedText = this.$store.getters.getText({
                trans_group: tGroup,
                trans_key: tKey
            });

            // Perform parameter replacement in the translated text
            if (Object.keys(params).length > 0 && translatedText && typeof translatedText === 'string') {
                return translatedText.replace(/{{([^{}]*)}}/g, (match, p1) => {
                    return params[p1.trim()] || '';
                });
            }

            return translatedText;
        };
    }
};

export default t;
