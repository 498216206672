var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-100 order-body"},[_c('transition-group',{attrs:{"enter-active-class":"animate__animated animate__fadeIn animate__faster","leave-active-class":"animate__animated animate__fadeOut animate__faster","name":"generation-list-transition","tag":"div"}},_vm._l((_vm.getGenerations),function(generation,gIndex){return _c('div',{key:generation.id,staticClass:"mb-3 mx-2"},[_c('b-card',[_c('h4',{staticClass:"card-title"},[_vm._v("\n          "+_vm._s(generation.created_at)+"\n          "),_c('span',{staticClass:"align-text-bottom float-right"},[_c('b-button',{staticClass:"p-0",attrs:{"id":'del-pop-' + generation.id,"variant":"link"}},[_c('i',{staticClass:"far fa-trash-alt text-dark"})]),_vm._v(" "),_c('b-popover',{attrs:{"target":'del-pop-' + generation.id,"placement":"auto","triggers":"focus"}},[_c('b-button',{attrs:{"variant":"outline-danger"},on:{"click":function($event){return _vm.deleteGen(generation.id, gIndex)}}},[_c('i',{staticClass:"far fa-trash-alt"})])],1)],1)]),_vm._v(" "),_c('h6',{staticClass:"card-subtitle text-muted mb-2"},[_vm._v("\n          "+_vm._s(generation.created_by)+"\n        ")]),_vm._v(" "),_c('div',{staticClass:"p-2 border-top border-light w-100"},[(generation.idefeedback && generation.idefeedback.length > 0)?_c('div',{staticClass:"w-100"},_vm._l((generation.idefeedback),function(entry,eIndex){return _c('div',{key:eIndex,staticClass:"w-100"},[_c('h4',{staticClass:"d-flex justify-content-between"},[_c('span',[_vm._v(_vm._s(_vm.$t("genunits", entry.target)))]),_vm._v(" "),_c('span',[_c('small',{staticClass:"align-top"},[_vm._v(_vm._s(_vm.translateStatus(entry.status)))])])]),_vm._v(" "),(
                  entry.status == 'FinishedSuccessfully' ||
                  entry.status == 'FinishedSuccesfully'
                )?_c('div',{staticClass:"row"},_vm._l((entry.results),function(fileNames,fIndex){return _c('div',{key:fIndex,staticClass:"col d-flex flex-wrap"},_vm._l((fileNames),function(fileName,fNdex){return _c('b-button',{key:fNdex,staticClass:"mx-1 mw-100",attrs:{"disabled":_vm.downloadingFile ===
                      generation.id +
                        '-' +
                        eIndex +
                        '-' +
                        fIndex +
                        '-' +
                        fNdex,"variant":"link"},on:{"click":function($event){return _vm.downloadFile(
                        generation.id,
                        eIndex,
                        fIndex,
                        fNdex,
                        fileName
                      )}}},[_c('span',{staticClass:"fa-stack fa-2x"},[_c('i',{class:{
                          'fal fa-stack-2x fa-file-pdf': fIndex === 'pdf',
                          'fal fa-stack-2x fa-file-archive': fIndex === 'zip',
                          'fal fa-stack-2x fa-file-word': fIndex === 'docx',
                          'fal fa-stack-2x fa-file-excel': fIndex === 'xlsx',
                          'fal fa-stack-2x fa-cube': fIndex === 'stp',
                          'fal fa-stack-2x fa-file': _vm.isUnknownType(fIndex),
                        }}),_vm._v(" "),(
                          _vm.downloadingFile ===
                          generation.id +
                            '-' +
                            eIndex +
                            '-' +
                            fIndex +
                            '-' +
                            fNdex
                        )?_c('i',{staticClass:"fad fa-stack-1x fa-spinner-third fa-spin"}):_vm._e()]),_vm._v(" "),_c('p',{staticClass:"text-wrap"},[_c('small',[_vm._v(_vm._s(fileName))])])])}),1)}),0):(entry.status == 'FinishedInError')?_c('div',{staticClass:"row no-gutters"},_vm._l((entry.logs),function(fileNames,fIndex){return _c('div',{key:fIndex,staticClass:"col d-flex flex-wrap"},_vm._l((fileNames),function(fileName,fNdex){return _c('b-button',{key:fNdex,staticClass:"mx-1",attrs:{"variant":"link"},on:{"click":function($event){return _vm.downloadFile(
                        generation.id,
                        eIndex,
                        fIndex,
                        fNdex,
                        fileName
                      )}}},[_c('div',[_c('i',{class:{
                          'fal fa-3x': true,
                          'fa-file-pdf': fIndex == 'pdf',
                          'fa-file-archive': fIndex == 'zip',
                          'fa-file-alt': fIndex == 'txt',
                          'fa-file-search': fIndex == 'log',
                        }}),_vm._v(" "),_c('p',{staticClass:"text-nowrap"},[_c('small',[_vm._v(_vm._s(fileName))])])])])}),1)}),0):_vm._e()])}),0):_vm._e()]),_vm._v("\n        "+_vm._s(_vm.$t('app', 'snapshot__of_config__for_generation'))+"\n        "),_c('b-button',{attrs:{"id":'generation-restore-pop-' + generation.id,"variant":"outline-warning"}},[_c('i',{staticClass:"far fa-sync"})]),_vm._v(" "),_c('b-popover',{attrs:{"target":'generation-restore-pop-' + generation.id,"title":_vm.$t('order', 'restore_order_snapshot'),"placement":"auto","triggers":"focus"}},[_c('p',{staticClass:"text-danger"},[_c('strong',[_vm._v(_vm._s(_vm.$t("app", "irreversible")))])]),_vm._v(" "),_c('b-button',{attrs:{"disabled":_vm.busyRestoring,"variant":"outline-danger"},on:{"click":function($event){return _vm.restoreGeneration(generation)}}},[_c('i',{class:{
                'far fa-fw fa-sync': true,
                'fa-spin': _vm.busyRestoring,
              }}),_vm._v("\n            "+_vm._s(_vm.$t("app", "restore"))+"\n          ")])],1)],1)],1)}),0)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }