<template>
  <div class="w-100">
    <draggable
      v-model="preferences"
      :move="checkMove"
      class="list-group"
      group="people"
      handle=".handle"
      tag="ul"
      v-bind="dragOptions"
    >
      <li
        v-for="preference in preferences"
        :key="preference.attribute"
        class="list-group-item"
      >
        <span class="text-left mr-3">
          <i
            v-b-tooltip.hover
            :title="$t('app', 'reorder')"
            class="fas fa-grip-lines handle"
          ></i>
        </span>
        <label :for="preference.attribute + '_show_in_table'">{{
          translateAttrib(preference.attribute)
          }}</label>
        <div class="float-right">
          <b-form-checkbox
            :id="preference.attribute + '_show_in_table'"
            v-model="preference.show_in_table"
            :disabled="preference.attribute == 'order_number'"
            :unchecked-value="false"
            :value="true"
            inline
            switch
          >
            {{ $t("app", "show") }}
          </b-form-checkbox>
        </div>
      </li>
    </draggable>
    <div class="mt-3 w-100 text-center">
      <b-button variant="primary" @click="savePreferences">
        <span class="text">{{ $t("app", "save") }}</span>
      </b-button>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      preferences: [],
      dragOptions: {
        animation: 150,
        group: "tablePrefs",
        disabled: false,
        ghostClass: "ghost",
        swapThreshold: 0.1,
      },
    };
  },
  methods: {
    checkMove: function (evt) {
      return this.isDraggable(evt.draggedContext);
    },
    isDraggable: function (context) {
      const {index, futureIndex} = context;
      return !(
        this.preferences[index].fixed || this.preferences[futureIndex].fixed
      );
    },
    getLayoutPreferences() {
      axios
        .get("/api/account/preferences/order-table-view")
        .then((res) => {
          {
            let layoutPref = res.data;

            for (const attriblabel in layoutPref) {
              let fixed = false;

              if (attriblabel == "order_number") {
                fixed = true;
              }

              this.preferences.push({
                attribute: attriblabel,
                show_in_table: layoutPref[attriblabel].show_in_table,
                fixed: fixed,
                order: layoutPref[attriblabel].order,
              });
            }

            this.preferences.sort(
              (a, b) => parseFloat(a.order) - parseFloat(b.order)
            );

            const index = this.preferences.findIndex(
              (item) => item.attribute === "order_number"
            );

            if (index !== 0) {
              const element = this.preferences.splice(index, 1)[0];
              this.preferences.unshift(element);
              this.preferences.forEach(
                (ele, index) => (this.preferences[index].order = index)
              );
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getAttribOrder(attribLabel) {
      return this.preferences.findIndex(
        (item) => item.attribute === attribLabel
      );
    },
    savePreferences() {
      let layoutPref = {};
      this.preferences.forEach((item, index) => {
        layoutPref[item.attribute] = {
          show_in_table: item.show_in_table,
          order: this.getAttribOrder(item.attribute),
        };
      });
      axios
        .patch("/api/account/preferences/order-table-view", layoutPref)
        .then((res) => {
          {
            let toastMessage = this.$t("app", "table_view_updated");
            let toastTitle = this.$t("app", "updated");

            this.$bvToast.toast(toastMessage, {
              title: toastTitle,
              variant: "success",
              solid: true,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    translateAttrib(attribLabel) {
      let coreAttributes = [
        "order_number",
        "creator",
        "created_at",
        "standard",
      ];

      let tenantLabel = this.$root.tenantLabel;

      return coreAttributes.includes(attribLabel)
        ? this.$t("preferences", attribLabel)
        : this.$t("tenant_" + tenantLabel, "order_attribute_" + attribLabel);
    },
  },
  mounted() {
    this.getLayoutPreferences();
  },
};
</script>
