<template>
  <div class="mt-3 mb-2">
    <template v-if="unit.data.length > 0">
      <h5>{{ __('database.configunits.' + unit.label) }}</h5>
      <div v-if="status.currentImportStepName === 'waiting'">
        <label class="text-muted">
          <i class="fal fa-clock fa-fw"></i>
          {{ __('tenants.views.order_import.step_waiting') }}
        </label>
      </div>
      <div v-else-if="status.currentImportStepName === 'cleaning'">
        <label>
          {{ __('tenants.views.order_import.step_label') }} {{ status.currentImportStep }}
          {{ __('tenants.views.order_import.step_label_of') }} {{ status.importSteps }} |
          {{ __('tenants.views.order_import.step_cleaning') }}:
          <strong>{{ status.deletionProcess }} %</strong>
          <span v-if="status.currentImportStepRemainingTime > 0">
                                            | {{ __('tenants.views.order_import.remaining_time') }}:
                <strong>ca.
                  <span v-if="status.currentImportStepRemainingMinutes > 0"> {{
                      status.currentImportStepRemainingMinutes
                    }} <span
                      v-if="status.currentImportStepRemainingMinutes > 1">{{
                        __('tenants.views.order_import.minutes')
                      }}</span><span
                      v-else>{{ __('tenants.views.order_import.minute') }}</span>
                  </span>
                  <span v-if="status.currentImportStepRemainingSeconds > 0"> {{
                      status.currentImportStepRemainingSeconds
                    }} <span
                      v-if="status.currentImportStepRemainingSeconds > 1">{{
                        __('tenants.views.order_import.seconds')
                      }}</span><span
                      v-else>{{ __('tenants.views.order_import.second') }}</span>
                  </span>
                </strong>
              </span>
        </label>
        <b-progress :max="100" class="w-100">
          <b-progress-bar :value="status.deletionProcess">
          </b-progress-bar>
        </b-progress>
      </div>
      <div v-else-if="status.currentImportStepName === 'importing'">
        <label>
          {{ __('tenants.views.order_import.step_label') }} {{ status.currentImportStep }}
          {{ __('tenants.views.order_import.step_label_of') }} {{ status.importSteps }} |
          {{ __('tenants.views.order_import.step_importing') }}:
          <strong>{{ status.importProcess }} %</strong>
          <span v-if="status.currentImportStepRemainingTime > 0">
                                            | {{ __('tenants.views.order_import.remaining_time') }}:
                  <strong>ca.
                    <span v-if="status.currentImportStepRemainingMinutes > 0"> {{
                        status.currentImportStepRemainingMinutes
                      }} <span v-if="status.currentImportStepRemainingMinutes > 1">{{
                          __('tenants.views.order_import.minutes')
                        }}</span><span
                        v-else>{{ __('tenants.views.order_import.minute') }}</span>
                    </span>
                    <span v-if="status.currentImportStepRemainingSeconds > 0"> {{
                        status.currentImportStepRemainingSeconds
                      }} <span v-if="status.currentImportStepRemainingSeconds > 1">{{
                          __('tenants.views.order_import.seconds')
                        }}</span><span
                        v-else>{{ __('tenants.views.order_import.second') }}</span>
                    </span>
                  </strong>
                </span>
        </label>
        <b-progress :max="100" class="w-100 ">
          <b-progress-bar :value="status.importProcess">
          </b-progress-bar>
        </b-progress>
      </div>
      <div v-else-if="status.abort"
           class="text-center pt-3">
        <span class="text-danger">
            <i class="fal fa-ban fa-fw"></i>
            {{ __('tenants.views.order_import.aborted') }}
          </span>
      </div>
      <div v-else-if="status.currentImportStepName === 'finished'"
           class="text-center pt-3">
          <span class="text-success">
            <i class="fal fa-check-circle fa-fw"></i>
            {{ __('tenants.views.order_import.imported') }}
          </span>
      </div>
      <b-list-group class="mt-2">
        <template v-for="(item, itemKey) in unit.data">
          <template
            v-if="!nexusRow || (item.config.nexus && item.config.nexus.sheet && parseInt(item.config.nexus.sheet.row) === nexusRow)">
            <b-list-group-item :key="itemKey" class="p-1 pl-2">
              <div class="d-flex align-items-center ">
                <div class="text-nowrap">
                  <span v-if="item.status.error" class="text-danger">
                    <i class="fal fa-times-circle fa-fw"></i>
                    {{ __('tenants.views.order_import.error') }}
                  </span>
                  <!-- Show if an of the parameters object is not imported -->
                  <span v-if="Object.values(item.parameters).some(parameter => parameter.status.error)"
                        class="text-warning">
                    <i class="fal fa-exclamation-triangle fa-fw"></i>
                    {{ __('tenants.views.order_import.partially_imported') }}
                  </span>
                  <span v-else-if="item.status.running" class="text-primary">
                    <i class="fal fa-cog fa-spin fa-fw"></i>
                    {{ __('tenants.views.order_import.running') }}
                  </span>
                  <span v-else-if="item.status.imported" class="text-success">
                    <i class="fal fa-check-circle fa-fw"></i>
                    {{ __('tenants.views.order_import.imported') }}
                  </span>
                  <span v-else-if="item.status.aborted" class="text-danger">
                    <i class="fal fa-ban fa-fw"></i>
                    {{ __('tenants.views.order_import.aborted') }}
                  </span>
                  <span v-else class="text-muted">
                    <i class="fal fa-clock fa-fw"></i>
                    {{ __('tenants.views.order_import.waiting') }}
                  </span>
                </div>
                <!-- Show the first 3 parameters with value -->
                <div class="h-100 text-truncate text-nowrap pr-2">
                  <template v-for="(parameter, parameterKey) in item.parameters">
                    <span v-if="parameter.value" :key="parameterKey" class="border-left ml-2 pl-2">
                      <b>{{ __('database.parameters.' + parameter.label) }}:</b> {{ parameter.value }}
                    </span>
                  </template>
                </div>
                <div class="h-100 border-left ml-2 mr-0 ml-auto">
                  <b-button v-b-toggle="'item-'+itemKey" class="text-dark p-1 ml-1" variant="link"
                            v-on:click="item.config.open = !item.config.open">
                    <i :class="{'fa-chevron-down': !item.config.open, 'fa-chevron-up': item.config.open}"
                       class="fal fa-fw"></i>
                    {{ item.config.label }}
                  </b-button>
                </div>
              </div>
              <b-collapse :id="'item-'+itemKey">
                <b-list-group class="mt-2">
                  <template v-for="(parameter, parameterKey) in item.parameters">
                    <template v-if="parameter.status.error">
                      <b-list-group-item v-for="(info, infoKey) in parameter.status.info" :key="infoKey"
                                         class="pb-0 ">
                        <template v-if="Array.isArray(info)">
                          <p v-for="(infoItem, infoItemKey) in info" :key="infoItemKey">
                            <span class="text-danger">
                              <i class="fal fa-times-circle"></i>
                              {{ __('tenants.views.order_import.error') }}
                            </span>
                            <span class="text-muted">|</span>
                            <b>{{
                                __('database.parameters.' + infoKey)
                              }}:</b> {{ infoItem }}
                          </p>
                        </template>
                        <p v-else>
                            <span class="text-danger">
                              <i class="fal fa-times-circle"></i>
                              {{ __('tenants.views.order_import.error') }}
                            </span>
                          <span class="text-muted">|</span> <b>{{ infoKey }}:</b> {{ info }}</p>
                      </b-list-group-item>
                    </template>
                    <template v-else-if="parameter.status.imported">
                      <b-list-group-item>
                        <span class="text-success">
                          <i class="fal fa-check-circle fa-fw"></i>
                          {{ __('tenants.views.order_import.imported') }}
                        </span>
                        <span class="text-muted">|</span> <b>{{ __('database.parameters.' + parameter.label) }}:</b>
                        {{ parameter.value }}
                      </b-list-group-item>
                    </template>
                    <template v-else>
                      <b-list-group-item>
                        <span class="text-muted">
                          <i class="fal fa-clock fa-fw"></i>
                          {{ __('tenants.views.order_import.waiting') }}
                        </span>
                        <span class="text-muted">|</span> <b>{{ __('database.parameters.' + parameter.label) }}:</b>
                        {{ parameter.value }}
                      </b-list-group-item>
                    </template>
                  </template>
                </b-list-group>
              </b-collapse>

              <template v-if="status.nexus && Object.keys(status.nexus).length > 0">
                <hr>
                <template v-for="(linkedUnitStatuses, linkedUnitKey) in status.nexus">
                  <div :key="linkedUnitKey">
                    <span class="text-muted">
                      <i class="fal fa-fw fa-link"></i>
                      {{ __('tenants.views.order_import.nexus') }}</span>
                    <template v-if="linkedUnitStatuses[item.config.row]">
                      <unit-progress-container :nexus-row="parseInt(item.config.row)"
                                               :status="linkedUnitStatuses[item.config.row]"
                                               :unit="OrderImportHelper.findUnitByAnchorId(unit, linkedUnitKey)"></unit-progress-container>
                    </template>
                  </div>
                </template>
              </template>
              <template v-else-if="Object.keys(unit.children).length > 0">
                <template v-for="(child, childKey) in unit.children">
                  <unit-progress-container
                    v-if="child.data.length > 0"
                    :status="status"
                    :unit="child">
                  </unit-progress-container>
                </template>
              </template>
            </b-list-group-item>
            <b-list-group-item v-if="item.status.running" class="p-0">
              <!-- Progress bar to show the status -->
              <b-progress :max="100" class="w-100 rounded-0" height="3px">
                <b-progress-bar :value="item.status.progress">
                </b-progress-bar>
              </b-progress>
            </b-list-group-item>
          </template>
        </template>
      </b-list-group>
    </template>
  </div>
</template>

<script>
import {OrderImportHelper} from "../../../../../mixins/app/OrderImportHelper";

export default {
  name: "UnitProgressContainer",
  computed: {
    OrderImportHelper() {
      return OrderImportHelper
    }
  },
  props: {
    status: Object,
    unit: Object,
    nexusRow: null
  },
}
</script>
