<template>
  <transition
    name="recursive-unit-container-transition"
    enter-active-class="animate__animated animate__fadeIn"
    leave-active-class="animate__animated animate__fadeOut"
  >
    <div
      v-if="driver.show"
      :class="{
        'card border border-dark w-100 mb-4': true,
      }"
    >
      <h4 class="card-header">
        <span> {{ unitLabel }} </span>
        <span class="float-right">
          <b-button
            variant="outline-dark"
            class="ml-1"
            size="sm"
            tabindex="-1"
            @click="toggleExpand"
          >
            <i v-if="expanded" class="far fa-chevron-up"></i>
            <i v-else class="far fa-chevron-down"></i>
          </b-button>
        </span>
      </h4>
      <b-collapse class="card-body w-100" v-model="expanded">
        <recursive-single-unit
          v-if="multiple == 0"
          :base-pointer="basePointer"
          :config-needle="configNeedle"
          :ui-needle="UiNeedle"
          :id-stub="idStub"
          ref="unit"
        />
        <recursive-multiple-unit
          v-else
          :base-pointer="basePointer"
          :config-needle="configNeedle"
          :ui-needle="UiNeedle"
          :id-stub="idStub"
          ref="unit"
        />
      </b-collapse>
    </div>
  </transition>
</template>
<script>
import {unitContainer} from "../../../../../mixins/app/unitContainer.js";

export default {
  mixins: [unitContainer],
};
</script>
