<template>
  <div class="w-100">
    <div class="input-group">
      <b-form-checkbox
        v-model="status"
        :disabled="busy"
        :unchecked-value="false"
        :value="true"
        v-bind="configAttribs"
        @change="submitParam"
      >{{ translation }}
      </b-form-checkbox>
    </div>
  </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
  props: {
    value: {
      required: true,
      default: null,
    },
    activeUnit: {
      required: true,
      type: Number,
      default: -1,
    },
    activeParam: {
      required: true,
      type: Number,
      default: -1,
    },
    basePointer: {
      type: Object,
      required: true,
      default: null,
    },
    configNeedle: {
      type: Array,
      required: true,
      default() {
        return [];
      },
    },
    isui: {
      required: false,
      type: Boolean,
      default: false,
    },
    UiNeedle: {
      type: Array,
      required: true,
      default: () => {
        return [];
      },
    },
    param: {
      required: true,
      type: Object,
      default: null,
    },
    driver: {
      required: true,
      type: Object,
      default: () => {
        return {show: false, groupIndex: -1};
      },
    },
    configAttribs: {
      required: true,
      type: Object,
      default: null,
    },
    translation: {
      required: true,
      type: String,
      default: "",
    },
  },
  data() {
    return {
      status: false,
      busy: false,
      invalidFeedback: null,
    };
  },
  methods: {
    ...mapActions(["saveParam"]),
    async submitParam() {
      this.busy = true;
      let newValue = this.status;
      if (!this.isui && this.configValue !== newValue) {
        const paramConfigNeedle = this.configNeedle.concat([
          [this.activeUnit, this.activeParam],
        ]);
        const paramUiMapPointer = this.$calcUiPointer(
          this.basePointer.unitLabel,
          this.UiNeedle
        );
        const upData = {
          basePointer: this.basePointer.unitLabel,
          mastervariable: this.param.mastervariable,
          configNeedle: paramConfigNeedle,
          uiMapPointer: paramUiMapPointer,
          value: newValue,
          text: newValue,
        };
        const response = await this.saveParam(upData);

        if (response) {
          this.invalidFeedback = response;
        }
      }
      this.busy = false;
    },
  },
  mounted() {
    if (this.value == null) {
      this.status = false;
    } else {
      this.status = this.value;
    }
  },
};
</script>
