<template>
  <b-modal
    id="order-export-modal"
    :title="$t('order', 'export_order')"
    hide-footer
  >
    <b-form @submit="downloadOrderExcel">
      <b-form-group
        :label="$t('order', 'export_template')"
        invalid-feedback="Name is required"
        label-for="order-export-template-select"
      >
        <b-input-group>
          <b-input-group-prepend>
            <b-button variant="outline-dark" @click="resetData"
            ><i class="far fa-undo"></i
            ></b-button>
          </b-input-group-prepend>
          <b-form-select
            id="order-export-template-select"
            v-model="exportTemplateId"
            :options="exportTemplateOptions"
            :state="errorMessage ? false : null"
            required
            @change="errorMessage = null"
          >
            <template #first>
              <b-form-select-option :value="null" disabled>{{
                $t("order", "select_template")
                }}
              </b-form-select-option>
            </template>
          </b-form-select>
          <b-input-group-append>
            <b-button
              :disabled="!exportTemplateId"
              type="submit"
              variant="outline-dark"
            ><i class="far fa-download"></i
            ></b-button>
          </b-input-group-append>
          <b-form-invalid-feedback>{{ errorMessage }}</b-form-invalid-feedback>
        </b-input-group>
      </b-form-group>
    </b-form>
  </b-modal>
</template>
<script>
import {mapGetters, mapActions} from "vuex";

export default {
  data() {
    return {
      exportTemplateId: null,
      errorMessage: null,
    };
  },
  methods: {
    ...mapActions(["exportOrder"]),
    async downloadOrderExcel(event) {
      event.preventDefault();
      const fileContent = await this.exportOrder(this.exportTemplateId);
      let hasErrorMessage = false;

      if (fileContent) {
        let fileName = this.exportTemplateOptions.find(
          (element) => element.value === this.exportTemplateId
        ).text;
        var url = window.URL.createObjectURL(new Blob([fileContent]));
        var link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName + ".xlsx");
        link.setAttribute("target", "_blank");
        document.body.appendChild(link);
        link.click();
        link.remove();
      } else {
        hasErrorMessage = true;
      }
      this.updateErrorMessage(hasErrorMessage);
    },
    updateErrorMessage(hasErrorMessage) {
      this.errorMessage = hasErrorMessage
        ? this.$t("order", "export_order_failed")
        : null;
    },
    resetData() {
      this.exportTemplateId = null;
      this.errorMessage = null;
    },
  },
  computed: {
    ...mapGetters(["getExportTemplates"]),
    exportTemplateOptions() {
      let retOps = [];
      this.getExportTemplates.forEach((exportTemplate) => {
        retOps.push({
          value: exportTemplate.id,
          text: this.$t("export_templates", exportTemplate.label),
        });
      });
      return retOps;
    },
  },
};
</script>
