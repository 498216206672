import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

import order from "./modules/order";
import datasources from "./modules/datasources";
import notification from "./modules/notification";
import translation from "../common/modules/translation";
import media from "../common/modules/media";

export default new Vuex.Store({
    modules: {
        order,
        datasources,
        translation,
        notification,
        media
    }
});
