<template>
  <b-card>
    <b-card-body>
      <b-row>
        <b-col md="4">
          <label><i class="fal fa-fw fa-indent"></i>
            {{ __('tenants.views.order_import.header_multi_data') }}</label>
          <b-select v-model="sheet.config.hasMultiData" @change="updateHeaderRows">
            <option :value="null">{{ __('tenants.views.order_import.header_multi_data_null') }}</option>
            <option :value="false">{{ __('tenants.views.order_import.header_multi_data_no') }}</option>
            <option :value="true">{{ __('tenants.views.order_import.header_multi_data_yes') }}</option>
          </b-select>
        </b-col>
        <b-col v-if="sheet.config.hasMultiData === true" md="2">
          <label><i class="fal fa-fw fa-indent"></i>
            {{ __('tenants.views.order_import.header_row') }}</label>
          <b-select v-model="sheet.config.row.header" @change="setHeader">
            <option v-for="(row, id) in sheet.data" :value="id">
              {{ __('tenants.views.order_import.row') }}: {{ parseInt(id) + 1 }}
            </option>
          </b-select>
        </b-col>
        <b-col v-if="sheet.config.hasMultiData === true" class="d-flex align-items-end" md="2">
          <b-button v-b-toggle="'config_'+sheet.id">{{ __('tenants.views.order_import.detail_settings') }}</b-button>
        </b-col>
      </b-row>
      <b-collapse :id="'config_'+sheet.id">
        <hr>
        <b-row>
          <b-col md="2">
            <label><i class="fal fa-fw fa-star"></i> {{
                __('tenants.views.order_import.required_column')
              }}</label>
            <b-input v-model="sheet.config.column.required"
                     @change="emitConfigChange"></b-input>
          </b-col>
          <b-col md="2">
            <label><i class="fal fa-fw fa-arrow-from-left"></i> {{
                __('tenants.views.order_import.first_column')
              }}</label>
            <b-input v-model="sheet.config.column.start" @change="emitConfigChange"></b-input>
          </b-col>
          <b-col md="2">
            <label><i class="fal fa-fw fa-arrow-to-right"></i> {{
                __('tenants.views.order_import.last_column')
              }}</label>
            <b-input v-model="sheet.config.column.end" @change="emitConfigChange"></b-input>
          </b-col>
          <b-col md="3">
            <label><i class="fal fa-fw fa-arrow-from-top"></i> {{
                __('tenants.views.order_import.first_row')
              }}</label>
            <b-input v-model="sheet.config.row.start" @change="emitConfigChange"></b-input>
          </b-col>
          <b-col md="3">
            <label><i class="fal fa-fw fa-arrow-to-bottom"></i> {{
                __('tenants.views.order_import.last_row')
              }}</label>
            <b-input v-model="sheet.config.row.end" @change="emitConfigChange"></b-input>
          </b-col>
        </b-row>
      </b-collapse>
      <b-row class="mt-3">
        <b-col md="12">
          <b-alert show>
            <span v-if="sheet.config.hasMultiData === null">
              {{ __('tenants.views.order_import.header_multi_data_null_desc') }}
            </span>
            <span v-if="sheet.config.hasMultiData === true">
              {{ __('tenants.views.order_import.header_multi_data_yes_desc') }}
            </span>
            <span v-if="sheet.config.hasMultiData === false">
              {{ __('tenants.views.order_import.header_multi_data_no_desc') }}
            </span>
          </b-alert>
        </b-col>
      </b-row>
      <div v-if="sheet.headers.length">
        <h5 class="mt-5">{{ __('tenants.views.order_import.preview_available_columns') }}</h5>
        <b-table :items="sheet.headers" class="mh-400px" style="overflow-y: scroll">
          <!-- Set template for every table heads label, column, value and sheet  -->
          <template #head(label)="data">
            {{ __('tenants.views.order_import.label') }}
          </template>
          <template #head(column)="data">
            {{ __('tenants.views.order_import.column') }}
          </template>
          <template #head(value)="data">
            {{ __('tenants.views.order_import.value') }}
          </template>
          <template #head(sheet)="data">
            {{ __('tenants.views.order_import.sheet') }}
          </template>
          <template #cell(column)="data">
            {{ ExcelHelper().columnNumberToLetter(data.item.column) }}
          </template>

        </b-table>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>

import {EventBus} from "../../../../../eventbus";
import {ExcelHelper} from "../../../../../mixins/app/ExcelHelper";

export default {
  props: {
    sheet: Object,
  },
  methods: {
    ExcelHelper() {
      return ExcelHelper
    },
    // Emit a configuration change event.
    emitConfigChange: function () {
      this.$forceUpdate();
      EventBus.$emit('order-import-change-config');
    },

    // Set unit header row and update header rows.
    setHeader: function (value) {
      this.sheet.config.row.header = parseInt(value);
      this.updateHeaderRows();
      this.emitConfigChange();
    },

    // Update header rows for all sheets and emit a config change.
    updateHeaderRows: function () {
      this.sheet.headers = [];
      if (this.sheet.config.hasMultiData !== true) {
        this.emitConfigChange();
        return;
      }
      Object.keys(this.sheet.data[this.sheet.config.row.header]).forEach(column => {
        const columnValue = this.sheet.data[this.sheet.config.row.header][column];
        if (columnValue) {
          this.sheet.headers.push({
            label: `[${this.sheet.label}][` + ExcelHelper.columnNumberToLetter(column) + `] ${columnValue}`,
            column: column,
            value: columnValue,
            sheet: this.sheet.label
          });
        }
      });

      this.emitConfigChange();
    },
  },
  beforeMount() {
    this.updateHeaderRows();
  }
}
</script>
